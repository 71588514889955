<template>
  <div class="default_main">
    <a-spin :spinning="pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="inventorysurplus_header">
        <a-button class="f_left" @click="goBackPage" icon="arrow-left" title="返回盘点详情"/>
        <a-button class="f_right" @click="callAssetAddDialog" type="primary">新增盘盈资产</a-button>
      </div>
      <div class="inventorysurplus_table">
        <a-table 
          :columns="surplusTableColumns" 
          :data-source="surplusTableData" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="surplusTablePagination" 
          @change="handleSurplusTableChange"
        >
          <template slot="status" slot-scope="status">
            <a-tag :color="status==='processed'? '#108EE9':status==='terminated'? '':'#87d068'">
              {{ status==='untreated'?'进行中':status==='processed'?'已完成':status==='terminated'?'已终止':status}} 
            </a-tag>
          </template>
          <template slot="description" slot-scope="text,record">
            <font class="table_action" @click="callSurplusDetailDialog(record)">{{text}}</font>
          </template>
          <template slot="actions" slot-scope="text,record">
            <font class="table_action" v-if="record.status=='untreated'" @click="doneSurplus(record)">完成</font>
            <font class="table_action disabled" v-if="record.status!='untreated'">完成</font>
            <font class="table_action" v-if="record.status=='untreated'" @click="stopSurplus(record)">终止</font>
            <font class="table_action disabled" v-if="record.status!='untreated'">终止</font>
            <font class="table_action" @click="deleteSurplus(record)">删除</font>
          </template>
        </a-table>
      </div>
    </a-spin>
    <AssetAddDialog :dialogConfig="AssetAddDialogConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :assetsStoreData="assetsStoreData" :extentionData="extentionData" @submit="postAssetDetail"/>
    <InventorySurplusDetailDialog :dialogConfig="InventorySurplusDetailDialogConfig" @doneSurplus="doneSurplus" @stopSurplus="stopSurplus" @callAssetAddDialog="callAssetAddDialog"/>
  </div>
</template>

<script>
import AssetAddDialog from "../../components/AssetAddDialog.vue";
import InventorySurplusDetailDialog from "../../components/InventorySurplusDetailDialog.vue";
export default {
  components: {
    AssetAddDialog,
    InventorySurplusDetailDialog
  },
  data(){
    return {
      pageLoading: false,
      surplusTableColumns: [
        {title: '记录状态',dataIndex: 'status',key: 'status',align: 'center',width: 120,scopedSlots: { customRender: 'status' }},
        {title: '盘盈内容',dataIndex: 'description',key: 'description',align: 'center',width: 250,ellipsis: true,scopedSlots: { customRender: 'description' }},
        {title: '盘点人',dataIndex: 'checkUserName',key: 'checkUserName',align: 'center',width: 120,ellipsis: true},
        {title: '手机号码',dataIndex: 'phoneNumber',key: 'phoneNumber',align: 'center',width: 120},
        {title: '完成日期',dataIndex: 'dateCompleted',key: 'dateCompleted',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '提交时间',dataIndex: 'dateEntered',key: 'dateEntered',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '操作',key: 'actions',align: 'center',width: 130,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      surplusTableData: [],
      surplusTableSelectedRowKeys: [],
      surplusTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      AssetAddDialogConfig: { visible:false,requesting:false,action:"inventorySurplus" },
      assetsTypeData: null,
      assetsLocationData: null,
      assetsStoreData: null,
      extentionData: null,
      InventorySurplusDetailDialogConfig: { visible:false,surplusDetail:{} }
    }
  },
  mounted(){
    this.getSurplusTableData();
    this.getAssetsLocationTreeData();
    this.getAssetsTypeTreeData();
    this.getAssetsStoreData();
    this.getExtentionData();
  },
  methods: {
    callAssetAddDialog(){
      this.AssetAddDialogConfig = { visible:true,requesting:false,action:"inventorySurplus" };
    },
    //获取资产分类数据
    getAssetsTypeTreeData(){
      let that = this;
      this.$get("/categoryStructure",{})
      .then(function(res){
        that.assetsTypeData = res.data;
      })
    },
    //获取资产位置数据
    getAssetsLocationTreeData(){
      let that = this;
      this.$get("/locationStructure",{})
      .then(function(res){
        that.assetsLocationData = res.data;
      })
    },
    //获取资产库房数据
    getAssetsStoreData(){
      let that = this;
      this.$get("/locations/store",{})
      .then(function(res){
        that.assetsStoreData = res.data;
      })
    },
    //加载扩展列信息
    getExtentionData(){
      let that = this;
      this.$get("/categoryExtendFields?pageNumber=1&pageSize=999999&searchData=",{})
      .then(function(res){
        that.extentionData = res.data.records;
      })
    },
    //新增资产信息回调
    postAssetDetail(values){
      this.AssetAddDialogConfig.requesting = true;
      let that = this;
      this.$post("/asset",values)
      .then(function(){
        that.AssetAddDialogConfig.requesting = false;
        that.$message.success("保存成功");
        that.AssetAddDialogConfig.visible = false;
      })
      .catch(function(){
        that.AssetAddDialogConfig.requesting = false;
      })
    },
    goBackPage(){
      this.$router.push({path:'/backstage/assetManagement/inventory/detail',query: {orderCheckId:this.$route.query.orderCheckId}});
    },
    getSurplusTableData(){
      this.pageLoading = true;
      let that = this;
      this.$get("/orderCheckWinRecords/"+this.$route.query.orderCheckId+"?pageNumber="+this.surplusTablePagination.current+"&pageSize="+this.surplusTablePagination.pageSize,{})
      .then(function(res){
        that.pageLoading = false;
        that.surplusTableSelectedRowKeys = [];
        that.surplusTableData = res.data.records;
        const pager = { ...that.surplusTablePagination };
        pager.total = res.data.total;
        that.surplusTablePagination = pager;
      })
    },
    handleSurplusTableChange(pagination){
      const pager = { ...this.surplusTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.surplusTablePagination = pager;
      this.getSurplusTableData();
    },
    deleteSurplus(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除盘盈记录吗?",
        onOk(){
          that.pageLoading = true;
          that.$delete("/orderCheckWinRecords/"+record.id,{})
          .then(function(){
            that.pageLoading = false;
            that.$message.success("删除成功");
            if(that.surplusTableData.length==1&&that.surplusTablePagination.current>1){ that.surplusTablePagination.current--; }
            that.getSurplusTableData();
          })
          .catch(function(){
            that.pageLoading = false;
          })
        },
        onCancel(){}
      });
    },
    doneSurplus(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "完成操作",
        content: "确定处理完成盘盈记录吗?",
        onOk(){
          that.pageLoading = true;
          that.$put("/orderCheckWinRecords/"+record.id+"/finish")
          .then(function(){
            that.pageLoading = false;
            that.$message.success("处理完成成功");
            that.getSurplusTableData();
          })
          .catch(function(){
            that.pageLoading = false;
          })
        },
        onCancel(){}
      });
    },
    stopSurplus(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "终止操作",
        content: "确定终止盘盈记录吗?",
        onOk(){
          that.pageLoading = true;
          that.$put("/orderCheckWinRecords/"+record.id+"/terminate")
          .then(function(){
            that.pageLoading = false;
            that.$message.success("处理终止成功");
            that.getSurplusTableData();
          })
          .catch(function(){
            that.pageLoading = false;
          })
        },
        onCancel(){}
      });
    },
    callSurplusDetailDialog(record){
      this.InventorySurplusDetailDialogConfig = { visible:true,surplusDetail:record };
    }
  }
}
</script>

<style lang="less" scoped>
.inventorysurplus_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.inventorysurplus_header button+button{
  margin-right: 5px;
}
.inventorysurplus_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
</style>