<template>
  <div class="default_main">
    <a-spin :spinning="organizationTreeData==null||(organizationTreeData.length!=0&&organizationTableData==null)||pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="approvalsetting_content_left">
        <a-tree v-if="organizationTreeData&&organizationTreeData.length>0" :treeData="organizationTreeData" :defaultExpandedKeys="defaultOrganizationTreeExpandedKeys" :blockNode="true" @select="organizationTreeSelect" :selectedKeys="organizationTreeSelectedKeys">
          <template slot="custom" slot-scope="item">
            <div class="tree_node">
              <div class="tree_n_title" :title="item.title">{{item.title}}</div>
              <a-icon v-if="item.approvalUserId" type="star" theme="filled"/>
            </div>
          </template>
        </a-tree>
      </div>
      <div class="approvalsetting_content_right">
        <div class="approvalsetting_status">
          <div class="approvalsetting_status_block" v-if="organizationTreeData!=null&&organizationTreeData.length!=0">
            <span v-if="approvalStatus.currentNodeApprovalUser">审批人：【{{approvalStatus.currentNodeApprovalUser.name}}（{{approvalStatus.currentNodeApprovalUser.code}}）】</span>
            <span v-if="!approvalStatus.currentNodeApprovalUser&&approvalStatus.parentNodeApprovalUser">当前节点未设置审批人，向上取节点【{{approvalStatus.parentNode.name}}】的审批人：【{{approvalStatus.parentNodeApprovalUser.name}}（{{approvalStatus.parentNodeApprovalUser.code}}）】</span>
            <span v-if="!approvalStatus.currentNodeApprovalUser&&!approvalStatus.parentNodeApprovalUser">当前节点未设置审批人，向上取不到审批人，不触发审批。</span>
            <span class="approvalsetting_status_action" v-if="!approvalStatus.currentNodeApprovalUser" @click="callApprovalUserAddDialog()">设置</span>
            <span class="approvalsetting_status_action" v-if="approvalStatus.currentNodeApprovalUser" @click="callApprovalUserEditDialog()">修改</span>
            <span class="approvalsetting_status_action" v-if="approvalStatus.currentNodeApprovalUser" @click="removeApprovalUser()">移除</span>
          </div>
        </div>
        <div class="approvalsetting_table">
          <a-table 
            :columns="organizationTableColumns" 
            :data-source="organizationTableData" 
            :scroll="{ x: '100%',y: true}" 
            rowKey="id" 
            :pagination="organizationTablePagination" 
            @change="handleOrganizationTableChange"
          >
          </a-table>
        </div>
      </div>
    </a-spin>
    <ApprovalUserSettleDialog :dialogConfig="ApprovalUserSettleDialogConfig" :employeeData="employeeData" @getEmployeeData="getEmployeeData" @submit="approvalUserSettle"/>
  </div>
</template>

<script>
import ApprovalUserSettleDialog from "../../components/ApprovalUserSettleDialog.vue";
export default {
  components: {
    ApprovalUserSettleDialog
  },
  data(){
    return {
      pageLoading: false,
      organizationTreeData: null,
      defaultOrganizationTreeExpandedKeys: [],
      organizationTreeSelectedKeys: [],
      approvalStatus: {},
      organizationTableColumns: [
        {title: '员工编号',dataIndex: 'code',key: 'code',align: 'center',width: 120,ellipsis: true},
        {title: '姓名',dataIndex: 'name',key: 'name',align: 'center',width: 150,ellipsis: true},
        {title: '手机号',dataIndex: 'phoneNumber',key: 'phoneNumber',align: 'center',width: 120}
      ],
      organizationTableData: null,
      organizationTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      ApprovalUserSettleDialogConfig: { visible:false,requesting:false,unitPoint:null,approvalUser:undefined },
      employeeData: null
    }
  },
  mounted(){
    this.getOrganizationTreeData(()=>{});
    this.getEmployeeData();
  },
  methods: {
    //获取树部分的数据
    getOrganizationTreeData(callback){
      let that = this;
      this.$get("/unitStructureWithRoot",{})
      .then(function(res){
        that.organizationTreeData = res.data[0].children;
        if(that.organizationTreeData[0]){that.defaultOrganizationTreeExpandedKeys=[that.organizationTreeData[0].id];}
        if((!that.organizationTreeSelectedKeys.length)&&that.organizationTreeData[0]){that.organizationTreeSelect([that.organizationTreeData[0].id]);}
        callback();
      })
    },
    //按树选择获取节点审批人
    getApprovalStatus(callback){
      let that = this;
      this.$get("/unit/"+that.organizationTreeSelectedKeys[0]+"/approvalUser",{})
      .then(function(res){
        if(res.data){
          that.approvalStatus = res.data;
        }else{
          that.approvalStatus = {};
        }
        callback();
      })
    },
    organizationTreeSelect(selectedKeys){
      if(selectedKeys.length){
        this.organizationTreeSelectedKeys = selectedKeys;
        this.pageLoading = true;
        this.organizationTablePagination.current = 1;
        this.getOrganizationTableData(()=>{
          this.getApprovalStatus(()=>{ this.pageLoading = false; })
        });
      }
    },
    //按树选择获取表格数据
    getOrganizationTableData(callback){
      let that = this;
      this.$get("/unit/"+that.organizationTreeSelectedKeys[0]+"/unitUserEmployees?pageSize="+this.organizationTablePagination.pageSize+"&pageNumber="+this.organizationTablePagination.current+"&searchData=",{})
      .then(function(res){
        that.organizationTableData = res.data.records;
        const pager = { ...that.organizationTablePagination };
        pager.total = res.data.total;
        that.organizationTablePagination = pager;
        callback();
      })
    },
    //加载员工数据
    getEmployeeData(value){
      let that = this;
      let param = "pageNumber=1&pageSize=50&active=true";
      if(value!=null){ param=param+"&searchData="+value; }
      this.$get("/userEmployees?"+param,{})
      .then(function(res){ 
        that.employeeData = res.data.records;
      })
    },
    handleOrganizationTableChange(pagination){
      const pager = { ...this.organizationTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.organizationTablePagination = pager;
      this.pageLoading = true;
      this.getOrganizationTableData(()=>{ this.pageLoading = false; });
    },
    approvalUserSettle(unitId,approvalUserId){
      this.ApprovalUserSettleDialogConfig.requesting = true;
      let that = this;
      this.$put("/unit/"+unitId+"/setApprovalUser",approvalUserId)
      .then(function(){
        that.$message.success('保存成功');
        that.ApprovalUserSettleDialogConfig.requesting = false;
        that.ApprovalUserSettleDialogConfig.visible = false;
        that.pageLoading = true;
        that.getOrganizationTreeData(()=>{
          that.getApprovalStatus(()=>{that.pageLoading = false;});
        });
      })
      .catch(function(){
        that.ApprovalUserSettleDialogConfig.requesting = false;
      })
    },
    callApprovalUserAddDialog(){
      this.ApprovalUserSettleDialogConfig = { visible:true,requesting:false,unitPoint:this.organizationTreeSelectedKeys[0],approvalUser:undefined };
    },
    callApprovalUserEditDialog(){
      this.ApprovalUserSettleDialogConfig = { visible:true,requesting:false,unitPoint:this.organizationTreeSelectedKeys[0],approvalUser:{key:this.approvalStatus.currentNodeApprovalUser.id,label:this.approvalStatus.currentNodeApprovalUser.name} };
    },
    removeApprovalUser(){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "移除操作",
        content: "确定移除该节点的审批人吗?",
        onOk(){
          that.pageLoading = true;
          that.$put("/unit/"+that.organizationTreeSelectedKeys[0]+"/setApprovalUser",{approvalUserId:null})
          .then(function(){
            that.$message.success("移除成功");
            that.getOrganizationTreeData(()=>{
              that.getApprovalStatus(()=>{that.pageLoading = false;});
            });
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    }
  }
}
</script>

<style lang="less" scoped>
.approvalsetting_content_left{
  width: 300px;
  height: 100%;
  float: left;
  box-sizing: border-box;
  border-right: 1px solid #d1d7de;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 25px 0px 25px 15px;
}
.approvalsetting_content_right{
  width: calc(100% - 300px);
  height: 100%;
  float: left;
}
.approvalsetting_status{
  width: 100%;
  height: 60px;
  padding: 14px 15px;
  float: left;
}
.approvalsetting_status_block{
  height: 30px;
  float: left;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.approvalsetting_status_action{
  color: #2C8DF4;
  cursor: pointer;
  margin-left: 8px;
}
.approvalsetting_status_alert{
  padding-left: 10px;
  color: #76797f;
  font-size: 17px;
}
.approvalsetting_table{
  width: 100%;
  height: calc(100% - 60px);
  float: left;
}
.tree_n_title {
  width: calc(100% - 17px);
}
</style>