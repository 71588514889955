<template>
  <div class="default_main">
    <a-spin :spinning="pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="tenantmanagement_header">
        <a-button class="f_right" type="primary" @click="callTenantAddDialog">添加租户</a-button>
      </div>
      <div class="tenantmanagement_table">
        <a-table 
          :columns="tenantsListTableColumns" 
          :data-source="tenantsListTableData" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="tenantsListTablePagination" 
          @change="handleTenantsListTableChange"
        >
          <template slot="active" slot-scope="active">
            <a-tag :color="active? '#87D068':''">
              {{ active? '启用':'停用' }} 
            </a-tag>
          </template>
          <template slot="scheme" slot-scope="scheme">
            {{ schemeLabel[scheme] }} 
          </template>
          <template slot="assetMaxCount" slot-scope="assetMaxCount">
            {{ assetMaxCount==999999999? '无限制':assetMaxCount }} 
          </template>
          <template slot="actions" slot-scope="text,record">
            <font class="table_action" @click="callTenantEditDialog(record)">修改</font>
            <font class="table_action" v-if="!record.active" @click="activeTenant(record)">启用</font>
            <font class="table_action disabled" v-if="record.active">启用</font>
            <font class="table_action" v-if="record.active" @click="disabledTenant(record)">停用</font>
            <font class="table_action disabled" v-if="!record.active">停用</font>
          </template>
        </a-table>
      </div>
    </a-spin>
    <TenantAddDialog :dialogConfig="TenantAddDialogConfig" @submit="addTenant"/>
    <TenantEditDialog :dialogConfig="TenantEditDialogConfig" @submit="editTenant"/>
  </div>
</template>

<script>
import TenantAddDialog from "../../components/TenantAddDialog.vue";
import TenantEditDialog from "../../components/TenantEditDialog.vue";
export default {
  components: {
    TenantAddDialog,
    TenantEditDialog
  },
  data(){
    return {
      pageLoading: false,
      tenantsListTableColumns: [
        {title: '租户名称',dataIndex: 'tenantName',key: 'tenantName',align: 'center',width: 200},
        {title: '租户手机',dataIndex: 'phoneNumber',key: 'phoneNumber',align: 'center',width: 150},
        {title: '租户状态',dataIndex: 'active',key: 'active',align: 'center',width: 120,scopedSlots: { customRender: 'active' }},
        {title: '套餐',dataIndex: 'scheme',key: 'scheme',align: 'center',width: 80,scopedSlots: { customRender: 'scheme' }},
        {title: '最大资产数',dataIndex: 'assetMaxCount',key: 'assetMaxCount',align: 'center',width: 100,scopedSlots: { customRender: 'assetMaxCount' }},
        {title: '当前资产数',dataIndex: 'assetNumber',key: 'assetNumber',align: 'center',width: 100},
        {title: '当前员工数',dataIndex: 'employeeNumber',key: 'employeeNumber',align: 'center',width: 100},
        {title: '省',dataIndex: 'province',key: 'province',align: 'center',width: 120},
        {title: '市',dataIndex: 'city',key: 'city',align: 'center',width: 120},
        {title: '区',dataIndex: 'district',key: 'district',align: 'center',width: 120},
        {title: '创建日期',dataIndex: 'dateEntered',key: 'dateEntered',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '操作',key: 'actions',align: 'center',width: 130,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      tenantsListTableData: [],
      tenantsListTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      schemeLabel: {'a':'套餐A','b':"套餐B",'c':'套餐C'},
      TenantAddDialogConfig: { visible:false,requesting:false },
      TenantEditDialogConfig: { visible:false,requesting:false,tenant:{} }
    }
  },
  mounted(){
    this.getTenantsListTableData();
  },
  methods: {
    getTenantsListTableData(){
      this.pageLoading = true;
      let that = this;
      this.$get("/userTenant?pageNumber="+this.tenantsListTablePagination.current+"&pageSize="+this.tenantsListTablePagination.pageSize,{})
      .then(function(res){
        that.tenantsListTableData = res.data.records;
        const pager = { ...that.tenantsListTablePagination };
        pager.total = res.data.total;
        that.tenantsListTablePagination = pager;
        that.pageLoading = false;
      })
    },
    disabledTenant(record){
      this.pageLoading = true;
      let that = this;
      this.$put("/userTenant/"+record.id+"/0",{})
      .then(function(){
        that.$message.success('停用成功');
        that.pageLoading = false;
        that.getTenantsListTableData();
      })
      .catch(function(){
        that.pageLoading = false;
      })
    },
    activeTenant(record){
      this.pageLoading = true;
      let that = this;
      this.$put("/userTenant/"+record.id+"/1",{})
      .then(function(){
        that.$message.success('启用成功');
        that.pageLoading = false;
        that.getTenantsListTableData();
      })
      .catch(function(){
        that.pageLoading = false;
      })
    },
    handleTenantsListTableChange(pagination){
      const pager = { ...this.tenantsListTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.tenantsListTablePagination = pager;
      this.getTenantsListTableData();
    },
    callTenantAddDialog(){
      this.TenantAddDialogConfig = { visible:true,requesting:false };
    },
    addTenant(values){
      this.TenantAddDialogConfig.requesting = true;
      let that = this;
      this.$post("/userTenant",values)
      .then(function(){
        that.$message.success('添加成功');
        that.getTenantsListTableData();
        that.TenantAddDialogConfig.requesting = false;
        that.TenantAddDialogConfig.visible = false;
      })
      .catch(function(){
        that.TenantAddDialogConfig.requesting = false;
      })
    },
    callTenantEditDialog(record){
      let fix = {...record};
      if(record.assetMaxCount===999999999){ fix.assetMaxCount="无限制"; }
      this.TenantEditDialogConfig = { visible:true,requesting:false,tenant:fix };
    },
    editTenant(values){
      this.TenantEditDialogConfig.requesting = true;
      let that = this;
      this.$put("/userTenant",values)
      .then(function(){
        that.$message.success('保存成功');
        that.TenantEditDialogConfig.requesting = false;
        that.TenantEditDialogConfig.visible = false;
        that.getTenantsListTableData();
      })
      .catch(function(){
        that.TenantEditDialogConfig.requesting = false;
      })
    }
  }
}
</script>


<style lang="less" scoped>
.tenantmanagement_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.tenantmanagement_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
</style>