<template>
  <div class="default_main">
    <a-spin :spinning="locationTreeData==null||locationTableData==null||pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="locationmanagement_header">
      <a-input-search class="default_searchinput" placeholder="请输入位置编号或位置名称" enter-button v-model="normalSearchDataShow" @search="onNormalSearch"/>
      <div class="locationmanagement_store">
        <a-switch checked-children="筛选库房" un-checked-children="筛选库房" :checked="isStoreChecked" @change="isStoreCheckedChange"/>
      </div>
      <a-dropdown>
        <a-button class="f_right" type="primary">添加位置</a-button>
        <a-menu slot="overlay">
          <a-menu-item @click="callLocationAddDialog">新增</a-menu-item>
          <a-menu-item @click="callBatchImportDialog">导入</a-menu-item>
        </a-menu>
      </a-dropdown>
      </div>
      <div class="locationmanagement_content">
      <div class="locationmanagement_content_left">
        <a-tree v-if="locationTreeData&&locationTreeData.length>0" :treeData="locationTreeData" :defaultExpandedKeys="defaultLocationTreeExpandedKeys" :blockNode="true" @select="locationTreeSelect" :selectedKeys="locationTreeSelectedKeys">
          <template slot="custom" slot-scope="item">
            <div class="tree_node">
            <div class="tree_n_title" :title="item.title">{{item.title}}</div>
            <div class="tree_n_board">
              <div class="tree_n_b_block">
                <a-icon class="tree_n_b_icon" type="plus-circle" @click="addLocationBaseRoot(item)"/>
                <a-icon class="tree_n_b_icon" type="edit" @click="callLocationEditDialog(item)"/>
                <a-icon class="tree_n_b_icon" type="delete" @click="deleteLocation(item)"/>
              </div>
            </div>
            </div>
          </template>
        </a-tree>
      </div>
      <div class="locationmanagement_content_right">
        <a-table 
          :columns="locationTableColumns" 
          :data-source="locationTableData" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="locationTablePagination" 
          @change="handleLocationTableChange"
        >
          <template slot="actions" slot-scope="text,record">
            <font class="table_action" @click="callLocationEditDialog(record)">修改</font>
            <font class="table_action" @click="deleteLocation(record)">删除</font>
          </template>
        </a-table>
      </div>
      </div>
    </a-spin>
    <BatchImportDialog :dialogConfig="BatchImportDialogConfig" @submit="batchImportLocations"/>
    <LocationAddDialog :dialogConfig="LocationAddDialogConfig" :locationTreeDataWithRoot="locationTreeDataWithRoot" :employeeData="employeeData" @getEmployeeData="getEmployeeData" @submit="addLocation"/>
    <LocationEditDialog :dialogConfig="LocationEditDialogConfig" :locationTreeDataWithRoot="locationTreeDataWithRoot" :employeeData="employeeData" @getEmployeeData="getEmployeeData" @submit="editLocation"/>
  </div>
</template>

<script>
import BatchImportDialog from "../../components/BatchImportDialog.vue";
import LocationAddDialog from "../../components/LocationAddDialog.vue";
import LocationEditDialog from "../../components/LocationEditDialog.vue";
export default {
  components: {
    BatchImportDialog,
    LocationAddDialog,
    LocationEditDialog
  },
  data(){
    return {
      searchType: "normal",
      pageLoading: false,
      normalSearchData: "",
      normalSearchDataShow: "",
      locationTreeData: null,
      locationTreeDataWithRoot: [],
      defaultLocationTreeExpandedKeys: [],
      locationTreeSelectedKeys: [],
      locationTableColumns: [
        {title: '位置编号',dataIndex: 'code',key: 'code',align: 'center',width: 150,ellipsis: true},
        {title: '位置名称',dataIndex: 'name',key: 'name',align: 'center',width: 150,ellipsis: true},
        {title: '上级位置',dataIndex: 'parentName',key: 'parentName',align: 'center',width: 150,ellipsis: true},
        {title: '是否为库房',dataIndex: 'isStore',key: 'isStore',align: 'center',width: 100,customRender: (val) => {return val ? '是':'否'}},
        {title: '库房管理员',dataIndex: 'storeUserName',key: 'storeUserName',align: 'center',width: 120,ellipsis: true},
        {title: '操作',key: 'actions',align: 'center',width: 100,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      locationTableData: null,
      locationTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      isStoreChecked: false,
      BatchImportDialogConfig: { visible:false,requesting:false,title:"",templateUrl:"",templateName:"" },
      employeeData: null,
      LocationAddDialogConfig: { visible:false,requesting:false,parentId:null },
      LocationEditDialogConfig: { visible:false,requesting:false,location:{} }
    }
  },
  mounted(){
    this.getLocationTreeData(()=>{});
    this.getLocationTableData(()=>{});
    this.getEmployeeData();
  },
  methods: {
    //加载员工数据
    getEmployeeData(value){
      let that = this;
      let param = "pageNumber=1&pageSize=50&active=true";
      if(value!=null){ param=param+"&searchData="+value; }
      this.$get("/userEmployees?"+param,{})
      .then(function(res){ 
        that.employeeData = res.data.records;
      })
    },
    //获取树部分的数据
    getLocationTreeData(callback){
      let that = this;
      this.$get("/locationStructureWithRoot",{})
      .then(function(res){
        that.locationTreeDataWithRoot = res.data;
        that.locationTreeData = that.locationTreeDataWithRoot[0].children;
        that.locationTreeDataWithRoot[0].title = "无上级";
        if(that.locationTreeData[0]){ that.defaultLocationTreeExpandedKeys=[that.locationTreeData[0].id]; }
        callback();
      })
    },
    //获取表格数据
    getLocationTableData(callback){
      let that = this;
      let isStore = this.isStoreChecked? '&isStore=1':'';
      if(this.searchType=="normal"){
        this.$get("/locations?searchData="+that.normalSearchData+"&pageSize="+this.locationTablePagination.pageSize+"&pageNumber="+this.locationTablePagination.current+isStore,{})
        .then(function(res){
          that.locationTableData = res.data.records;
          const pager = { ...that.locationTablePagination };
          pager.total = res.data.total;
          that.locationTablePagination = pager;
          callback();
        })
      }else{
        this.$get("/locations/"+that.locationTreeSelectedKeys[0]+"?pageSize="+this.locationTablePagination.pageSize+"&pageNumber="+this.locationTablePagination.current+isStore,{})
        .then(function(res){
          that.locationTableData = res.data.records;
          const pager = { ...that.locationTablePagination };
          pager.total = res.data.total;
          that.locationTablePagination = pager;
          callback();
        })
      }
    },
    //触发树选择
    locationTreeSelect(selectedKeys){
      if(selectedKeys.length==0){ return; }
      this.locationTreeSelectedKeys = selectedKeys;
      this.searchType = "tree";
      this.normalSearchData = "";
      this.normalSearchDataShow = "";
      this.pageLoading = true;
      this.locationTablePagination.current = 1;
      this.getLocationTableData(()=>{ this.pageLoading = false; });
    },
    handleLocationTableChange(pagination){
      const pager = { ...this.locationTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.locationTablePagination = pager;
      this.pageLoading = true;
      this.getLocationTableData(()=>{ this.pageLoading = false; });
    },
    isStoreCheckedChange(checked){
      this.isStoreChecked = checked;
      this.pageLoading = true;
      this.getLocationTableData(()=>{ this.pageLoading = false; });
    },
    onNormalSearch(value){
      this.searchType = "normal";
      this.normalSearchData = value;
      this.pageLoading = true;
      this.locationTreeSelectedKeys = [];
      this.locationTablePagination.current = 1;
      this.getLocationTableData(()=>{ this.pageLoading = false; });
    },
    callLocationEditDialog(record){
      let fix = {...record};
      fix.isStore = record.isStore===true? "true":"false";
      this.LocationEditDialogConfig = { visible:true,requesting:false,location:fix };
    },
    editLocation(values){
      this.LocationEditDialogConfig.requesting = true;
      let that = this;
      this.$put("/location",values)
      .then(function(){
        that.LocationEditDialogConfig.requesting = false;
        that.LocationEditDialogConfig.visible = false;
        that.$message.success('保存成功');
        that.pageLoading = true;
        that.getLocationTreeData(()=>{
          that.getLocationTableData(()=>{ that.pageLoading=false; });
        });
      })
      .catch(function(){
        that.LocationEditDialogConfig.requesting = false;
      })
    },
    callLocationAddDialog(){
      this.LocationAddDialogConfig = { visible:true,requesting:false,parentId:null };
    },
    addLocation(values){
      this.LocationAddDialogConfig.requesting = true;
      let that = this;
      this.$post("/location",values)
      .then(function(){
        that.LocationAddDialogConfig.requesting = false;
        that.LocationAddDialogConfig.visible = false;
        that.$message.success('保存成功');
        that.pageLoading = true;
        that.getLocationTreeData(()=>{
          that.getLocationTableData(()=>{ that.pageLoading=false; });
        });
      })
      .catch(function(){
        that.LocationAddDialogConfig.requesting = false;
      })
    },
    callBatchImportDialog(){
      this.BatchImportDialogConfig = { visible:true,requesting:false,title:"批量导入位置",templateUrl:"/locations/export/excelTemplate",templateName:"位置模板.xls" };
    },
    batchImportLocations(values){
      this.BatchImportDialogConfig.requesting = true;
      let that = this;
      this.$post("/locations/import",values)
      .then(function(res){
        that.BatchImportDialogConfig.requesting = false;
        if(res.data.import_status==="error"){
          that.$message.warning({
            title: '错误提示',
            content: res.data.import_error_info,
            duration: 5
          });
        }else{
          that.BatchImportDialogConfig.visible = false;
          that.$message.success("导入成功");
          that.loactionTablePagination.current = 1;
          that.pageLoading = true;
          that.getLocationTreeData(()=>{
            that.getLocationTableData(()=>{ that.pageLoading=false; });
          });
        } 
      })
      .catch(function(){
        that.BatchImportDialogConfig.requesting = false;
      })
    },
    addLocationBaseRoot(record){
      this.LocationAddDialogConfig = { visible:true,requesting:false,parentId:record.id };
    },
    deleteLocation(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除【"+record.name+"】位置吗?",
        onOk(){
          that.pageLoading = true;
          that.$delete("/location/"+record.id,{})
          .then(function(){
            that.pageLoading=false;
            that.$message.success("删除成功");
            that.pageLoading = true;
            that.searchType = "normal";
            that.normalSearchData = "";
            that.locationTreeSelectedKeys = [];
            that.locationTablePagination.current = 1;
            that.getLocationTreeData(()=>{
              that.getLocationTableData(()=>{ that.pageLoading=false; });
            });
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    }
  }
}
</script>

<style lang="less" scoped>
.locationmanagement_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.locationmanagement_content{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
.locationmanagement_content_left{
  width: 300px;
  height: 100%;
  float: left;
  box-sizing: border-box;
  border-right: 1px solid #d1d7de;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px 0px 15px 15px;
}
.locationmanagement_content_right{
  width: calc(100% - 300px);
  height: 100%;
  float: left;
}
.locationmanagement_store{
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  float: left;
  margin-left: 10px;
}
.locationmanagement_store font+button{
  margin-left: 5px;
}
</style>