<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="添加字段扩展" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="550" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">保存</a-button>
    </template>
    <div class="extendadddialog_block">
      <a-spin :spinning="assetsTypeData==null||dialogConfig.requesting" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <a-form :form="form">
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="字段扩展名称">
                <a-input placeholder="请输入字段扩展名称" v-decorator="['labelName',{rules:[{required:true,message:'请输入字段扩展名称'},{ max: 20, message: '最多填写20个字符' }]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="应用分类">
                <a-tree-select 
                  style="width: 100%"
                  :tree-data="assetsTypeData"  
                  show-search 
                  treeNodeFilterProp="title" 
                  placeholder="请选择应用分类"
                  tree-default-expand-all 
                  v-decorator="['categoryId',{ rules: [{ required:true,message:'请选择应用分类' }] }]" 
                  :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="是否必填">
                <a-select v-decorator="['required',{ initialValue: 'false',rules: [{ required: true, message: '请选择是否必填' }] }]">
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object,
    assetsTypeData: Array
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 5 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 16 } },
      }
    }
  },
  methods: {
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        let fix = {...values};
        fix.required = fix.required ==="false" ? false : true;
        this.$emit('submit',fix);
      })
    },
    handleCancel(){
      this.dialogConfig.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.extendadddialog_block{
  width: 100%;
  height: auto;
  padding: 30px 0px 5px 50px;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
</style>