<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    :title="'【'+dialogConfig.assetDetail.code+'】资产履历'" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="750"
  >
    <template slot="footer">
      <a-button @click="handleCancel">关闭</a-button>
    </template>
    <div class="assetresumerdialog_block">
      <a-spin :spinning="dialogLoading" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <div class="assetresumerdialog_content_block">
          <div class="assetresumerdialog_content_item" v-for="(item,index) in resumerData" :key="index">{{item.content}}</div>
        </div>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object
  },
  watch: {
    'dialogConfig.visible': {
      handler(val){ if(val){ this.getAssetResumerData(); } },
    }
  },
  data(){
    return{
      dialogLoading: false,
      resumerData: [],
      attributeFilter:['maintenanceDateExpired','maintenanceContactInformation','maintenanceContacts','maintenanceProvider','notes','measureUnit','dateEstimated','categoryId','locationId','storeLocationId','receivedUserId','status','code','name','warehousedType','dateWarehoused','usageCase','brand','modelNumber','amount','disposeType','dateDisposed','dateReceived']
    }
  },
  methods: {
    getAssetResumerData(){
      this.resumerData = [];
      this.dialogLoading = true;
      let that = this;
      this.$get("/assetResumers/"+this.dialogConfig.assetDetail.id+"?pageNumber=1&pageSize=999999",{})
      .then(function(res){
        res.data.records.forEach(item => {
          let content = that.processData(item);
          if(content){
            that.resumerData.push(content);
          }
        });
        that.dialogLoading = false;
      })
    },
    processData(data){
      let temp = {content:''};
      if(this.attributeFilter.includes(data.fieldName)||data.labelName){
        //发生时间
        temp.content+='【'+this.$moment(data.dateEntered).format('YYYY-MM-DD')+'】';
        if(data.operation=='import_storage'){
          temp.content+='因【导入入库】原因，';
        }else if(data.operation=='add_storage'){
          temp.content+='因【新增入库】原因，';
        }else if(data.operation=='modify'){
          temp.content+='因【修改】原因，';
        }else if(data.operation=='distribute_assets'){
          temp.content+='因【分配资产】原因，';
        }else if(data.operation=='approval_agree'){
          temp.content+='因【审批通过】原因，';
        }else if(data.operation=='approval_reject'){
          temp.content+='因【审批拒绝】';
        }else if(data.operation=='receive_confirming'){
          temp.content+='因【领用】原因，';
        }else if(data.operation=='back_confirming'){
          temp.content+='因【退库】原因，';
        }else if(data.operation=='dispose_confirming'){
          temp.content+='因【处置】原因，';
        }else if(data.operation=='transfer_confirming'){
          temp.content+='因【转移】原因，';
        }else if(data.operation=='check_confirming'){
          temp.content+='因【盘点】原因，';
        }else if(data.operation=='terminate'){
          temp.content+='因【终止】原因，';
        }
        //变化字段
        let before = data.beforeValueString? '由【'+data.beforeValueString+'】':'';
        if(data.fieldName=='dateWarehoused'&&data.beforeValueString){
          before = '由【'+this.$moment(data.beforeValueString).format('YYYY-MM-DD')+'】';
        }else if(data.fieldName=='status'&&data.beforeValueString){
          let str = data.beforeValueString==='leisure'?'空闲':data.beforeValueString==='receive_approval'?'领用审批中':data.beforeValueString==='receive_confirming'?'待领用':
              data.beforeValueString==='receive_sign_confirming'?'领用待签字':data.beforeValueString==='receive_end'?'已领用':data.beforeValueString==='loan'?'借出':data.beforeValueString==='cancel_approval'?'退库审批中':
              data.beforeValueString==='dispose'?'处置':data.beforeValueString==='cancel_confirming'?'待退库':data.beforeValueString==='cancel_sign_confirming'?'退库待签字':
              data.beforeValueString==='transfer_confirming'?'待转移':data.beforeValueString==='transfer_sign_confirming'?'转移待签字':
              data.beforeValueString==='dispose_confirming'?'待处置':data.beforeValueString==='dispose_sign_confirming'?'处置待签字':
              data.beforeValueString==='dispose_end'?'已处置':data.beforeValueString;
          before = '由【'+str+'】';
        }else if(data.fieldName=='usageCase'&&data.beforeValueString){
          let str = data.beforeValueString==='normal'?'正常':data.beforeValueString==='fault'?'故障':'';
          before = '由【'+str+'】';
        }else if(data.fieldName=='warehousedType'&&data.beforeValueString){
          let str = data.beforeValueString==='purchase'?'采购':data.beforeValueString==='allocation'?'调拨':data.beforeValueString==='inventory_win'?'盘赢':
                data.beforeValueString==='donation'?'捐赠':data.beforeValueString==='others'?'其他':data.beforeValueString;
          before = '由【'+str+'】';
        }else if(data.fieldName=='disposeType'&&data.beforeValueString){
          let str = data.beforeValueString==='allocation'? '调拨':data.beforeValueString==='inventory_loss'? '盘亏':data.beforeValueString==='donation'? '捐赠':data.beforeValueString==='scrap'? '报废':data.beforeValueString==='broken'? '报损':data.beforeValueString==='loss'? '报失':data.beforeValueString==='others'?'其他':'';
          before = '由【'+str+'】';
        }
        if(data.fieldName=='status'){
          let str = data.afterValueString==='leisure'?'空闲':data.afterValueString==='receive_approval'?'领用审批中':data.afterValueString==='receive_confirming'?'待领用':
              data.afterValueString==='receive_sign_confirming'?'领用待签字':data.afterValueString==='receive_end'?'已领用':data.afterValueString==='loan'?'借出':data.afterValueString==='cancel_approval'?'退库审批中':
              data.afterValueString==='dispose'?'处置':data.afterValueString==='cancel_confirming'?'待退库':data.afterValueString==='cancel_sign_confirming'?'退库待签字':
              data.afterValueString==='transfer_confirming'?'待转移':data.afterValueString==='transfer_sign_confirming'?'转移待签字':
              data.afterValueString==='dispose_confirming'?'待处置':data.afterValueString==='dispose_sign_confirming'?'处置待签字':
              data.afterValueString==='dispose_end'?'已处置':data.afterValueString;
          temp.content+='【资产状态】'+before+'变更为【'+str+'】';
        }else if(data.fieldName=='code'){
          temp.content+='【资产编码】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='name'){
          temp.content+='【资产名称】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='warehousedType'){
          let str = data.afterValueString==='purchase'?'采购':data.afterValueString==='allocation'?'调拨':data.afterValueString==='inventory_win'?'盘赢':
                data.afterValueString==='donation'?'捐赠':data.afterValueString==='others'?'其他':data.afterValueString;
          temp.content+='【入库类型】'+before+'变更为【'+str+'】';
        }else if(data.fieldName=='dateWarehoused'){
          temp.content+='【入库时间】'+before+'变更为【'+this.$moment(data.afterValueString).format('YYYY-MM-DD')+'】';
        }else if(data.fieldName=='usageCase'){
          let str = data.afterValueString==='normal'?'正常':data.afterValueString==='fault'?'故障':'';
          temp.content+='【使用状况】'+before+'变更为【'+str+'】';
        }else if(data.fieldName=='brand'){
          temp.content+='【品牌】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='modelNumber'){
          temp.content+='【型号】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='amount'){
          temp.content+='【金额】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='disposeType'){
          let str = data.afterValueString==='allocation'? '调拨':data.afterValueString==='inventory_loss'? '盘亏':data.afterValueString==='donation'? '捐赠':data.afterValueString==='scrap'? '报废':data.afterValueString==='broken'? '报损':data.afterValueString==='loss'? '报失':data.afterValueString==='others'?'其他':'';
          temp.content+='【处置类型】'+before+'变更为【'+str+'】';
        }else if(data.fieldName=='dateDisposed'){
          temp.content+='【处置日期】'+before+'变更为【'+this.$moment(data.afterValueString).format('YYYY-MM-DD')+'】';
        }else if(data.fieldName=='dateReceived'){
          temp.content+='【领用日期】'+before+'变更为【'+this.$moment(data.afterValueString).format('YYYY-MM-DD')+'】';
        }else if(data.fieldName=='storeLocationId'){
          temp.content+='【所属库房】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='receivedUserId'){
          temp.content+='【领用人】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='locationId'){
          temp.content+='【资产位置】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='categoryId'){
          temp.content+='【资产分类】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='dateEstimated'){
          temp.content+='【使用期限】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='measureUnit'){
          temp.content+='【计量单位】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='notes'){
          temp.content+='【备注】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='maintenanceProvider'){
          temp.content+='【供应商】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='maintenanceContacts'){
          temp.content+='【联系人】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='maintenanceContactInformation'){
          temp.content+='【联系方式】'+before+'变更为【'+data.afterValueString+'】';
        }else if(data.fieldName=='maintenanceDateExpired'){
          temp.content+='【维保到期日期】'+before+'变更为【'+data.afterValueString+'】';
        }else{
          temp.content+='【'+data.labelName+'】'+before+'变更为【'+data.afterValueString+'】';
        }
        return temp;
      }else{
        return null;
      }
    },
    handleCancel(){
      this.dialogConfig.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.assetresumerdialog_block{
  width: 100%;
  height: auto;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
}
.assetresumerdialog_content_block{
  width: 100%;
  height: auto;
  float: left;
  min-height: 200px;
}
.assetresumerdialog_content_item{
  width: 100%;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
</style>