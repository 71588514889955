<template>
  <div class="default_main">
    <a-spin :spinning="organizationTreeData==null||(organizationTreeData.length!=0&&organizationTableData==null)||pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="organizationmanagement_left">
        <div class="organizationmanagement_header">
          <a-button class="f_right" type="primary" @click="callOrganizationAddDialog">添加组织</a-button>
        </div>
        <div class="organizationmanagement_tree">
          <a-tree v-if="organizationTreeData&&organizationTreeData.length>0" :treeData="organizationTreeData" :defaultExpandedKeys="defaultOrganizationTreeExpandedKeys" :blockNode="true" @select="organizationTreeSelect" :selectedKeys="organizationTreeSelectedKeys">
            <template slot="custom" slot-scope="item">
              <div class="tree_node">
              <div class="tree_n_title" :title="item.title">{{item.title}}</div>
              <div class="tree_n_board">
                <div class="tree_n_b_block">
                  <a-icon class="tree_n_b_icon" type="plus-circle" @click="addOrganizationBaseRoot(item)"/>
                  <a-icon class="tree_n_b_icon" type="edit" @click="callOrganizationEditDialog(item)"/>
                  <a-icon class="tree_n_b_icon" type="delete" @click="deleteOrganization(item)"/>
                </div>
              </div>
              </div>
            </template>
          </a-tree>
        </div>
      </div>
      <div class="organizationmanagement_right">
        <div class="organizationmanagement_header">
          <a-input-search class="default_searchinput" :disabled="organizationTreeData==null||organizationTreeData.length==0" placeholder="请输入员工编号、姓名或手机号" enter-button v-model="searchDataShow" @search="onSearch"/>
          <a-button class="f_right" type="primary" :disabled="organizationTreeData==null||organizationTreeData.length==0" @click="callAddUserToOrganizationDialog">选择员工</a-button>
        </div>
        <div class="organizationmanagement_table">
          <a-table 
            :columns="organizationTableColumns" 
            :data-source="organizationTableData" 
            :scroll="{ x: '100%',y: true}" 
            rowKey="id" 
            :pagination="organizationTablePagination" 
            @change="handleOrganizationTableChange"
          >
            <template slot="actions" slot-scope="text,record">
              <font class="table_action" @click="removeUserFormOrganization(record)">移除</font>
            </template>
          </a-table>
        </div>
      </div>
    </a-spin>
    <OrganizationAddDialog :dialogConfig="OrganizationAddDialogConfig" :organizationTreeDataWithRoot="organizationTreeDataWithRoot" @submit="addOrganization"/>
    <OrganizationEditDialog :dialogConfig="OrganizationEditDialogConfig" :organizationTreeDataWithRoot="organizationTreeDataWithRoot" @submit="editOrganization"/>
    <OrganizationUsersSelectDialog :dialogConfig="OrganizationUsersSelectDialogConfig" :employeesNoUnitData="employeesNoUnitData" @getEmployeesNoUnitData="getEmployeesNoUnitData" @submit="addUsersToUnit"/>
  </div>
</template>

<script>
import OrganizationAddDialog from "../../components/OrganizationAddDialog.vue";
import OrganizationEditDialog from "../../components/OrganizationEditDialog.vue";
import OrganizationUsersSelectDialog from "../../components/OrganizationUsersSelectDialog.vue";
export default {
  components: {
    OrganizationAddDialog,
    OrganizationEditDialog,
    OrganizationUsersSelectDialog
  },
  data(){
    return {
      pageLoading: false,
      organizationTreeData: null,
      organizationTreeDataWithRoot: null,
      defaultOrganizationTreeExpandedKeys: [],
      organizationTreeSelectedKeys: [],
      searchData: "",
      searchDataShow: "",
      organizationTableColumns: [
        {title: '员工编号',dataIndex: 'code',key: 'code',align: 'center',width: 120,ellipsis: true},
        {title: '姓名',dataIndex: 'name',key: 'name',align: 'center',width: 150,ellipsis: true},
        {title: '手机号',dataIndex: 'phoneNumber',key: 'phoneNumber',align: 'center',width: 120},
        {title: '操作',key: 'actions',align: 'center',width: 80,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      organizationTableData: null,
      organizationTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      OrganizationAddDialogConfig: { visible:false,requesting:false,parentId:null },
      OrganizationEditDialogConfig: { visible:false,requesting:false,organization:{} },
      employeesNoUnitData: null,
      OrganizationUsersSelectDialogConfig: { visible:false,requesting:false }
    }
  },
  mounted(){
    this.getOrganizationTreeData(()=>{});
    this.getEmployeesNoUnitData();
  },
  methods: {
    //获取树部分的数据
    getOrganizationTreeData(callback){
      let that = this;
      this.$get("/unitStructureWithRoot",{})
      .then(function(res){
        that.organizationTreeDataWithRoot = res.data;
        that.organizationTreeData = that.organizationTreeDataWithRoot[0].children;
        that.organizationTreeDataWithRoot[0].title = "无上级";
        if(that.organizationTreeData[0]){that.defaultOrganizationTreeExpandedKeys=[that.organizationTreeData[0].id];}
        if((!that.organizationTreeSelectedKeys.length)&&that.organizationTreeData[0]){that.organizationTreeSelect([that.organizationTreeData[0].id]);}
        callback();
      })
    },
    organizationTreeSelect(selectedKeys){
      if(selectedKeys.length){
        this.organizationTreeSelectedKeys = selectedKeys;
        this.pageLoading = true;
        this.organizationTablePagination.current = 1;
        this.getOrganizationTableData(()=>{ this.pageLoading = false; });
      }
    },
    //按树选择和搜索内容获取表格数据
    getOrganizationTableData(callback){
      let that = this;
      let param = this.searchData&&this.searchData!=""? "&searchData="+this.searchData:"";
      this.$get("/unit/"+that.organizationTreeSelectedKeys[0]+"/unitUserEmployees?pageSize="+this.organizationTablePagination.pageSize+"&pageNumber="+this.organizationTablePagination.current+param,{})
      .then(function(res){
        that.organizationTableData = res.data.records;
        const pager = { ...that.organizationTablePagination };
        pager.total = res.data.total;
        that.organizationTablePagination = pager;
        callback();
      })
    },
    onSearch(value){
      this.searchData = value;
      this.pageLoading = true;
      this.organizationTablePagination.current = 1;
      this.getOrganizationTableData(()=>{ this.pageLoading = false; });
    },
    handleOrganizationTableChange(pagination){
      const pager = { ...this.organizationTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.organizationTablePagination = pager;
      this.pageLoading = true;
      this.getOrganizationTableData(()=>{ this.pageLoading = false; });
    },
    callOrganizationAddDialog(){
      this.OrganizationAddDialogConfig = { visible:true,requesting:false,parentId:null };
    },
    addOrganization(values){
      this.OrganizationAddDialogConfig.requesting = true;
      let that = this;
      this.$post("/unit",values)
      .then(function(){
        that.OrganizationAddDialogConfig.requesting = false;
        that.OrganizationAddDialogConfig.visible = false;
        that.$message.success('保存成功');
        that.pageLoading = true;
        that.getOrganizationTreeData(()=>{ that.pageLoading=false; });
      })
      .catch(function(){
        that.OrganizationAddDialogConfig.requesting = false;
      })
    },
    addOrganizationBaseRoot(record){
      this.OrganizationAddDialogConfig = { visible:true,requesting:false,parentId:record.id };
    },
    callOrganizationEditDialog(record){
      this.OrganizationEditDialogConfig = { visible:true,requesting:false,organization:{...record} };
    },
    editOrganization(values){
      this.OrganizationEditDialogConfig.requesting = true;
      let that = this;
      this.$put("/unit",values)
      .then(function(){
        that.OrganizationEditDialogConfig.requesting = false;
        that.OrganizationEditDialogConfig.visible = false;
        that.$message.success('保存成功');
        that.pageLoading = true;
        that.getOrganizationTreeData(()=>{ that.pageLoading=false; });
      })
      .catch(function(){
        that.OrganizationEditDialogConfig.requesting = false;
      })
    },
    deleteOrganization(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除【"+record.name+"】组织吗?",
        onOk(){
          that.pageLoading = true;
          that.$delete("/unit/"+record.id,{})
          .then(function(){
            that.$message.success("删除成功");
            that.organizationTreeSelectedKeys = [];
            that.getOrganizationTreeData(()=>{ that.pageLoading = false; });
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    removeUserFormOrganization(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "移除操作",
        content: "确定从当前组织中移除【"+record.name+"】吗?",
        onOk(){
          that.pageLoading = true;
          that.$delete("/unit/"+that.organizationTreeSelectedKeys[0]+"/unitUserEmployee/"+record.id,{})
          .then(function(){
            that.$message.success("移除成功");
            if(that.organizationTableData.length==1&&that.organizationTablePagination.current>1){ that.organizationTablePagination.current--; }
            that.getOrganizationTableData(()=>{ that.pageLoading = false; });
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    getEmployeesNoUnitData(value){
      let that = this;
      let search = value? "&searchData="+value:"&searchData=";
      this.$get("/userEmployees/noUnit?pageNumber=1&pageSize=999999"+search,{})
      .then(function(res){
        that.employeesNoUnitData = res.data.records;
      })
    },
    callAddUserToOrganizationDialog(){
      this.OrganizationUsersSelectDialogConfig = { visible:true,requesting:false };
    },
    addUsersToUnit(values){
      this.OrganizationUsersSelectDialogConfig.requesting = true;
      let that = this;
      this.$post("/unit/"+this.organizationTreeSelectedKeys[0]+"/unitUserEmployee",values.approvalUserId)
      .then(function(){
        that.$message.success('保存成功');
        that.OrganizationUsersSelectDialogConfig.visible = false;
        that.OrganizationUsersSelectDialogConfig.requesting = false;
        that.pageLoading = true;
        that.getOrganizationTableData(()=>{
          that.pageLoading = false;
        });
      })
      .catch(function(){
        that.OrganizationUsersSelectDialogConfig.requesting = false;
      })
    }
  }
}
</script>

<style lang="less" scoped>
.organizationmanagement_left{
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid #d1d7de;
  float: left;
}
.organizationmanagement_right{
  width: calc(100% - 300px);
  height: 100%;
  float: left;
}
.organizationmanagement_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.organizationmanagement_tree{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px 0px 15px 15px;
}
.organizationmanagement_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
</style>