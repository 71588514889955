<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="创建盘点" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="550" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">开始盘点</a-button>
    </template>
    <div class="inventoryadddialog_block">
      <a-spin :spinning="assetsTypeData==null||assetsLocationData==null||dialogConfig.requesting" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <div class="inventoryadddialog_main_block">
          <a-form :form="form">
            <a-row :gutter="24">
              <a-col :span="24" class="inventoryadddialog_title">基本信息</a-col>
              <a-col :span="24"><a-form-item v-bind="formItemLayout" label="盘点名称"><a-input v-decorator="['name',{rules: [{ required: true, message: '请输入盘点名称' },{ max: 20, message: '最多填写20个字符' }]}]" placeholder="请输入盘点名称"/></a-form-item></a-col>
              <a-col :span="24">
                <a-form-item v-bind="formItemLayout" label="计划起始日期"><a-range-picker v-decorator="['checkDate']" /></a-form-item>
              </a-col>
            </a-row>
            <a-divider dashed/>
            <a-row :gutter="24">
              <a-col :span="24" class="inventoryadddialog_title">盘点范围</a-col>
              <a-col :span="24">
                <a-form-item v-bind="formItemLayout" label="资产分类">
                  <a-tree-select 
                    style="width: 100%"
                    :tree-data="assetsTypeData" 
                    placeholder="请选择资产分类" 
                    show-search 
                    :allow-clear="true" 
                    tree-checkable 
                    treeNodeFilterProp='title' 
                    tree-default-expand-all 
                    v-decorator="['categoryIds']" 
                    :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item v-bind="formItemLayout" label="资产位置">
                  <a-tree-select 
                    style="width: 100%" 
                    :tree-data="assetsLocationData" 
                    placeholder="请选择资产位置" 
                    show-search 
                    :allow-clear="true" 
                    tree-checkable 
                    treeNodeFilterProp='title' 
                    tree-default-expand-all 
                    v-decorator="['locationIds']" 
                    :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object,
    assetsTypeData: Array,
    assetsLocationData: Array
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 6 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 18 } },
      }
    }
  },
  methods: {
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        let fix = {...values};
        if(Object.prototype.hasOwnProperty.call(values, 'checkDate')||values.checkDate.size()>0){
          fix = {...fix,...{startTime:values.checkDate[0]},...{endTime:values.checkDate[1]}};
        }
        this.$emit('submit',fix);
      })
    },
    handleCancel(){
      this.dialogConfig.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.inventoryadddialog_block{
  width: 100%;
  height: auto;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.inventoryadddialog_main_block{
  width: 100%;
  height: auto;
  min-height: 200px;
  padding: 10px 50px 10px 30px;
}
.inventoryadddialog_title{
  height: 60px;
  line-height: 60px;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
/deep/ .ant-divider-horizontal{
  margin: 8px 0;
}
</style>