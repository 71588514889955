<template>
  <div class="default_main">
    <a-spin :spinning="pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <a-table 
        :columns="applyListTableColumns" 
        :data-source="applyListTableData" 
        :scroll="{ x: '100%',y: true}" 
        rowKey="id" 
        :pagination="applyListTablePagination" 
        @change="handleApplyListTableChange"
      >
        <template slot="status" slot-scope="status">
          <a-tag :color="status==='processed'? '#108EE9':status==='terminated'? '':'#87d068'">
            {{ status==='untreated'?'进行中':status==='processed'?'已完成':status==='terminated'?'已终止':status}} 
          </a-tag>
        </template>
        <template slot="description" slot-scope="text,record">
          <font class="table_action" @click="callApplyDetailDialog(record)">{{text}}</font>
        </template>
        <template slot="actions" slot-scope="text,record">
          <font class="table_action" @click="DistributionAssets(record)">派发</font>
          <font class="table_action" v-if="record.status=='untreated'" @click="doneApply(record)">完成</font>
          <font class="table_action disabled" v-if="record.status!='untreated'">完成</font>
          <font class="table_action" v-if="record.status=='untreated'" @click="rejectApply(record)">驳回</font>
          <font class="table_action disabled" v-if="record.status!='untreated'">驳回</font>
          <font class="table_action" @click="deleteApply(record)">删除</font>
        </template>
      </a-table>
    </a-spin>
    <DistributionAssetsDialog :dialogConfig="DistributionAssetsDialogConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :employeeData="employeeData" :assetsColumns="[...distributionAssetsColumns]" @getEmployeeData="getEmployeeData" @submit="handleDistributionSubmit"/>
    <ApplyDetailDialog :dialogConfig="ApplyDetailDialogConfig" @doneApply="doneApply" @rejectApply="rejectApply" @getApplyListTableData="getApplyListTableData" @DistributionAssets="DistributionAssets"/>
  </div>
</template>

<script>
import tableColumns from "../../utils/tableColumns";
import DistributionAssetsDialog from "../../components/DistributionAssetsDialog.vue";
import ApplyDetailDialog from "../../components/ApplyDetailDialog.vue";
export default {
  components: {
    DistributionAssetsDialog,
    ApplyDetailDialog
  },
  data(){
    return {
      pageLoading: false,
      applyListTableColumns: [
        {title: '申请人',dataIndex: 'applyUserName',key: 'applyUserName',align: 'center',width: 150,ellipsis: true},
        {title: '手机号码',dataIndex: 'phoneNumber',key: 'phoneNumber',align: 'center',width: 120},
        {title: '单据状态',dataIndex: 'status',key: 'status',align: 'center',width: 120,scopedSlots: { customRender: 'status' }},
        {title: '申请内容',dataIndex: 'description',key: 'description',align: 'center',width: 250,ellipsis: true,scopedSlots: { customRender: 'description' }},
        {title: '提交日期',dataIndex: 'dateEntered',key: 'dateEntered',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '完成日期',dataIndex: 'dateProcessed',key: 'dateProcessed',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '备注',dataIndex: 'deliveryNotes',key: 'deliveryNotes',align: 'center',width: 200,ellipsis: true},
        {title: '操作',key: 'actions',align: 'center',width: 160,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      applyListTableData: [],
      applyListTablePagination:{ current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      DistributionAssetsDialogConfig: { visible:false,requesting:false,assets:[],apply:{},action:"applyList" },
      assetsTypeData: null,
      assetsLocationData: null,
      employeeData: null,
      assetsListTableColumns: [...tableColumns.assetsListTableColumns],
      distributionAssetsColumns: [],
      ApplyDetailDialogConfig: { visible:false,applyDetail:{} }
    }
  },
  mounted(){
    this.getApplyListTableData();
    this.getExtentionData(()=>{ this.distributionAssetsColumns = [...this.assetsListTableColumns]; });
    this.getAssetsTypeTreeData();
    this.getAssetsLocationTreeData();
    this.getEmployeeData();
  },
  methods: {
    //加载扩展列信息
    getExtentionData(callback){
      let that = this;
      this.$get("/categoryExtendFields?pageNumber=1&pageSize=999999&searchData=",{})
      .then(function(res){
        that.extentionData = res.data.records;
        that.extentionData.forEach(item => {
          that.assetsListTableColumns.push({ title:item.labelName,dataIndex:item.fieldLabel,key:item.fieldLabel,align:'center',ellipsis: true,width: 120 });
        })
        callback();
      })
    },
    //获取资产分类数据
    getAssetsTypeTreeData(){
      let that = this;
      this.$get("/categoryStructure",{})
      .then(function(res){
        that.assetsTypeData = res.data;
      })
    },
    //获取资产位置数据
    getAssetsLocationTreeData(){
      let that = this;
      this.$get("/locationStructure",{})
      .then(function(res){
        that.assetsLocationData = res.data;
      })
    },
    //加载员工数据
    getEmployeeData(value){
      let that = this;
      let param = "pageNumber=1&pageSize=50&active=true";
      if(value!=null){ param=param+"&searchData="+value; }
      this.$get("/userEmployees?"+param,{})
      .then(function(res){ 
        that.employeeData = res.data.records;
      })
    },
    handleApplyListTableChange(pagination){
      const pager = { ...this.applyListTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.applyListTablePagination = pager;
      this.getApplyListTableData();
    },
    getApplyListTableData(){
      this.pageLoading = true;
      let that = this;
      this.$get("/applies?pageNumber="+this.applyListTablePagination.current+"&pageSize="+this.applyListTablePagination.pageSize,{})
      .then(function(res){
        that.pageLoading = false;
        that.applyListTableData = res.data.records;
        const pager = { ...that.applyListTablePagination };
        pager.total = res.data.total;
        that.applyListTablePagination = pager;
      })
    },
    doneApply(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "完成操作",
        content: "确定处理完成资产申请吗?",
        onOk(){
          that.pageLoading = true;
          that.$put("/apply",{...record,status:"processed"})
          .then(function(){
            that.pageLoading = false;
            that.$message.success("完成成功");
            that.getApplyListTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    rejectApply(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "驳回操作",
        content: "确定驳回资产申请吗?",
        onOk(){
          that.pageLoading = true;
          that.$put("/apply",{...record,status:"terminated"})
          .then(function(){
            that.pageLoading = false;
            that.$message.success("驳回成功");
            that.getApplyListTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    deleteApply(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除资产申请记录吗?",
        onOk(){
          that.pageLoading = true;
          that.$delete("/apply/"+record.id,{})
          .then(function(){
            that.pageLoading = false;
            that.$message.success("删除成功");
            if(that.applyListTableData.length==1&&that.applyListTablePagination.current>1){ that.applyListTablePagination.current--; }
            that.getApplyListTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    DistributionAssets(record){
      this.DistributionAssetsDialogConfig = { visible:true,requesting:false,assets:[],apply:record,action:"applyList" };
    },
    handleDistributionSubmit(values){
      this.DistributionAssetsDialogConfig.requesting = true;
      let that = this;
      this.$post("/orderDelivery",values)
      .then(function(){
        that.DistributionAssetsDialogConfig.visible = false;
        that.DistributionAssetsDialogConfig.requesting = false;
        that.$message.success("派发成功");
        that.getAssetsListTableData();
      })
      .catch(function(){
        that.DistributionAssetsDialogConfig.requesting = false;
      })
    },
    callApplyDetailDialog(record){
      this.ApplyDetailDialogConfig = { visible:true,applyDetail:record };
    }
  }
}
</script>

<style lang="less" scoped>

</style>