<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    :title="'【'+dialogConfig.assetDetail.code+'】资产详细信息'" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="960" 
    :closable="true"
  >
    <template slot="footer">
      <a-button @click="handleCancel">关闭</a-button>
    </template>
    <div class="assetdetaildialog_block">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="24">资产基本信息</a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="资产编码" :title="dialogConfig.assetDetail.code">{{dialogConfig.assetDetail.code}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="资产名称" :title="dialogConfig.assetDetail.name">{{dialogConfig.assetDetail.name}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="资产分类" :title="dialogConfig.assetDetail.categoryName">{{dialogConfig.assetDetail.categoryName}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="资产位置" :title="dialogConfig.assetDetail.locationName">{{dialogConfig.assetDetail.locationName}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="入库类型" :title="dialogConfig.assetDetail.warehousedType">{{dialogConfig.assetDetail.warehousedType}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="入库日期" :title="dialogConfig.assetDetail.dateWarehoused">{{dialogConfig.assetDetail.dateWarehoused}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="所属库房" :title="dialogConfig.assetDetail.storeLocationName">{{dialogConfig.assetDetail.storeLocationName}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="使用状况" :title="dialogConfig.assetDetail.usageCase">{{dialogConfig.assetDetail.usageCase}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="品牌" :title="dialogConfig.assetDetail.brand">{{dialogConfig.assetDetail.brand}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="型号" :title="dialogConfig.assetDetail.modelNumber">{{dialogConfig.assetDetail.modelNumber}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="金额" :title="dialogConfig.assetDetail.amount">{{dialogConfig.assetDetail.amount}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="使用期限" :title="dialogConfig.assetDetail.dateEstimated">{{dialogConfig.assetDetail.dateEstimated}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="计量单位" :title="dialogConfig.assetDetail.measureUnit">{{dialogConfig.assetDetail.measureUnit}}</a-form-item></a-col>
          <a-col :span="24"><a-form-item style="margin-left:-8px" v-bind="formItemSingleLayout" label="备注" :title="dialogConfig.assetDetail.notes">{{dialogConfig.assetDetail.notes}}</a-form-item></a-col>
        </a-row>
        <a-divider dashed/>
        <a-row :gutter="24" v-if="extentionDataFit.length>0">
          <a-col :span="24">资产扩展信息</a-col>
          <a-col v-for="(item,index) in extentionDataFit" :key="index" :span="8"><a-form-item v-bind="formItemLayout" :label="item.labelName" :title="dialogConfig.assetDetail[extentionDataFit[index].fieldLabel]">{{dialogConfig.assetDetail[extentionDataFit[index].fieldLabel]}}</a-form-item></a-col>
        </a-row>
        <a-divider v-if="extentionDataFit.length>0" dashed/>
        <a-row :gutter="24">
          <a-col :span="24">资产维保信息</a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="供应商" :title="dialogConfig.assetDetail.maintenanceProvider">{{dialogConfig.assetDetail.maintenanceProvider}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="联系人" :title="dialogConfig.assetDetail.maintenanceContacts">{{dialogConfig.assetDetail.maintenanceContacts}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="联系方式" :title="dialogConfig.assetDetail.maintenanceContactInformation">{{dialogConfig.assetDetail.maintenanceContactInformation}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="到期日期" :title="dialogConfig.assetDetail.maintenanceDateExpired">{{dialogConfig.assetDetail.maintenanceDateExpired}}</a-form-item></a-col>
        </a-row>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object,
    extentionData: Array
  },
  watch: {
    'dialogConfig.visible': {
      handler(val){ if(val){ this.processData(); } },
    }
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 9 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 15 } },
      },
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 3 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 21 } },
      },
      extentionDataFit: []
    }
  },
  methods: {
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    processData(){
      if(this.dialogConfig.assetDetail.warehousedType){ this.dialogConfig.assetDetail.warehousedType = this.dialogConfig.assetDetail.warehousedType==='purchase'?'采购':this.dialogConfig.assetDetail.warehousedType==='allocation'?'调拨':this.dialogConfig.assetDetail.warehousedType==='inventory_win'?'盘赢':this.dialogConfig.assetDetail.warehousedType==='donation'?'捐赠':this.dialogConfig.assetDetail.warehousedType==='others'?'其他':this.dialogConfig.assetDetail.warehousedType}
      if(this.dialogConfig.assetDetail.dateWarehoused){ this.dialogConfig.assetDetail.dateWarehoused = this.$moment(this.dialogConfig.assetDetail.dateWarehoused).format('YYYY-MM-DD') }
      if(this.dialogConfig.assetDetail.usageCase){ this.dialogConfig.assetDetail.usageCase = this.dialogConfig.assetDetail.usageCase==='normal'?'正常':this.dialogConfig.assetDetail.usageCase==='fault'?'故障':'' }
      if(this.dialogConfig.assetDetail.dateEstimated){ this.dialogConfig.assetDetail.dateEstimated+="月" }
      if(this.dialogConfig.assetDetail.maintenanceDateExpired){ this.dialogConfig.assetDetail.maintenanceDateExpired = this.$moment(this.dialogConfig.assetDetail.maintenanceDateExpired).format('YYYY-MM-DD') }
      this.extentionDataFit = [];
      this.extentionData.forEach(item => {
        if(item.type==="type_global"||item.categoryId===this.dialogConfig.assetDetail.categoryId){ this.extentionDataFit.push(item); }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.assetdetaildialog_block{
  width: 100%;
  height: auto;
  padding: 15px 30px 10px 30px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
/deep/ .ant-col{
  height: 40px;
  line-height: 40px;
}
/deep/ .ant-form-item-label{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .ant-form-item-control{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .ant-form-item:hover{
  background-color: rgba(27, 42, 78, 0.05);
}
/deep/ .ant-divider-horizontal{
  margin: 8px 0;
}
</style>