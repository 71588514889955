<template>
  <div class="default_main">
    <a-spin :spinning="pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
        <div class="distributionmanagement_header">
          <a-input-search class="default_searchinput" placeholder="请输入派发单号或领用人" enter-button v-model="searchDataShow" @search="onSearch"/>
          <a-dropdown>
            <a-button class="f_right">导出派发单据</a-button>
            <a-menu slot="overlay">
              <a-menu-item @click="exportDistributions('all')">导出全部单据</a-menu-item>
              <a-menu-item @click="exportDistributions('selected')">导出选中单据</a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-button class="f_right" type="primary" @click="DistributionAssets">开始派发</a-button>
        </div>
        <div class="distributionmanagement_table">
          <a-table 
            :columns="distributionListTableColumns" 
            :data-source="distributionListTableData" 
            :row-selection="{ selectedRowKeys: distributionListTableSelectedRowKeys,onChange: distributionListTableOnSelectChange }" 
            :scroll="{ x: '100%',y: true}" 
            rowKey="id" 
            :pagination="distributionListTablePagination" 
            @change="handleDistributionListTableChange"
          >
            <template slot="actions" slot-scope="text,record">
              <font class="table_action"  v-if="record.status=='receive_confirming'||record.status=='approval'" @click="stopDistribution(record)">终止</font>
              <font class="table_action disabled" v-if="record.status!='receive_confirming'&&record.status!='approval'">终止</font>
              <font class="table_action" v-if="record.status=='terminated'||record.status=='rejected'" @click="deleteDistribution(record)">删除</font>
              <font class="table_action disabled" v-if="record.status!='terminated'&&record.status!='rejected'">删除</font>
              <font class="table_action" v-if="record.signId" @click="callSignDetailDialog(record)">签字</font>
              <font class="table_action disabled" v-if="!record.signId">签字</font>
            </template>
            <template slot="status" slot-scope="status">
              <a-tag :color="status==='receive_end'? '#108EE9':status==='terminated'||status==='rejected'? '':'#87d068'">
                {{ status==='approval'?'审批中':status==='receive_confirming'?'进行中':status==='receive_end'?'已完成':status==='terminated'?'已终止':status==='rejected'?'已驳回':status}} 
              </a-tag>
            </template>
            <template slot="distributionCode" slot-scope="text,record">
              <font class="table_action" @click="callDistributionDetailDialog(record)">{{text}}</font>
            </template>
          </a-table>
      </div>
    </a-spin>
    <DistributionAssetsDialog :dialogConfig="DistributionAssetsDialogConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :employeeData="employeeData" :assetsColumns="[...distributionAssetsColumns]" @getEmployeeData="getEmployeeData" @submit="handleDistributionSubmit"/>
    <DistributionDetailDialog :dialogConfig="DistributionDetailDialogConfig" :assetsColumns="[...distributionAssetsColumns]"/>
    <SignDetailDialog :dialogConfig="SignDetailDialogConfig"/>
  </div>
</template>

<script>
import tableColumns from "../../utils/tableColumns";
import DistributionAssetsDialog from "../../components/DistributionAssetsDialog.vue";
import DistributionDetailDialog from "../../components/DistributionDetailDialog.vue";
import SignDetailDialog from "../../components/SignDetailDialog.vue";
export default {
  components: {
    DistributionAssetsDialog,
    DistributionDetailDialog,
    SignDetailDialog
  },
  data(){
    return {
      pageLoading: false,
      searchDataShow: "",
      searchData: "",
      distributionListTableColumns: [
        {title: '单据状态',dataIndex: 'status',key: 'status',align: 'center',width: 120,scopedSlots: { customRender: 'status' }},
        {title: '派发单号',dataIndex: 'orderDeliveryCode',key: 'orderDeliveryCode',align: 'center',ellipsis: true,width: 200,scopedSlots: { customRender: 'distributionCode' }},
        {title: '领用人',dataIndex: 'receivedUserName',key: 'receivedUserName',align: 'center',width: 120,ellipsis: true},
        {title: '领用后位置',dataIndex: 'locationName',key: 'locationName',align: 'center',ellipsis: true,width: 200},
        {title: '审批人',dataIndex: 'approvalUserName',key: 'approvalUserName',align: 'center',width: 120,ellipsis: true},
        {title: '审批结果',dataIndex: 'approvalAction',key: 'approvalAction',align: 'center',width: 120,customRender: (val) => {return val=='approval_agree' ? '同意':val=='approval_reject'? '驳回':''}},
        {title: '审批意见',dataIndex: 'approvalNotes',key: 'approvalNotes',align: 'center',ellipsis: true,width: 200},
        {title: '备注',dataIndex: 'notes',key: 'notes',align: 'center',ellipsis: true,width: 250},
        {title: '创建日期',dataIndex: 'dateEntered',key: 'dateEntered',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '完成日期',dataIndex: 'dateProcessed',key: 'dateProcessed',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '操作',key: 'actions',align: 'center',width: 130,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      distributionListTableData: [],
      distributionListTableSelectedRowKeys: [],
      distributionListTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      DistributionAssetsDialogConfig: { visible:false,requesting:false,assets:[],action:"distributionManagement" },
      assetsTypeData: null,
      assetsLocationData: null,
      employeeData: null,
      distributionAssetsColumns: [...tableColumns.assetsListTableColumns],
      DistributionDetailDialogConfig: { visible:false,distributionDetail:{} },
      SignDetailDialogConfig: { visible:false,fileId:null }
    }
  },
  mounted(){
    this.getDistributionListTableData();
    this.getExtentionData();
    this.getAssetsTypeTreeData();
    this.getAssetsLocationTreeData();
    this.getEmployeeData();
  },
  methods: {
    //开始派发
    DistributionAssets(){
      this.DistributionAssetsDialogConfig = { visible:true,requesting:false,assets:[],action:"distributionManagement" };
    },
    //获取资产分类数据
    getAssetsTypeTreeData(){
      let that = this;
      this.$get("/categoryStructure",{})
      .then(function(res){
        that.assetsTypeData = res.data;
      })
    },
    //获取资产位置数据
    getAssetsLocationTreeData(){
      let that = this;
      this.$get("/locationStructure",{})
      .then(function(res){
        that.assetsLocationData = res.data;
      })
    },
    //加载员工数据
    getEmployeeData(value){
      let that = this;
      let param = "pageNumber=1&pageSize=50&active=true";
      if(value!=null){ param=param+"&searchData="+value; }
      this.$get("/userEmployees?"+param,{})
      .then(function(res){ 
        that.employeeData = res.data.records;
      })
    },
    //加载扩展列信息
    getExtentionData(){
      let that = this;
      this.$get("/categoryExtendFields?pageNumber=1&pageSize=999999&searchData=",{})
      .then(function(res){
        res.data.records.forEach(item => {
          that.distributionAssetsColumns.push({ title:item.labelName,dataIndex:item.fieldLabel,key:item.fieldLabel,align:'center',ellipsis: true,width: 120 });
        })
      })
    },
    callSignDetailDialog(record){
      this.SignDetailDialogConfig = { visible:true,fileId:record.signId };
    },
    callDistributionDetailDialog(record){
      this.DistributionDetailDialogConfig = { visible:true,distributionDetail:record };
    },
    onSearch(value){
      this.searchData = value;
      this.distributionListTablePagination.current = 1;
      this.getDistributionListTableData();
    },
    getDistributionListTableData(){
      this.pageLoading = true;
      let that = this;
      this.$get("/orderDeliveries?pageNumber="+this.distributionListTablePagination.current+"&pageSize="+this.distributionListTablePagination.pageSize+"&searchData="+this.searchData,{})
      .then(function(res){
        that.pageLoading = false;
        that.distributionListTableSelectedRowKeys = [];
        that.distributionListTableData = res.data.records;
        const pager = { ...that.distributionListTablePagination };
        pager.total = res.data.total;
        that.distributionListTablePagination = pager;
      })
    },
    distributionListTableOnSelectChange(selectedRowKeys){
      this.disposesListTableSelectedRowKeys = selectedRowKeys;
    },
    handleDistributionListTableChange(pagination){
      const pager = { ...this.distributionListTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.distributionListTablePagination = pager;
      this.getDistributionListTableData();
    },
    exportDistributions(type){
      let selected = [];
      if(type==='selected'){ 
        if(this.distributionListTableSelectedRowKeys.length===0){ this.$message.warning("请至少选择一个单据");return; }
        selected = this.distributionListTableSelectedRowKeys;
      }
      let that = this;
      this.$message.loading({content:"正在导出，请耐心等待",key:"exportDistributions",duration:0});
      this.$postFile("/orderDelivery/export/excel",selected)
      .then(function(res){
        if(!res) return;
        that.$message.success({content:"导出完成",key:"exportDistributions",duration:2});
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' }) // 构造一个blob对象来处理数据，并设置文件类型
        if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
            navigator.msSaveBlob(blob, "派发单列表.xls")
        } else {
          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = "派发单列表.xls" //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        }
      })
      .catch(function(){
        that.$message.error({content:"导出失败",key:"exportDistributions",duration:2});
      })
    },
    handleDistributionSubmit(values){
      this.DistributionAssetsDialogConfig.requesting = true;
      let that = this;
      this.$post("/orderDelivery",values)
      .then(function(){
        that.DistributionAssetsDialogConfig.visible = false;
        that.DistributionAssetsDialogConfig.requesting = false;
        that.$message.success("保存成功");
        that.getDistributionListTableData();
      })
      .catch(function(){
        that.DistributionAssetsDialogConfig.requesting = false;
      })
    },
    deleteDistribution(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除【"+record.orderDeliveryCode+"】派发单吗?",
        onOk(){
          that.pageLoading = true;
          that.$delete("/orderDelivery/"+record.id,{})
          .then(function(){
            that.pageLoading=false;
            that.$message.success("删除成功");
            if(that.distributionListTableData.length==1&&that.distributionListTablePagination.current>1){ that.distributionListTablePagination.current--; }
            that.getDistributionListTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    stopDistribution(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "终止操作",
        content: "确定终止【"+record.orderDeliveryCode+"】派发单吗?",
        onOk(){
          that.pageLoading = true;
          that.$put("/orderDelivery/"+record.id+"/terminate",{})
          .then(function(){
            that.pageLoading=false;
            that.$message.success("终止成功");
            that.getDistributionListTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    }
  }
}
</script>

<style lang="less" scoped>
.distributionmanagement_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.distributionmanagement_header button+button{
  margin-right: 5px;
}
.distributionmanagement_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
</style>