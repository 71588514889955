<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="修改员工信息" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="450" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">保存</a-button>
    </template>
    <div class="employeeeditdialog_block">
      <a-spin :spinning="dialogConfig.requesting" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <a-form :form="form" style="padding: 30px 0px 5px 30px;">
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="员工编号">
                <a-input placeholder="请输入员工编号" v-decorator="['code',{initialValue:dialogConfig.employee.code,rules:[{required:true,message:'请输入员工编号'},{ max: 20, message: '最多填写20个字符' }]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="姓名">
                <a-input placeholder="请输入姓名" v-decorator="['name',{initialValue:dialogConfig.employee.name,rules:[{required:true,message:'请输入姓名'},{ max: 20, message: '最多填写20个字符' }]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="手机号码">
                <a-input placeholder="请输入手机号码" v-decorator="['phoneNumber',{initialValue:dialogConfig.employee.phoneNumber,rules:[{required:true,message:'请输入手机号码'},{ max: 20, message: '最多填写20个字符' },{pattern: /^1[3-9][0-9]\d{8}$/,message: '手机号格式不正确'}]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="邮箱">
                <a-input placeholder="请输入邮箱" v-decorator="['email',{initialValue:dialogConfig.employee.email,rules:[{ max: 50, message: '最多填写50个字符' },{pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,message: '邮箱格式不正确'}]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="职位">
                <a-input placeholder="请输入职位" v-decorator="['position',{initialValue:dialogConfig.employee.position,rules:[{ max: 20, message: '最多填写20个字符' }]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="备注">
                <a-input placeholder="请输入备注" v-decorator="['notes',{initialValue:dialogConfig.employee.notes,rules:[{ max: 200, message: '最多填写200个字符' }]}]"/>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 5 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 16 } },
      }
    }
  },
  methods: {
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        this.$emit('submit',{...values,id:this.dialogConfig.employee.id});
      })
    },
    handleCancel(){
      this.dialogConfig.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.employeeeditdialog_block{
  width: 100%;
  height: auto;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
</style>