<template>
  <div class="login_main">
    <div class="login_board">
      <div class="login_title">
        <font>鲲开码资产管理平台</font>
        <font class="login_switch_text" v-if="currentLoginType=='byAccount'" @click="switchLoginType('byPhone')">手机快捷登录</font>
        <font class="login_switch_text" v-if="currentLoginType=='byPhone'" @click="switchLoginType('byAccount')">帐号密码登录</font>
      </div>
      <a-form :form="form" @submit="submitLogin">
        <div class="login_account_block" v-if="currentLoginType=='byAccount'">
            <a-input placeholder="请输入手机号码" v-model="userName"><a-icon slot="prefix" type="user"/></a-input>
            <a-input placeholder="请输入密码" type="password" v-model="password"><a-icon slot="prefix" type="barcode"/></a-input>
         
        </div>
        <div class="login_account_block" v-if="currentLoginType=='byPhone'">
            <a-input placeholder="请输入手机号码" v-model="phoneNumber"><a-icon slot="prefix" type="user"/></a-input>
            <div class="login_account_code_bar">
            <div class="login_account_code_bar_input">
                <a-input placeholder="请输入验证码" v-model="phoneCode"><a-icon slot="prefix" type="mail"/></a-input>
            </div>
            <a-button class="login_account_code_bar_img" :disabled="phoneCodeBtnText!='获取验证码'" @click="getPhoneCode">{{phoneCodeBtnText}}</a-button>
            </div>
        </div>
        <div class="login_error_text" v-if="errorContent">{{errorContent}}</div>
        <a-button class="login_btn" type="primary" html-type="submit" :loading="logging" block>登录</a-button>
      </a-form>
    </div>
  </div>
</template>

<script>
import { showNotification } from "../../utils/request";
import JSENcrypt from "jsencrypt";
export default {
  data(){
    /* global publicUrl */
    /* global basicUrl */
    return{
      form: this.$form.createForm(this),
      currentLoginType: 'byAccount',
      jcaptchaImgSrc: null,
      errorContent: "",
      phoneCodeBtnText: "获取验证码",
      userName: "",
      password: "",
      jcaptcha: "",
      phoneNumber: "",
      phoneCode: "",
      capchaTimes: 60,
      logging: false
    }
  },
  mounted(){
    let LoginTimeout = localStorage.getItem("LoginTimeout");
    if(LoginTimeout){
      showNotification('warning','登录超时','请重新登录',3);
      localStorage.removeItem("LoginTimeout");
    }
    this.getCaptcha();
  },
  methods: {
    //切换登录模式
    switchLoginType(type){
      if(!this.logging){
        this.currentLoginType = type;
        this.errorContent = "";
      }
    },
    //获取验证码图片
    getCaptcha(){
      
    },
    //获取短信验证码
    getPhoneCode(){
      if(this.phoneCodeBtnText=="获取验证码"&&this.runValidPhoneNumber()){
        this.phoneCodeBtnText = "获取中";
        let that = this;
        const formData = new FormData();
        formData.append("phoneNumber", this.phoneNumber);
        this.$axios.post(publicUrl+"/code/login",formData)
        .then(function(){
          that.timer = setInterval(()=>{
            that.phoneCodeBtnText = that.capchaTimes+"s";
            that.capchaTimes--;
            if(that.capchaTimes<0){
              clearInterval(that.timer);
              that.phoneCodeBtnText = "获取验证码";
            }
          },1000)
        })
        .catch(function(err){
          that.phoneCodeBtnText = "获取验证码";
          that.capchaTimes = 60;
          that.$message.warning(err.response.data.message,2);
        })
      }
    },
    //手机号码填写校验
    runValidPhoneNumber(){
      const mobile = /^[1][0-9][0-9]{9}$/;
      if(!this.phoneNumber){ this.errorContent="请填写手机号码";return false; }
      if(!mobile.test(this.phoneNumber)){ this.errorContent="请填写正确的手机号码";return false; }
      this.errorContent="";
      return true;
    },
    //整体填写校验
    runValid(){
      const mobile = /^[1][0-9][0-9]{9}$/;
      if(this.currentLoginType=="byAccount"){
        if(!this.userName){ this.errorContent="请填写手机号码";return false; }
        if(!mobile.test(this.userName)){ this.errorContent="请填写正确的手机号码";return false; }
        if(!this.password){ this.errorContent="请填写密码";return false; }

      }else if(this.currentLoginType=="byPhone"){
        if(!this.phoneNumber){ this.errorContent="请填写手机号码";return false; }
        if(!mobile.test(this.phoneNumber)){ this.errorContent="请填写正确的手机号码";return false; }
        if(!this.phoneCode){ this.errorContent="请填写验证码";return false; }
      }
      this.errorContent="";
      return true;
    },
    //提交登录
    submitLogin(e){
      e.preventDefault();
      if(!this.runValid()){ return; }
      let that = this;
      this.logging = true;
      if(this.currentLoginType=="byAccount"){
        const jse = new JSENcrypt();
        jse.setPublicKey("MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCYrEUvhKjzpqIuHd6Wc8hfnAMD0niLn1L3CcW6pUWmEx5SlZD4cAfwg9rU6g5roNm1LAvEhXu2hhxgV4wiW0WMvdooHg5x7NfnQT1o9wKluQCew6c_4Mc-QMi8mKjEYU4WY3p7StnBNn_y7MqA_hx2P8VxRpTTAeYLIY8lC1KxTQIDAQAB");
        const formData = new FormData();
        formData.append("username", this.userName);
        formData.append("password", jse.encrypt(this.password));
        formData.append("jcaptchaResponse", this.jcaptcha);
        formData.append("captchaId", sessionStorage.getItem("captchaid"));
        this.$axios.post(basicUrl+"/login",formData)
        .then(function(res){
          if(res.data.code===200){
            localStorage.setItem('userName',that.userName);
            localStorage.setItem('AccessAuthorization',res.headers.access_token);
            localStorage.setItem('RefreshAuthorization',res.headers.refresh_token);
            localStorage.setItem('role',res.headers.role);
            that.errorContent="";
            that.$router.push({path:'/backstage',query:{}});
          }
        })
        .catch(function(err){
          that.logging = false;
          that.errorContent = err.response.data.message;
          that.getCaptcha();
        })
      }else if(this.currentLoginType=="byPhone"){
        const formData = new FormData();
        formData.append("username", this.phoneNumber);
        formData.append("captcha", this.phoneCode);
        this.$axios.post(publicUrl+"/captchaLogin",formData)
        .then(function(res){
          if(res.data.code===200){
            localStorage.setItem('userName',that.phoneNumber);
            localStorage.setItem('AccessAuthorization',res.headers.access_token);
            localStorage.setItem('RefreshAuthorization',res.headers.refresh_token);
            localStorage.setItem('role',res.headers.role);
            that.errorContent="";
            that.$router.push({path:'/backstage',query:{}});
          }
        })
        .catch(function(err){
          that.logging = false;
          that.errorContent = err.response.data.message;
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login_main{
  width: 100%;
  height: 100%;
  min-height: 500px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b2a4e;
  background-image: linear-gradient(315deg,#1b2a4e 0%,#858ae2 100%);
}
.login_board{
  width: 400px;
  height: auto;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.1);
  padding: 40px 50px;
}
.login_title{
  width: 100%;
  height: 25px;
  line-height: 25px;
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
  border-left: 5px #1b2a4e solid;
  padding-left: 10px;
  float: left;
}
.login_switch_text{
  font-size: 15px;
  float: right;
  color: #1890ff;
  cursor: pointer;
}
.login_account_block{
  width: 100%;
  height: auto;
  float: left;
  margin-top: 30px;
}
.login_account_block span+span{
  margin-top: 20px;
}
.login_account_code_bar{
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  float: left;
}
.login_account_code_bar_input{
  width: calc(100% - 100px);
  height: auto;
  float: left;
}
.login_account_code_bar_img{
  width: 100px;
  height: 40px;
  float: left;
  cursor: pointer;
}
.login_btn{
  height: 40px;
  margin-top: 10px;
}
.login_error_text{
  width: 100%;
  height: 20px;
  font-size: 15px;
  color: #f25242;
  line-height: 20px;
  float: left;
}
/deep/ .ant-input{
  height: 40px;
}
/deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 35px;
}
</style>