<template>
  <div class="default_main">
    <a-spin :spinning="extentionData==null||assetsListTableData==null||pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="assetslist_header">
        <a-input-search class="default_searchinput" placeholder="请输入资产编码或资产名称" enter-button v-model="normalSearchDataShow" @search="onNormalSearch"/>
        <a-button-group class="assetslist_button_group">
          <a-button @click="callAssetsAdvanceSearchDrawer">高级搜索</a-button>
          <a-button v-if="searchType=='advanced'&&advancedSearchTags.length>0" icon="stop" @click="stopAssetsAdvanceSearch"/>
        </a-button-group>
        <a-dropdown>
          <a-button class="f_right">导出</a-button>
          <a-menu slot="overlay">
            <a-menu-item @click="exportAssetsList('selected')">导出选中列表</a-menu-item>
            <a-menu-item @click="exportAssetsLabel('selected')">导出选中标签</a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-dropdown>
          <a-button class="f_right" type="primary">操作</a-button>
          <a-menu slot="overlay">
            <a-menu-item @click="DistributionAssets">派发</a-menu-item>
            <a-menu-item @click="TransferAssets">转移</a-menu-item>
            <a-menu-item @click="DisposeAssets">处置</a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-dropdown>
          <a-button class="f_right" type="primary">入库</a-button>
          <a-menu slot="overlay">
            <a-menu-item @click="callAssetAddDialog">新增</a-menu-item>
            <a-menu-item @click="callBatchImportDialog">导入</a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div class="assetslist_tags" v-if="searchType=='advanced'&&advancedSearchTags.length>0">
        <a-tag closable v-for="item in advancedSearchTags" :key="item.field" @close="closeAdvancedSearchTag(item)"> {{item.name}}:{{item.showValue}} </a-tag>
      </div>
      <div :class="['assetslist_table',searchType=='advanced'&&advancedSearchTags.length>0? 'advanced':'']">
        <a-table 
          :columns="assetsListTableColumns" 
          :data-source="assetsListTableData" 
          :row-selection="{ selectedRowKeys: assetsListTableSelectedRowKeys,onChange: assetsListTableOnSelectChange }" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="assetsListTablePagination" 
          @change="handleAssetsListTableChange"
        >
          <template slot="code" slot-scope="text,record">
            <font class="table_action" @click="callAssetDetailDialog(record)">{{text}}</font>
          </template>
          <template slot="actions" slot-scope="text,record">
            <font class="table_action" @click="callAssetEditDialog(record)">修改</font>
            <font class="table_action" v-if="record.status=='leisure'" @click="deleteAsset(record)">删除</font>
            <font class="table_action disabled" v-if="record.status!='leisure'" title="仅支持删除空闲资产">删除</font>
            <font class="table_action" @click="callAssetResumerDialog(record)">履历</font> 
          </template>
          <template slot="disposeType" slot-scope="disposeType">
            {{disposeType==='allocation'? '调拨':disposeType==='inventory_loss'? '盘亏':disposeType==='donation'? '捐赠':disposeType==='scrap'? '报废':disposeType==='broken'? '报损':disposeType==='loss'? '报失':disposeType==='others'?'其他':''}}
          </template>
          <template slot="status" slot-scope="status">
            <a-tag :color="status==='leisure'? '#D46B08':status==='dispose_end'? '':status==='receive_end'? '#108EE9':'#87D068'">
              {{ status==='leisure'?'空闲':status==='receive_approval'?'领用审批中':status==='receive_confirming'?'待领用':
                status==='receive_sign_confirming'?'领用待签字':status==='receive_end'?'已领用':status==='loan'?'借出':status==='cancel_approval'?'退库审批中':
                status==='dispose'?'处置':status==='cancel_confirming'?'待退库':status==='cancel_sign_confirming'?'退库待签字':
                status==='transfer_confirming'?'待转移':status==='transfer_sign_confirming'?'转移待签字':
                status==='dispose_confirming'?'待处置':status==='dispose_sign_confirming'?'处置待签字':
                status==='dispose_end'?'已处置':status}}
            </a-tag>
          </template>
          <template slot="usageCase" slot-scope="usageCase">
            {{ usageCase==='normal'?'正常':usageCase==='fault'?'故障':'' }} 
          </template>
          <template slot="warehousedType" slot-scope="warehousedType">     
            {{ warehousedType==='purchase'?'采购':warehousedType==='allocation'?'调拨':warehousedType==='inventory_win'?'盘赢':warehousedType==='donation'?'捐赠':warehousedType==='others'?'其他':warehousedType}} 
          </template>
        </a-table>
      </div>
    </a-spin>
    <AssetDetailDialog :dialogConfig="AssetDetailDialogConfig" :extentionData="extentionData"/>
    <AssetResumerDialog :dialogConfig="AssetResumerDialogConfig"/>
    <AssetEditDialog :dialogConfig="AssetEditDialogConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :assetsStoreData="assetsStoreData" :extentionData="extentionData" @submit="putAssetDetail"/>
    <AssetAddDialog :dialogConfig="AssetAddDialogConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :assetsStoreData="assetsStoreData" :extentionData="extentionData" @submit="postAssetDetail"/>
    <BatchImportDialog :dialogConfig="BatchImportDialogConfig" @submit="batchImportAssets"/>
    <AssetsAdvancedSearchDrawer :drawerConfig="AssetsAdvanceSearchDrawerConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :employeeData="employeeData" @getEmployeeData="getEmployeeData" @submit="onAdvancedSearch"/>
    <DistributionAssetsDialog :dialogConfig="DistributionAssetsDialogConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :employeeData="employeeData" :assetsColumns="[...distributionAssetsColumns]" @getEmployeeData="getEmployeeData" @submit="handleDistributionSubmit"/>
    <TransferAssetsDialog :dialogConfig="TransferAssetsDialogConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :employeeData="employeeData" :assetsColumns="[...distributionAssetsColumns]" @getEmployeeData="getEmployeeData" @submit="handleTransferSubmit"/>
    <DisposeAssetsDialog :dialogConfig="DisposeAssetsDialogConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :employeeData="employeeData" :assetsColumns="[...distributionAssetsColumns]" @getEmployeeData="getEmployeeData" @submit="handleDisposeSubmit"/>
  </div>
</template>

<script>
import tableColumns from "../../utils/tableColumns";
import AssetDetailDialog from "../../components/AssetDetailDialog.vue";
import AssetResumerDialog from "../../components/AssetResumerDialog.vue";
import AssetEditDialog from "../../components/AssetEditDialog.vue";
import AssetAddDialog from "../../components/AssetAddDialog.vue";
import BatchImportDialog from "../../components/BatchImportDialog.vue";
import AssetsAdvancedSearchDrawer from "../../components/AssetsAdvancedSearchDrawer.vue";
import DistributionAssetsDialog from "../../components/DistributionAssetsDialog.vue";
import TransferAssetsDialog from "../../components/TransferAssetsDialog.vue";
import DisposeAssetsDialog from "../../components/DisposeAssetsDialog.vue";
export default {
  components: {
    AssetDetailDialog,
    AssetResumerDialog,
    AssetEditDialog,
    AssetAddDialog,
    BatchImportDialog,
    AssetsAdvancedSearchDrawer,
    DistributionAssetsDialog,
    TransferAssetsDialog,
    DisposeAssetsDialog
  },
  data(){
    return {
      pageLoading: false,
      assetsListTableSelectedRowKeys: [],
      assetsListTableSelectedData: [],
      assetsListTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      assetsListTableColumns: [...tableColumns.assetsListTableColumns],
      distributionAssetsColumns: [],
      assetsListTableData: null,
      extentionData: null,
      assetsTypeData: null,
      assetsLocationData: null,
      assetsStoreData: null,
      employeeData: null,
      searchType:"normal",
      normalSearchData: null,
      normalSearchDataShow: "",
      advancedSearchData:null,
      advancedSearchTags: [],
      AssetDetailDialogConfig: { visible:false,assetDetail:{} },
      AssetResumerDialogConfig: { visible:false,assetDetail:{} },
      AssetEditDialogConfig: { visible:false,requesting:false,assetDetail:{} },
      AssetAddDialogConfig: { visible:false,requesting:false,action:"assetsList" },
      BatchImportDialogConfig: { visible:false,requesting:false,title:"",templateUrl:"",templateName:"" },
      AssetsAdvanceSearchDrawerConfig: { visible:false,action:"assetsList" },
      DistributionAssetsDialogConfig: { visible:false,requesting:false,assets:[],action:"assetsList" },
      TransferAssetsDialogConfig: { visible:false,requesting:false,assets:[],action:"assetsList",transferUser:{} },
      DisposeAssetsDialogConfig: { visible:false,requesting:false,assets:[],action:"assetsList" }
    }
  },
  mounted(){
    this.getAssetsListTableData();
    this.getExtentionData(()=>{
      this.distributionAssetsColumns = [...this.assetsListTableColumns];
      this.assetsListTableColumns.push({title: '操作',key: 'actions',align: 'center',width: 130,fixed: 'right',scopedSlots: { customRender: 'actions' }});
    });
    this.getAssetsTypeTreeData();
    this.getAssetsLocationTreeData();
    this.getAssetsStoreData();
    this.getEmployeeData();
  },
  methods: {
    //表格选择触发事件
    assetsListTableOnSelectChange(selectedRowKeys,selectedRows){
      this.assetsListTableSelectedRowKeys = selectedRowKeys;
      this.assetsListTableSelectedData = selectedRows;
    },
    //表格翻页触发事件
    handleAssetsListTableChange(pagination){
      const pager = { ...this.assetsListTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.assetsListTablePagination = pager;
      this.getAssetsListTableData();
    },
    //普通搜索
    onNormalSearch(value){
      this.searchType = "normal";
      this.normalSearchData = value;
      this.advancedSearchData = {};
      this.advancedSearchTags = [];
      this.assetsListTablePagination.current = 1;
      this.getAssetsListTableData();
    },
    //高级搜索
    onAdvancedSearch(tags,data){
      this.AssetsAdvanceSearchDrawerConfig.visible = false;
      this.advancedSearchTags = tags;
      this.advancedSearchData = data;
      this.normalSearchData = "";
      this.normalSearchDataShow = "";
      if(tags.length==0){
        this.searchType = "normal";
      }else{
        this.searchType = "advanced";
      }
      this.assetsListTablePagination.current = 1;
      this.getAssetsListTableData();
    },
    //关闭高级搜索项
    closeAdvancedSearchTag(item){
      delete this.advancedSearchData[item.field];
      this.advancedSearchTags.splice(this.advancedSearchTags.findIndex(temp => temp.field === item.field), 1);
      if(this.advancedSearchTags.length==0){
        this.stopAssetsAdvanceSearch();
      }else{
        this.getAssetsListTableData();
      }
    },
    //取消高级搜索
    stopAssetsAdvanceSearch(){
      this.advancedSearchData = {};
      this.advancedSearchTags = [];
      this.searchType = "normal";
      this.normalSearchData = "";
      this.normalSearchDataShow = "";
      this.assetsListTablePagination.current = 1;
      this.getAssetsListTableData();
    },
    //加载扩展列信息
    getExtentionData(callback){
      let that = this;
      this.$get("/categoryExtendFields?pageNumber=1&pageSize=999999&searchData=",{})
      .then(function(res){
        that.extentionData = res.data.records;
        that.extentionData.forEach(item => {
          that.assetsListTableColumns.push({ title:item.labelName,dataIndex:item.fieldLabel,key:item.fieldLabel,align:'center',ellipsis: true,width: 120 });
        })
        callback();
      })
    },
    //加载表格数据
    getAssetsListTableData(){
      let that = this;
      this.pageLoading = true;
      let param = "pageNumber="+this.assetsListTablePagination.current+"&pageSize="+this.assetsListTablePagination.pageSize;
      if(this.searchType==="advanced"){
        this.$post("/advanceSearch/assets?"+param,this.advancedSearchData,{})
        .then(function(res){
          that.assetsListTableSelectedRowKeys = [];
          that.assetsListTableSelectedData = [];
          that.assetsListTableData = res.data.records;
          const pager = { ...that.assetsListTablePagination };
          pager.total = res.data.total;
          that.assetsListTablePagination = pager;
          that.pageLoading = false;
        })
      }else{
        if(this.normalSearchData!=null){ param=param+"&searchData="+this.normalSearchData; }
        this.$get("/assets?"+param,{})
        .then(function(res){
          that.assetsListTableSelectedRowKeys = [];
          that.assetsListTableSelectedData = [];
          that.assetsListTableData = res.data.records;
          const pager = { ...that.assetsListTablePagination };
          pager.total = res.data.total;
          that.assetsListTablePagination = pager;
          that.pageLoading = false;
        })
      }
    },
    //获取资产分类数据
    getAssetsTypeTreeData(){
      let that = this;
      this.$get("/categoryStructure",{})
      .then(function(res){
        that.assetsTypeData = res.data;
      })
    },
    //获取资产位置数据
    getAssetsLocationTreeData(){
      let that = this;
      this.$get("/locationStructure",{})
      .then(function(res){
        that.assetsLocationData = res.data;
      })
    },
    //获取资产库房数据
    getAssetsStoreData(){
      let that = this;
      this.$get("/locations/store",{})
      .then(function(res){
        that.assetsStoreData = res.data;
      })
    },
    //加载员工数据
    getEmployeeData(value){
      let that = this;
      let param = "pageNumber=1&pageSize=50&active=true";
      if(value!=null){ param=param+"&searchData="+value; }
      this.$get("/userEmployees?"+param,{})
      .then(function(res){ 
        that.employeeData = res.data.records;
      })
    },
    //高级搜索
    callAssetsAdvanceSearchDrawer(){
      this.AssetsAdvanceSearchDrawerConfig = { visible:true,action:"assetsList" };
    },
    //删除资产
    deleteAsset(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除【"+record.code+"】资产吗（保证资产无操作记录）?",
        onOk(){
          that.pageLoading = true;
          that.$delete("/asset/"+record.id,{})
          .then(function(){
            that.pageLoading=false;
            that.$message.success("删除成功");
            if(that.assetsListTableData.length==1&&that.assetsListTablePagination.current>1){ that.assetsListTablePagination.current--; }
            that.getAssetsListTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    //导出资产列表
    exportAssetsList(type){
      let param = [];
      if(type==='selected'){
        if(this.assetsListTableSelectedRowKeys.length===0){ this.$message.warning("请至少选择一项资产");return; }
        param = this.assetsListTableSelectedRowKeys;
      }
      let that = this;
      this.$message.loading({content:"正在导出，请耐心等待",key:"exportAssets",duration:0});
      this.$postFile("/asset/export/excel",param)
      .then(function(res){
        if(!res) return;
        that.$message.success({content:"导出完成",key:"exportAssets",duration:2});
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' }); // 构造一个blob对象来处理数据，并设置文件类型
        if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
          navigator.msSaveBlob(blob, "资产列表.xls")
        } else {
          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = "资产列表.xls" //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        }
      })
      .catch(function(){
        that.$message.error({content:"导出失败",key:"exportAssets",duration:2});
      })
    },
    //导出资产标签
    exportAssetsLabel(type){
      let param = [];
      if(type==='selected'){
        if(this.assetsListTableSelectedRowKeys.length===0){ this.$message.warning("请至少选择一项资产");return; }
        param = this.assetsListTableSelectedRowKeys;
      }
      let that = this;
      this.$message.loading({content:"正在导出，请耐心等待",key:"exportAssets",duration:0});
      this.$postFile("/asset/export/qrCodes/"+type,param)
      .then(function(res){
        if(!res) return;
        that.$message.success({content:"导出完成",key:"exportAssets",duration:2});
        const blob = new Blob([res.data], { type: 'application/zip' }); // 构造一个blob对象来处理数据，并设置文件类型
        if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
          navigator.msSaveBlob(blob, "资产标签.zip")
        } else {
          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = "资产标签.zip" //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        }
      })
      .catch(function(){
        that.$message.error({content:"导出失败",key:"exportAssets",duration:2});
      })
    },
    //查看资产详情
    callAssetDetailDialog(record){
      this.AssetDetailDialogConfig = { visible: true,assetDetail:{...record} };
    },
    //新增资产信息
    callAssetAddDialog(){
      this.AssetAddDialogConfig = { visible: true,requesting:false,action:"assetsList" };
    },
    //新增资产信息回调
    postAssetDetail(values){
      this.AssetAddDialogConfig.requesting = true;
      let that = this;
      this.$post("/asset",values)
      .then(function(){
        that.AssetAddDialogConfig.requesting = false;
        that.$message.success("保存成功");
        that.AssetAddDialogConfig.visible = false;
        that.getAssetsListTableData();
      })
      .catch(function(){
        that.AssetAddDialogConfig.requesting = false;
      })
    },
    //批量导入资产
    callBatchImportDialog(){
      this.BatchImportDialogConfig = { visible:true,requesting:false,title:"批量导入资产",templateUrl:"/asset/export/excelTemplate",templateName:"资产模板.xls" };
    },
    //批量导入资产回调
    batchImportAssets(values){
      this.BatchImportDialogConfig.requesting = true;
      let that = this;
      this.$post("/asset/import",values)
      .then(function(res){
        that.BatchImportDialogConfig.requesting = false;
        if(res.data.import_status==="error"){
          that.$message.warning({
            title: '错误提示',
            content: res.data.import_error_info,
            duration: 5
          });
        }else{
          that.BatchImportDialogConfig.visible = false;
          that.assetsListTablePagination.current = 1;
          that.$message.success("导入成功");
          that.getAssetsListTableData();
        } 
      })
      .catch(function(){
        that.BatchImportDialogConfig.requesting = false;
      })
    },
    //修改资产信息
    callAssetEditDialog(record){
      this.AssetEditDialogConfig = { visible: true,requesting:false,assetDetail:{...record} };
    },
    //修改资产信息回调
    putAssetDetail(values){
      this.AssetEditDialogConfig.requesting = true;
      let that = this;
      this.$put("/asset",values)
      .then(function(){
        that.AssetEditDialogConfig.requesting = false;
        that.$message.success("保存成功");
        that.AssetEditDialogConfig.visible = false;
        that.getAssetsListTableData();
      })
      .catch(function(){
        that.AssetEditDialogConfig.requesting = false;
      })
    },
    //查看履历
    callAssetResumerDialog(record){
      this.AssetResumerDialogConfig = { visible: true,assetDetail:{...record} };
    },
    //发起派发
    DistributionAssets(){
      if(this.assetsListTableSelectedData.some(element => element.status!=="leisure")){
        this.$message.warning('仅支持派发空闲资产');
      }else{
        this.DistributionAssetsDialogConfig = { visible:true,requesting:false,assets:[...this.assetsListTableSelectedData],action:"assetsList" };
      }
    },
    handleDistributionSubmit(values){
      this.DistributionAssetsDialogConfig.requesting = true;
      let that = this;
      this.$post("/orderDelivery",values)
      .then(function(){
        that.DistributionAssetsDialogConfig.visible = false;
        that.DistributionAssetsDialogConfig.requesting = false;
        that.$message.success("保存成功");
        that.getAssetsListTableData();
      })
      .catch(function(){
        that.DistributionAssetsDialogConfig.requesting = false;
      })
    },
    //发起转移
    TransferAssets(){
      if(this.assetsListTableSelectedData.some(element => element.status!="receive_end"||element.receivedUserId!=this.assetsListTableSelectedData[0].receivedUserId)){
        this.$message.warning('仅支持转移同一领用人的已领用资产');
      }else{
        if(this.assetsListTableSelectedData.length!=0){
          this.TransferAssetsDialogConfig = { visible:true,requesting:false,assets:[...this.assetsListTableSelectedData],action:"assetsList",transferUser:{id:this.assetsListTableSelectedData[0].receivedUserId,name:this.assetsListTableSelectedData[0].receivedUserName} };
        }else{
          this.TransferAssetsDialogConfig = { visible:true,requesting:false,assets:[...this.assetsListTableSelectedData],action:"assetsList",transferUser:{} };
        }
      }
    },
    handleTransferSubmit(values){
      this.TransferAssetsDialogConfig.requesting = true;
      let that = this;
      this.$post("/orderTransfer",values)
      .then(function(){
        that.TransferAssetsDialogConfig.visible = false;
        that.TransferAssetsDialogConfig.requesting = false;
        that.$message.success("保存成功");
        that.getAssetsListTableData();
      })
      .catch(function(){
        that.TransferAssetsDialogConfig.requesting = false;
      })
    },
    //发起处置
    DisposeAssets(){
      if(this.assetsListTableSelectedData.some(element => element.status!=="leisure"&&element.status!=="receive_end")){
        this.$message.warning('仅支持处置空闲或已领用资产');
      }else{
        this.DisposeAssetsDialogConfig = { visible:true,requesting:false,assets:[...this.assetsListTableSelectedData],action:"assetsList" };
      }
    },
    handleDisposeSubmit(values){
      this.DisposeAssetsDialogConfig.requesting = true;
      let that = this;
      this.$post("/orderDispose",values)
      .then(function(){
        that.DisposeAssetsDialogConfig.visible = false;
        that.DisposeAssetsDialogConfig.requesting = false;
        that.$message.success("保存成功");
        that.getAssetsListTableData();
      })
      .catch(function(){
        that.DisposeAssetsDialogConfig.requesting = false;
      })
    }
  }
}
</script>

<style lang="less" scoped>
.assetslist_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.assetslist_button_group{
  margin-left: 5px;
}
.assetslist_header button+button{
  margin-right: 5px;
}
.assetslist_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
.assetslist_table.advanced{
  height: calc(100% - 116px);
}
.assetslist_tags{
  width: 100%;
  height: 44px;
  float: left;
  overflow-x: scroll;
  padding: 0 0 5px 20px;
}
.assetslist_tags span+span{
  margin-left: 5px;
}
.assetslist_tags span{
  display: inline !important;
}
.assetslist_tags span:last-child{
  margin-right: 20px;
}
</style>