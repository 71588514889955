<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    :title="'【'+dialogConfig.distributionDetail.orderDeliveryCode+'】派发详情'" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="1050" 
    :closable="true"
  >
    <template slot="footer">
      <a-button @click="handleCancel">关闭</a-button>
    </template>
    <div class="distributiondetaildialog_block">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="领用人" :title="dialogConfig.distributionDetail.receivedUserName">{{dialogConfig.distributionDetail.receivedUserName}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="领用后位置" :title="dialogConfig.distributionDetail.locationName">{{dialogConfig.distributionDetail.locationName}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="创建日期" :title="$moment(dialogConfig.distributionDetail.dateEntered).format('YYYY-MM-DD')">{{$moment(dialogConfig.distributionDetail.dateEntered).format('YYYY-MM-DD')}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="状态">{{ dialogConfig.distributionDetail.status==='approval'?'审批中':dialogConfig.distributionDetail.status==='receive_confirming'?'进行中':dialogConfig.distributionDetail.status==='receive_end'?'已完成':dialogConfig.distributionDetail.status==='terminated'?'已终止':dialogConfig.distributionDetail.status==='rejected'?'已驳回':dialogConfig.distributionDetail.status}} </a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="审批人" :title="dialogConfig.distributionDetail.approvalUserName">{{dialogConfig.distributionDetail.approvalUserName}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="审批结果">{{dialogConfig.distributionDetail.approvalAction=="approval_agree"? "同意":dialogConfig.distributionDetail.approvalAction=="approval_reject"? "驳回":""}}</a-form-item></a-col>
          <a-col :span="24"><a-form-item style="margin-left:-8px" v-bind="formItemSingleLayout" label="审批意见" :title="dialogConfig.distributionDetail.approvalNotes">{{dialogConfig.distributionDetail.approvalNotes}}</a-form-item></a-col>
          <a-col :span="24"><a-form-item style="margin-left:-8px" v-bind="formItemSingleLayout" label="备注" :title="dialogConfig.distributionDetail.notes">{{dialogConfig.distributionDetail.notes}}</a-form-item></a-col>
        </a-row>
      </a-form>
      <a-divider dashed/>
      <div class="distributiondetaildialog_table">
        <a-table 
          :columns="assetsColumnsFit" 
          :data-source="dialogConfig.distributionDetail.assets" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="false" 
        >
          <template slot="disposeType" slot-scope="disposeType">
              {{disposeType==='allocation'? '调拨':disposeType==='inventory_loss'? '盘亏':disposeType==='donation'? '捐赠':disposeType==='scrap'? '报废':disposeType==='broken'? '报损':disposeType==='loss'? '报失':disposeType==='others'?'其他':''}}
          </template>
          <template slot="usageCase" slot-scope="usageCase">
              {{ usageCase==='normal'?'正常':usageCase==='fault'?'故障':'' }} 
          </template>
          <template slot="warehousedType" slot-scope="warehousedType">     
              {{ warehousedType==='purchase'?'采购':warehousedType==='allocation'?'调拨':warehousedType==='inventory_win'?'盘赢':warehousedType==='donation'?'捐赠':warehousedType==='others'?'其他':warehousedType}} 
          </template>
        </a-table>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object,
    assetsTypeData: Array,
    assetsLocationData: Array,
    employeeData: Array,
    assetsColumns: Array
  },
  computed: {
    assetsColumnsFit: function(){
      return [...this.assetsColumns].splice(1,this.assetsColumns.length-1);
    }
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 9 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 15 } },
      },
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 3 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 21 } },
      },
    }
  },
  methods: {
    handleCancel(){
      this.dialogConfig.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.distributiondetaildialog_block{
  width: 100%;
  height: auto;
  padding: 30px;
}
.distributiondetaildialog_table{
  width: 100%;
  height: 350px;
  position: relative;
}
/deep/ .ant-col{
  height: 40px;
  line-height: 40px;
}
/deep/ .ant-form-item-label{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .ant-form-item-control{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .ant-form-item:hover{
  background-color: rgba(27, 42, 78, 0.05);
}
/deep/ .ant-spin-nested-loading{
  height: 100%;
}
/deep/ .ant-spin-container{
  height: 100%;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
/deep/ .ant-divider-horizontal{
  margin: 8px 0;
}

/deep/ .ant-table-thead > tr > th{
  padding: 8px 8px;
}
/deep/ .ant-table-tbody > tr > td {
  padding: 6px 8px;
}
/deep/ .ant-table{
  height: 100%;
  font-size: 13px;
}
/deep/ .ant-table-content{
  height: 100%;
}
/deep/ .ant-table-scroll{
  height: 100%;
  overflow-y: hidden;
}
/deep/ .ant-table-body-outer{
  height: calc(100% - 37px);
}
/deep/ .ant-table-fixed-left{
  height: calc(100% - 15px);
}
/deep/ .ant-table-fixed-right{
  height: calc(100% - 15px);
}
/deep/ .ant-table td { 
  white-space: nowrap;
}
/deep/ .ant-table-fixed-left .ant-table-body-outer .ant-table-body-inner{
  overflow-x: hidden;
}
/deep/ .ant-table-fixed-right .ant-table-body-outer .ant-table-body-inner{
  overflow-x: hidden;
}
/deep/ .ant-table-body{
  height: calc(100% - 36px);
  max-height: calc(100% - 36px) ;
  min-height: calc(100% - 36px);
}
/deep/ .ant-table-wrapper{
  height: 100%;
}
/deep/ .ant-table-placeholder {
  position: absolute;
  top: 39px;
  width: 100%;
  height: calc(100% - 26px);
  border: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
</style>