<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="选择资产" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="1150" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">选择（{{assetsSelectData.length}}）</a-button>
    </template>
    <div class="assetsselect_block">
      <a-spin :spinning="dialogloading" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <div class="assetsselect_header">
          <a-input-search class="default_searchinput" placeholder="请输入资产编码或资产名称" enter-button v-model="normalSearchDataShow" @search="onNormalSearch"/>
          <a-button-group class="assetsselect_button_group">
            <a-button @click="callAssetsAdvanceSearchDrawer">高级搜索</a-button>
            <a-button v-if="searchType=='advanced'&&advancedSearchTags.length>0" icon="stop" @click="stopAssetsAdvanceSearch"/>
          </a-button-group>
        </div>
        <div class="assetsselect_tags" v-if="searchType=='advanced'&&advancedSearchTags.length>0">
          <a-tag closable v-for="item in advancedSearchTags" :key="item.field" @close="closeAdvancedSearchTag(item)"> {{item.name}}:{{item.showValue}} </a-tag>
        </div>
        <div class="assetsselect_table">
          <a-table 
            :columns="assetsColumns" 
            :data-source="assetsListTableData" 
            :row-selection="{ selectedRowKeys: assetsListTableSelectedRowKeys,onChange: assetsListTableOnSelectChange }" 
            :scroll="{ x: '100%',y: true}" 
            rowKey="id" 
            :pagination="assetsListTablePagination" 
            @change="handleAssetsListTableChange"
          >
            <template slot="disposeType" slot-scope="disposeType">
                {{disposeType==='allocation'? '调拨':disposeType==='inventory_loss'? '盘亏':disposeType==='donation'? '捐赠':disposeType==='scrap'? '报废':disposeType==='broken'? '报损':disposeType==='loss'? '报失':disposeType==='others'?'其他':''}}
            </template>
            <template slot="status" slot-scope="status">
              <a-tag :color="status==='leisure'? '#D46B08':status==='dispose_end'? '':status==='receive_end'? '#108EE9':'#87D068'">
                {{ status==='leisure'?'空闲':status==='receive_approval'?'领用审批中':status==='receive_confirming'?'待领用':
                    status==='receive_sign_confirming'?'领用待签字':status==='receive_end'?'已领用':status==='loan'?'借出':status==='cancel_approval'?'退库审批中':
                    status==='dispose'?'处置':status==='cancel_confirming'?'待退库':status==='cancel_sign_confirming'?'退库待签字':
                    status==='transfer_confirming'?'待转移':status==='transfer_sign_confirming'?'转移待签字':
                    status==='dispose_confirming'?'待处置':status==='dispose_sign_confirming'?'处置待签字':
                    status==='dispose_end'?'已处置':status}}
              </a-tag>
            </template>
            <template slot="usageCase" slot-scope="usageCase">
                {{ usageCase==='normal'?'正常':usageCase==='fault'?'故障':'' }} 
            </template>
            <template slot="warehousedType" slot-scope="warehousedType">     
                {{ warehousedType==='purchase'?'采购':warehousedType==='allocation'?'调拨':warehousedType==='inventory_win'?'盘赢':warehousedType==='donation'?'捐赠':warehousedType==='others'?'其他':warehousedType}} 
            </template>
          </a-table>
        </div>
      </a-spin>
    </div>
    <AssetsAdvancedSearchDrawer :drawerConfig="AssetsAdvanceSearchDrawerConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :employeeData="employeeData" @getEmployeeData="getEmployeeData" @submit="onAdvancedSearch"/>
  </a-modal>
</template>

<script>
import AssetsAdvancedSearchDrawer from "./AssetsAdvancedSearchDrawer.vue";
export default {
  props: {
    dialogConfig: Object,
    assetsColumns: Array,
    assetsTypeData: Array,
    assetsLocationData: Array,
    employeeData: Array
  },
  components: {
    AssetsAdvancedSearchDrawer
  },
  data(){
    return{
      dialogloading: false,
      searchType:"normal",
      normalSearchData: null,
      normalSearchDataShow: "",
      assetsListTableData: [],
      assetsSelectData: [],
      assetsListTableSelectedRowKeys: [],
      assetsListTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      advancedSearchData:null,
      advancedSearchTags: [],
      AssetsAdvanceSearchDrawerConfig: { visible:false,action:this.dialogConfig.action },
    }
  },
  watch: {
    'dialogConfig.visible': {
      handler(val){
        if(val){
          this.getAssetsListTableData();
        }
      },
    }
  },
  methods: {
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    getAssetsListTableData(){
      let that = this;
      this.dialogloading = true;
      let param = "pageNumber="+this.assetsListTablePagination.current+"&pageSize="+this.assetsListTablePagination.pageSize;
      if(this.searchType==="advanced"){
        if(this.dialogConfig.action=='distribution'){ this.advancedSearchData.status="leisure"; }
        if(this.dialogConfig.action=='transfer'){ this.advancedSearchData.status="receive_end";this.advancedSearchData.receivedUserId=this.dialogConfig.transferUserId }
        if(this.dialogConfig.action=='dispose'){ this.advancedSearchData.status="receive_end@leisure" }
        this.$post("/advanceSearch/assets?"+param,this.advancedSearchData,{})
        .then(function(res){
          that.assetsSelectData = [];
          that.assetsListTableSelectedRowKeys = [];
          that.assetsListTableData = res.data.records;
          const pager = { ...that.assetsListTablePagination };
          pager.total = res.data.total;
          that.assetsListTablePagination = pager;
          that.dialogloading = false;
        })
      }else{
        if(this.normalSearchData!=null){ param=param+"&searchData="+this.normalSearchData; }
        if(this.dialogConfig.action=='distribution'){ param=param+"&status=leisure"; }
        if(this.dialogConfig.action=='transfer'){ param=param+"&status=receive_end&receivedUserId="+this.dialogConfig.transferUserId; }
        if(this.dialogConfig.action=='dispose'){ param=param+"&status=receive_end@leisure"; }
        this.$get("/assets?"+param,{})
        .then(function(res){
          that.assetsSelectData = [];
          that.assetsListTableSelectedRowKeys = [];
          that.assetsListTableData = res.data.records;
          const pager = { ...that.assetsListTablePagination };
          pager.total = res.data.total;
          that.assetsListTablePagination = pager;
          that.dialogloading = false;
        })
      }
    },
    handleSubmit(){
      let valid = true;
      this.assetsSelectData.forEach(item => {
        if(this.dialogConfig.action=='distribution'&&item.status!=="leisure"){ this.$message.warning('仅支持派发空闲资产');valid=false;return; }
      });
      if(valid){ this.$emit('submit',this.assetsSelectData); }
    },
    onNormalSearch(value){
      this.searchType = "normal";
      this.advancedSearchData = {};
      this.normalSearchData = value;
      this.advancedSearchTags = [];
      this.assetsListTablePagination.current = 1;
      this.getAssetsListTableData();
    },
    //高级搜索
    onAdvancedSearch(tags,data){
      this.AssetsAdvanceSearchDrawerConfig.visible = false;
      this.advancedSearchTags = tags;
      this.advancedSearchData = data;
      this.normalSearchData = "";
      this.normalSearchDataShow = "";
      if(tags.length==0){
        this.searchType = "normal";
      }else{
        this.searchType = "advanced";
      }
      this.assetsListTablePagination.current = 1;
      this.getAssetsListTableData();
    },
    //关闭高级搜索项
    closeAdvancedSearchTag(item){
      delete this.advancedSearchData[item.field];
      this.advancedSearchTags.splice(this.advancedSearchTags.findIndex(temp => temp.field === item.field), 1);
      if(this.advancedSearchTags.length==0){
        this.stopAssetsAdvanceSearch();
      }else{
        this.getAssetsListTableData();
      }
    },
    //取消高级搜索
    stopAssetsAdvanceSearch(){
      this.advancedSearchData = {};
      this.advancedSearchTags = [];
      this.searchType = "normal";
      this.normalSearchData = "";
      this.normalSearchDataShow = "";
      this.assetsListTablePagination.current = 1;
      this.getAssetsListTableData();
    },
    handleAssetsListTableChange(pagination){
      const pager = { ...this.assetsListTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.assetsListTablePagination = pager;
      this.getAssetsListTableData();
    },
    assetsListTableOnSelectChange(selectedRowKeys,selectedRows){
      this.assetsListTableSelectedRowKeys = selectedRowKeys;
      this.assetsSelectData = selectedRows;
    },
    getEmployeeData(value){
      this.$emit("getEmployeeData",value);
    },
    //高级搜索
    callAssetsAdvanceSearchDrawer(){
      this.AssetsAdvanceSearchDrawerConfig = { visible:true,action:this.dialogConfig.action };
    },
  }
}
</script>

<style lang="less" scoped>
.assetsselect_block{
  width: 100%;
  height: auto;
  padding: 20px 20px 0 20px;
}
.assetsselect_header{
  width: 100%;
  padding-bottom: 20px;
}
.assetsselect_button_group{
  margin-left: 5px;
}
.assetsselect_table{
  width: 100%;
  height: 400px;
}
.assetsselect_tags{
  width: 100%;
  height: 44px;
  overflow-x: scroll;
  padding: 0 0 5px 20px;
}
.assetsselect_tags span+span{
  margin-left: 5px;
}
.assetsselect_tags span{
  display: inline !important;
}
.assetsselect_tags span:last-child{
  margin-right: 20px;
}
/deep/ .ant-table{
  height: calc(100% - 64px) !important;
  font-size: 13px;
}
</style>