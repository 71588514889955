import moment from "moment";
const assetsListTableColumns = [
    {title: '资产状态',dataIndex: 'status',key: 'status',align: 'center',width: 80,fixed: 'left',scopedSlots: { customRender: 'status' },ellipsis: true},
    {title: '资产编码',dataIndex: 'code',key: 'code',align: 'center',width: 150,fixed: 'left',scopedSlots: { customRender: 'code' },ellipsis: true},
    {title: '资产名称',dataIndex: 'name',key: 'name',align: 'center',ellipsis: true,width: 200,fixed: 'left'},
    {title: '资产分类',dataIndex: 'categoryName',key: 'categoryName',align: 'center',ellipsis: true,width: 120},
    {title: '资产位置',dataIndex: 'locationName',key: 'locationName',align: 'center',ellipsis: true,width: 120},
    {title: '入库类型',dataIndex: 'warehousedType',key: 'warehousedType',align: 'center',ellipsis: true,width: 120,scopedSlots: { customRender: 'warehousedType' }},
    {title: '入库日期',dataIndex: 'dateWarehoused',key: 'dateWarehoused',align: 'center',width: 200,ellipsis: true,customRender: (val) => {return val ? moment(val).format('YYYY-MM-DD') : ''}},
    {title: '所属库房',dataIndex: 'storeLocationName',key: 'storeLocationName',align: 'center',ellipsis: true,width: 120},
    {title: '使用状况',dataIndex: 'usageCase',key: 'usageCase',align: 'center',width: 120,ellipsis: true,scopedSlots: { customRender: 'usageCase' }},
    {title: '品牌',dataIndex: 'brand',key: 'brand',align: 'center',ellipsis: true,width: 120},
    {title: '型号',dataIndex: 'modelNumber',key: 'modelNumber',align: 'center',ellipsis: true,width: 120},
    {title: '金额',dataIndex: 'amount',key: 'amount',align: 'center',ellipsis: true,width: 100},
    {title: '使用期限（月）',dataIndex: 'dateEstimated',key: 'dateEstimated',align: 'center',width: 120,ellipsis: true},
    {title: '计量单位',dataIndex: 'measureUnit',key: 'measureUnit',align: 'center',width: 120,ellipsis: true},
    {title: '领用人',dataIndex: 'receivedUserName',key: 'receivedUserName',align: 'center',ellipsis: true,width: 120},
    {title: '领用日期',dataIndex: 'dateReceived',key: 'dateReceived',align: 'center',width: 200,ellipsis: true,customRender: (val) => {return val ? moment(val).format('YYYY-MM-DD') : ''}},
    {title: '处置类型',dataIndex: 'disposeType',key: 'disposeType',align: 'center',ellipsis: true,width: 80,scopedSlots: { customRender: 'disposeType' }},
    {title: '处置日期',dataIndex: 'dateDisposed',key: 'dateDisposed',align: 'center',width: 200,ellipsis: true,customRender: (val) => {return val ? moment(val).format('YYYY-MM-DD') : ''}},
    {title: '备注',dataIndex: 'notes',key: 'notes',align: 'center',ellipsis: true,width: 250},
    {title: '供应商',dataIndex: 'maintenanceProvider',key: 'maintenanceProvider',align: 'center',ellipsis: true,width: 120},
    {title: '联系人',dataIndex: 'maintenanceContacts',key: 'maintenanceContacts',align: 'center',ellipsis: true,width: 120},
    {title: '联系方式',dataIndex: 'maintenanceContactInformation',key: 'maintenanceContactInformation',align: 'center',ellipsis: true,width: 120},
    {title: '维保到期日期',dataIndex: 'maintenanceDateExpired',key: 'maintenanceDateExpired',align: 'center',width: 120,ellipsis: true,customRender: (val) => {return val ? moment(val).format('YYYY-MM-DD') : ''}} 
]

export default { assetsListTableColumns }