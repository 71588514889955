<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="盘点进展" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="850"
  >
    <template slot="footer">
      <a-button @click="handleCancel">关闭</a-button>
    </template>
    <div class="inventoryprogressdialog_block">
      <a-spin :spinning="dialogLoading" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <div class="inventoryprogressdialog_table">
          <a-table 
            :columns="inventoryProcessColumns" 
            :data-source="inventoryProcessTableData" 
            :scroll="{ x: '100%',y: true}" 
            rowKey="assignedCheckUserId" 
            :pagination="false" 
          >
            <template slot="checkProgress" slot-scope="text, record">
                {{Math.round((record.assetTotal-record.assetNotCheck)/record.assetTotal*100)}}%
            </template>
          </a-table>
        </div>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object
  },
  watch: {
    'dialogConfig.visible': {
      handler(val){ if(val){ this.getInventoryProcessTableData(); } },
    }
  },
  data(){
    return{
      dialogLoading: true,
      inventoryProcessColumns: [
        {title: '盘点人',dataIndex: 'assignedCheckUserName',key: 'assignedCheckUserName',align: 'center',ellipsis: true,width: 120},
        {title: '盘点进度',dataIndex: 'checkProgress',key: 'checkProgress',align: 'center',ellipsis: true,width: 100,scopedSlots: { customRender: 'checkProgress' }},
        {title: '总数量',dataIndex: 'assetTotal',key: 'assetTotal',align: 'center',ellipsis: true,width: 100},
        {title: '未盘数量',dataIndex: 'assetNotCheck',key: 'assetNotCheck',align: 'center',ellipsis: true,width: 100},
        {title: '已盘数量',dataIndex: 'assetChecked',key: 'assetChecked',align: 'center',ellipsis: true,width: 100},
        {title: '盘盈数量',dataIndex: 'assetCheckWin',key: 'assetCheckWin',align: 'center',ellipsis: true,width: 100},
        {title: '盘亏数量',dataIndex: 'assetCheckLoss',key: 'assetCheckLoss',align: 'center',ellipsis: true,width: 100}
      ],
      inventoryProcessTableData: []
    }
  },
  methods: {
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    getInventoryProcessTableData(){
      this.dialogLoading = true;
      let that = this;
      this.$get("/orderCheck/statistic/person/"+this.dialogConfig.inventoryId+"?pageNumber=1&pageSize=999999",{})
      .then(function(res){
        that.dialogLoading = false;
        that.inventoryProcessTableData = res.data.records;
      })
    },
  }
}
</script>

<style lang="less" scoped>
.inventoryprogressdialog_block{
  width: 100%;
  height: auto;
  padding: 20px;
}
.inventoryprogressdialog_table{
  width: 100%;
  height: 400px;
}
/deep/ .ant-spin-nested-loading{
  height: 100%;
}
/deep/ .ant-spin-container{
  height: 100%;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
/deep/ .ant-table-thead > tr > th{
  padding: 8px 8px;
}
/deep/ .ant-table-tbody > tr > td {
  padding: 6px 8px;
}
/deep/ .ant-table{
  height: 100%;
  font-size: 13px;
}
/deep/ .ant-table-content{
  height: 100%;
}
/deep/ .ant-table-scroll{
  height: 100%;
  overflow-y: hidden;
}
/deep/ .ant-table-body-outer{
  height: calc(100% - 37px);
}
/deep/ .ant-table-fixed-left{
  height: calc(100% - 15px);
}
/deep/ .ant-table-fixed-right{
  height: calc(100% - 15px);
}
/deep/ .ant-table td { 
  white-space: nowrap;
}
/deep/ .ant-table-fixed-left .ant-table-body-outer .ant-table-body-inner{
  overflow-x: hidden;
}
/deep/ .ant-table-fixed-right .ant-table-body-outer .ant-table-body-inner{
  overflow-x: hidden;
}
/deep/ .ant-table-body{
  height: calc(100% - 36px);
  max-height: calc(100% - 36px) ;
  min-height: calc(100% - 36px);
}
/deep/ .ant-table-wrapper{
  height: 100%;
}
/deep/ .ant-table-placeholder {
  position: absolute;
  top: 39px;
  width: 100%;
  height: calc(100% - 26px);
  border: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
</style>