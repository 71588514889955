<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    :title="dialogConfig.title" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="550" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">开始导入</a-button>
    </template>
    <div class="batchimportdialog_block">
      <a-spin :spinning="dialogConfig.requesting" size="large" tip="导入中，请耐心等待...">
        <a-icon slot="indicator" type="loading"/>
        <div class="batchimportdialog_title_levelone">第一步：下载模板</div>
        <div class="batchimportdialog_alert">提示：模板会随分类、位置、员工等信息动态变化，建议您在每次导入前重新获取最新模板，特别是在分类、位置、员工等信息变化后，请务必重新获取模板。</div>
        <a-button class="batchimportdialog_btn" type="primary" @click="downloadTemplate">获取模板</a-button>
        <a-divider dashed/>
        <div class="batchimportdialog_title_levelone">第二步：填写模板</div>
        <div class="batchimportdialog_title_leveltwo">基于获取的模板，按照模板内容及说明完成模板填写。</div>
        <a-divider dashed/>
        <div class="batchimportdialog_title_levelone">第三步：上传已填写的模板文件</div>
        <div class="batchimportdialog_title_leveltwo">选择填写好的模板文件进行上传。</div>
        <a-upload
          name="file"
          list-type="text"
          class="upload-div"
          :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload"
        >
          <a-button class="batchimportdialog_btn" type="primary"> <a-icon type="upload" /> 上传 </a-button>
        </a-upload>
        <a-divider dashed/>
        <div class="batchimportdialog_title_levelone">第四步：开始导入</div>
        <div class="batchimportdialog_alert">提示：导入过程耗时受导入数据量大小的影响，请耐心等待；若导入失败，请按照提示说明修改填写的内容后重新进行第三步。</div>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object
  },
  data(){
    return{
      fileList: [],
    }
  },
  methods: {
    handleSubmit(){
      if(this.fileList.length!=0){
        const formData = new FormData();    
        formData.append('file', this.fileList[0]);
        this.$emit('submit',formData);
      }else{
        this.$message.warning("请上传填写好的模板文件");
      }
    },
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    downloadTemplate(){
      let that=this;
      this.$postFile(this.dialogConfig.templateUrl,{})
      .then(function(res){
        if(!res) return
          const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' }) // 构造一个blob对象来处理数据，并设置文件类型
        if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
          navigator.msSaveBlob(blob, that.dialogConfig.templateName)
        } else {
          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = that.dialogConfig.templateName //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        }
      })
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [file];
      return false;
    },
  }
}
</script>

<style lang="less" scoped>
.batchimportdialog_block{
  width: 100%;
  height: auto;
  padding: 30px;
}
.batchimportdialog_title_levelone{
  width: 100%;
  height: auto;
  line-height: 30px;
  font-size: 16px;
  font-weight: bolder;
  color: #333;
}
.batchimportdialog_alert{
  width: 100%;
  height: auto;
  line-height: 20px;
  font-size: 14px;
  color: #E22323;
}
.batchimportdialog_btn{
  margin-top: 8px;
}
.batchimportdialog_title_leveltwo{
  width: 100%;
  height: auto;
  line-height: 20px;
  font-size: 14px;
  color: #333;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
/deep/ .ant-divider-horizontal{
  margin: 10px 0;
}
</style>