<template>
  <div class="default_main">
    <a-spin :spinning="pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="signsetting_table">
        <a-table 
          :columns="signSettingTableColumns" 
          :data-source="signSettingTableData" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="signSettingTablePagination" 
          @change="handleSignSettingTableChange"
        >
          <template slot="actions" slot-scope="text,record">
            <font class="table_action" v-if="record.active=='0'" @click="signSettleChange(record)">开启</font>
            <font class="table_action disabled" v-if="record.active=='1'">开启</font>
            <font class="table_action" v-if="record.active=='1'" @click="signSettleChange(record)">关闭</font>
            <font class="table_action disabled" v-if="record.active=='0'">关闭</font>
          </template>
          <template slot="action" slot-scope="action">
            {{action=='receive_confirming'?'领用签字':action=='back_confirming'?'退库签字':action=='dispose_confirming'?'处置签字':action=='transfer_confirming'?'转移签字':action=='check_confirming'?'盘点签字':''}}
          </template>
          <template slot="explain" slot-scope="text,record">
            {{record.action=='receive_confirming'?'开启时，员工在领用资产时需要进行签字确认；关闭时，员工在领用资产时不需要签字环节':
                record.action=='back_confirming'?'开启时，库房管理员在接收资产退库时需要进行签字确认；关闭时，库房管理员在接收资产退库时不需要签字环节':
                record.action=='dispose_confirming'?'开启时，库房管理员在接收资产处置时需要进行签字确认；关闭时，库房管理员在接收资产处置时不需要签字环节':
                record.action=='transfer_confirming'?'开启时，接收人在接收资产转移时需要进行签字确认；关闭时，接收人在接收资产转移时不需要签字环节':
                record.action=='check_confirming'?'开启时，员工在盘点资产提交时需要进行签字确认；关闭时，员工在盘点资产提交时不需要签字环节':''}}
          </template>
          <template slot="status" slot-scope="text,record">
            <a-tag :color="record.active=='1'? '#87D068':''">
              {{ record.active=='1'? "开启":"关闭" }}
            </a-tag>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  data(){
    return {
      pageLoading: false,
      signSettingTableData: [],
      signSettingTableColumns: [
        {title: '签字环节',dataIndex: 'action',key: 'action',align: 'center',width: 80,ellipsis: true,scopedSlots: { customRender: 'action' }},
        {title: '是否开启签字',dataIndex: 'active',key: 'active',align: 'center',width: 80,ellipsis: true,scopedSlots: { customRender: 'status' }},
        {title: '说明',dataIndex: 'parentName',key: 'parentName',align: 'center',width: 500,ellipsis: true,scopedSlots: { customRender: 'explain' }},
        {title: '操作',key: 'actions',align: 'center',width: 80,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      signSettingTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`}
    }
  },
  mounted(){
    this.getSignSettingData();
  },
  methods: {
    handleSignSettingTableChange(pagination){
      const pager = { ...this.signSettingTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.signSettingTablePagination = pager;
      this.getSignSettingData();
    },
    getSignSettingData(){
      this.pageLoading = true;
      let that = this;
      this.$get("/settingSigns",{})
      .then(function(res){
        that.signSettingTableData = res.data;
        that.pageLoading = false;
      })
    },
    signSettleChange(item){
      this.pageLoading = true;
      let that = this;
      let active = item.active=='1'? '0':'1';
      this.$put("/settingSign/"+item.id+"/"+active,{})
      .then(function(){
        that.getSignSettingData();
      })
    }
  }
}
</script>

<style lang="less" scoped>
.signsetting_table{
  width: 100%;
  height: 100%;
  float: left;
}
</style>