<template>
  <a-drawer
    placement="right"
    width="450"
    :visible="drawerConfig.visible" 
    :destroyOnClose="true" 
    :maskClosable="false" 
    @close="handleCancel"
  >
    <div class="assetsadvancesearch_main">
      <a-spin :spinning="assetsTypeData==null||assetsLocationData==null||employeeData==null" size="large" tip="装载数据中...">
        <a-icon slot="indicator" type="loading"/>
        <div class="assetsadvancesearch_main">
          <div class="assetsadvancesearch_header">
            <a-button-group>
              <a-button type="primary" @click="handleSubmit">开始搜索</a-button>
              <a-button icon="redo" @click="reset"/>
            </a-button-group>
          </div>
          <div class="assetsadvancesearch_content">
            <a-form :form="form">
              <a-row :gutter="24">
                <a-col :span="24"><a-form-item v-bind="formItemLayout" label="资产编码"><a-input v-decorator="['code']" placeholder="请输入资产编码"/></a-form-item></a-col>
                <a-col :span="24"><a-form-item v-bind="formItemLayout" label="资产名称"><a-input   v-decorator="['name']" placeholder="请输入资产名称"/></a-form-item></a-col>
                <a-col :span="24" v-if="drawerConfig.action=='inventoryDetail'">
                  <a-form-item v-bind="formItemLayout" label="盘点状态">
                    <a-select  labelInValue  v-decorator="['checkStatus']" placeholder="请选择盘点状态">
                      <a-select-option value="undone"> 未盘</a-select-option>
                      <a-select-option value="finished"> 已盘</a-select-option>
                      <a-select-option value="surplus"> 盘盈</a-select-option>
                      <a-select-option value="loss"> 盘亏</a-select-option>
                    </a-select> 
                  </a-form-item>
                </a-col>
                <a-col :span="24" v-if="drawerConfig.action=='inventoryDetail'">
                  <a-form-item v-bind="formItemLayout" label="盘点人">
                    <a-select
                      labelInValue
                      v-decorator="['checkUserId']"
                      show-search
                      :filterOption="false"
                      @search="handleEmployeeSearch"
                      placeholder="请输入姓名搜索"
                      allow-clear
                    >
                      <a-select-option v-for="item in employeeData" :value="item.id" :key="item.id">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item v-bind="formItemLayout" label="资产分类">
                    <a-tree-select 
                      labelInValue
                      :tree-data="assetsTypeData"  
                      placeholder="请选择资产分类"
                      searchPlaceholder="请输入名称搜索"
                      v-decorator="['categoryId']"
                      show-search
                      allow-clear
                      treeNodeFilterProp='title'
                      :dropdown-style="{ maxHeight: '300px',maxWidth:'350px',overflow: 'auto' }"
                      tree-default-expand-all 
                    >
                    </a-tree-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24">
                  <a-form-item v-bind="formItemLayout" label="资产位置">
                    <a-tree-select  
                      labelInValue 
                      :tree-data="assetsLocationData"  
                      placeholder="请选择资产位置"
                      searchPlaceholder="请输入名称搜索"
                      show-search
                      allow-clear
                      treeNodeFilterProp='title'
                      tree-default-expand-all 
                      v-decorator="['locationId']"
                      :dropdown-style="{ maxHeight: '300px',maxWidth:'350px', overflow: 'auto' }"
                    >
                    </a-tree-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24" v-if="drawerConfig.action!='transfer'">
                  <a-form-item v-bind="formItemLayout" label="领用人">
                    <a-select
                      labelInValue
                      v-decorator="['receivedUserId']"
                      show-search
                      :filterOption="false"
                      @search="handleEmployeeSearch"
                      placeholder="请输入姓名搜索"
                      allow-clear
                    >
                      <a-select-option v-for="item in employeeData" :value="item.id" :key="item.id">{{ item.name }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24" v-if="drawerConfig.action!='distribution'&&drawerConfig.action!='transfer'&&drawerConfig.action!='dispose'">
                  <a-form-item v-bind="formItemLayout" label="资产状态">
                    <a-select  labelInValue  v-decorator="['status']" placeholder="请选择资产状态">
                      <a-select-option value="leisure"> 空闲</a-select-option>
                      <a-select-option value="receive_confirming"> 待领用</a-select-option>
                      <a-select-option value="receive_end"> 已领用</a-select-option>
                      <a-select-option value="cancel_confirming"> 待退库</a-select-option>
                      <a-select-option value="transfer_confirming"> 待转移</a-select-option>
                      <a-select-option value="dispose_confirming"> 待处置</a-select-option>
                      <a-select-option value="dispose_end"> 已处置</a-select-option>
                    </a-select> 
                  </a-form-item>
                </a-col>
                <a-col :span="24" v-if="drawerConfig.action!='inventoryDetail'">
                  <a-form-item v-bind="formItemLayout" label="使用状况">
                    <a-select labelInValue v-decorator="['usageCase']" placeholder="请选择使用状态">
                      <a-select-option value="normal"> 正常</a-select-option>
                      <a-select-option value="fault"> 故障</a-select-option>
                    </a-select> 
                  </a-form-item>
                </a-col>
                <a-col :span="24" v-if="drawerConfig.action!='inventoryDetail'">
                  <a-form-item v-bind="formItemLayout" label="入库类型">
                    <a-select labelInValue  v-decorator="['warehousedType']" placeholder="请选择入库类型">
                      <a-select-option value="purchase"> 采购</a-select-option>
                      <a-select-option value="allocation"> 调拨</a-select-option>
                      <a-select-option value="inventory_win"> 盘盈</a-select-option>
                      <a-select-option value="donation"> 捐赠</a-select-option>
                      <a-select-option value="others"> 其他</a-select-option>
                    </a-select> 
                  </a-form-item>
                </a-col>
                <a-col :span="24" v-if="drawerConfig.action!='inventoryDetail'"><a-form-item v-bind="formItemLayout" label="入库日期"><a-date-picker v-decorator="['dateWarehoused']" placeholder="请输入入库日期"/></a-form-item></a-col>
                <a-col :span="24" v-if="drawerConfig.action!='inventoryDetail'"><a-form-item v-bind="formItemLayout" label="领用日期"><a-date-picker v-decorator="['dateReceived']" placeholder="请输入领用日期"/></a-form-item></a-col>
              </a-row>
            </a-form>
          </div>
        </div>
      </a-spin>
    </div>
  </a-drawer>
</template>

<script>
export default {
  props: {
    drawerConfig: Object,
    assetsTypeData: Array,
    assetsLocationData: Array,
    employeeData: Array
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 6 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 16 } },
      }
    }
  },
  methods: {
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        let advancedSearchTags = [];
        let advancedSearchData = {};
        Object.keys(values).forEach(key=>{
          if(values[key]!==""&&values[key]!== undefined&&values[key]!== null){
            switch(key){
              case "code":
                advancedSearchTags.push({ name:"资产编码",field:"code",showValue:values[key],value:values[key] });
                advancedSearchData.code = values[key];
                break;
              case "name":
                advancedSearchTags.push({ name:"资产名称",field:"name",showValue:values[key],value:values[key] });
                advancedSearchData.name = values[key];
                break;
              case "status":
                advancedSearchTags.push({ name:"资产状态",field:"status",showValue:values[key].label,value:values[key].key });
                advancedSearchData.status = values[key].key;
                break;
              case "categoryId":
                advancedSearchTags.push({ name:"资产分类",field:"categoryId",showValue:values[key].label,value:values[key].value });
                advancedSearchData.categoryId = values[key].value;
                break;
              case "warehousedType":
                advancedSearchTags.push({ name:"入库类型",field:"warehousedType",showValue:values[key].label,value:values[key].key });
                advancedSearchData.warehousedType = values[key].key;
                break;
              case "dateWarehoused":
                advancedSearchTags.push({ name:"入库日期",field:"dateWarehoused",showValue:this.$moment(values[key]).format('YYYY-MM-DD'),value:this.$moment(values[key]).format('YYYY-MM-DD') });
                advancedSearchData.dateWarehoused = this.$moment(values[key]).format('YYYY-MM-DD');
                break;
              case "usageCase":
                advancedSearchTags.push({ name:"使用状况",field:"usageCase",showValue:values[key].label,value:values[key].key });
                advancedSearchData.usageCase = values[key].key;
                break;
              case "locationId":
                advancedSearchTags.push({ name:"资产位置",field:"locationId",showValue:values[key].label,value:values[key].value });
                advancedSearchData.locationId = values[key].value;
                break;
              case "receivedUserId":
                advancedSearchTags.push({ name:"领用人",field:"receivedUserId",showValue:values[key].label,value:values[key].key });
                advancedSearchData.receivedUserId = values[key].key;
                break;
              case "dateReceived":
                advancedSearchTags.push({ name:"领用日期",field:"dateReceived",showValue:this.$moment(values[key]).format('YYYY-MM-DD'),value:this.$moment(values[key]).format('YYYY-MM-DD') });
                advancedSearchData.dateReceived = this.$moment(values[key]).format('YYYY-MM-DD');
                break;
              case "checkStatus":
                advancedSearchTags.push({ name:"盘点状态",field:"checkStatus",showValue:values[key].label,value:values[key].key });
                advancedSearchData.checkStatus = values[key].key;
                break;
              case "checkUserId":
                advancedSearchTags.push({ name:"盘点人",field:"checkUserId",showValue:values[key].label,value:values[key].key });
                advancedSearchData.checkUserId = values[key].key;
                break;
            }
          }
        });
        this.$emit('submit',advancedSearchTags,advancedSearchData);
      })
    },
    handleCancel(){
      this.drawerConfig.visible = false;
    },
    reset(){
      this.form.resetFields();
    },
    handleEmployeeSearch(value){
      this.$emit("getEmployeeData",value);
    }
  }
}
</script>

<style lang="less" scoped>
.assetsadvancesearch_main{
  width: 100%;
  height: 100%;
}
.assetsadvancesearch_header{
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-left: 25px;
}
.assetsadvancesearch_content{
  width: 100%;
  height: calc(100% - 57px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 30px 0;
}
/deep/ .ant-drawer-body{
  width: 100%;
  height: 100%;
  padding: 0;
}
/deep/ .ant-spin-nested-loading{
  height: 100%;
}
/deep/ .ant-spin-container{
  height: 100%;
}
/deep/ .ant-calendar-picker{
  width: 100%;
}
</style>