<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="添加位置" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="450" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">保存</a-button>
    </template>
    <div class="locationadddialog_block">
      <a-spin :spinning="locationTreeDataWithRoot==null||employeeData==null||dialogConfig.requesting" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <a-form :form="form" style="padding: 30px 0px 5px 30px">
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="位置编号">
                <a-input placeholder="请输入位置编号" v-decorator="['code',{rules:[{required:true,message:'请输入位置编号'},{ pattern: /^[A-Za-z0-9]+$/, message: '应由数字和字母组成' },{ max: 20, message: '最多填写20个字符' }]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="位置名称">
                <a-input placeholder="请输入位置名称" v-decorator="['name',{rules:[{required:true,message:'请输入位置名称'},{ max: 20, message: '最多填写20个字符' }]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="上级位置">
                <a-tree-select 
                  style="width: 100%"
                  :tree-data="locationTreeDataWithRoot" 
                  show-search 
                  treeNodeFilterProp="title" 
                  placeholder="请选择上级位置"
                  tree-default-expand-all 
                  v-decorator="['parentId',{ initialValue:dialogConfig.parentId? dialogConfig.parentId:undefined,rules: [{ required: true, message: '请选择上级分类' }] }]" 
                  :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="是否为库房">
                <a-select @select="handleIsStoreChange" v-decorator="['isStore',{ initialValue: 'false',rules: [{ required: true, message: '请选择是否为库房' }] }]">
                  <a-select-option value="true">是</a-select-option>
                  <a-select-option value="false">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24" v-if="isStore==='true'">
              <a-form-item v-bind="formItemSingleLayout" label="库房管理员">
                <a-select
                  labelInValue
                  v-decorator="['storeUserId',{rules: [{ required: true, message: '请选择库房管理员' }]}]"
                  show-search
                  :filterOption="false"
                  @search="getEmployeeData"
                  placeholder="请输入姓名搜索"
                  allow-clear
                >
                  <a-select-option v-for="item in employeeData" :value="item.id" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object,
    locationTreeDataWithRoot: Array,
    employeeData: Array
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 6 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 16 } },
      },
      isStore: "false"
    }
  },
  methods: {
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        let fix = {...values};
        fix.isStore = values.isStore ==="false" ? false : true;
        if(values.storeUserId){ fix.storeUserId = values.storeUserId.key; }
        this.$emit('submit',fix);
      })
    },
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    handleIsStoreChange(isStore){
      this.isStore = isStore;
    },
    getEmployeeData(value){
      this.$emit("getEmployeeData",value);
    }
  }
}
</script>

<style lang="less" scoped>
.locationadddialog_block{
  width: 100%;
  height: auto;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
</style>