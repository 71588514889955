<template>
  <div class="error_block">
    <a-result status="404" title="404" sub-title="您访问的页面未找到">
      <template #extra>
        <a-button type="primary" @click="goIndex">返回首页</a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
  methods: {
    goIndex(){
      this.$router.push({ path: '/' });
    }
  }
}
</script>

<style lang="less" scoped>
.error_block{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>