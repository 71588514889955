<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="资产申请详情" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="750" 
    :closable="!dialogLoading"
  >
    <template slot="footer">
      <a-button type="primary" :disabled="dialogLoading" @click="DistributionAssets">派发资产</a-button>
      <a-button type="primary" :disabled="dialogLoading" @click="putDeliveryNotes">保存备注</a-button>
      <a-button type="primary" :disabled="dialogLoading" v-if="dialogConfig.applyDetail.status=='untreated'" @click="doneApply">完成</a-button>
      <a-button type="primary" :disabled="dialogLoading" v-if="dialogConfig.applyDetail.status=='untreated'" @click="rejectApply">驳回</a-button>
      <a-button @click="handleCancel" :disabled="dialogLoading">关闭</a-button>
    </template>
    <div class="applydetaildialog_block">
      <a-spin :spinning="dialogLoading" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <a-form :form="form">
          <a-row :gutter="24">
            <a-col :span="8"><a-form-item v-bind="formItemLayout" label="申请人">{{dialogConfig.applyDetail.applyUserName}}</a-form-item></a-col>
            <a-col :span="8"><a-form-item v-bind="formItemLayout" label="手机号码">{{dialogConfig.applyDetail.phoneNumber}}</a-form-item></a-col>
            <a-col :span="8"><a-form-item v-bind="formItemLayout" label="提交日期">{{$moment(dialogConfig.applyDetail.dateEntered).format('YYYY-MM-DD')}}</a-form-item></a-col>
            <a-col :span="24"><a-form-item style="margin-left:-8px" v-bind="formItemSingleLayout" label="申请内容">{{dialogConfig.applyDetail.description}}</a-form-item></a-col>
            <a-col :span="24"><a-form-item style="margin-left:-8px" v-bind="formItemSingleLayout" label="备注"><a-textarea v-decorator="['deliveryNotes',{initialValue: dialogConfig.applyDetail.deliveryNotes,rules:[{ max: 200, message: '最多填写200个字符' }]}]" placeholder="请输入备注"/></a-form-item></a-col>
          </a-row>
        </a-form>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object,
  },
  data(){
    return{
      dialogLoading: false,
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 9 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 15 } },
      },
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 3 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 21 } },
      }
    }
  },
  methods: {
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    doneApply(){
      this.$emit("doneApply",this.dialogConfig.applyDetail);
    },
    rejectApply(){
      this.$emit("rejectApply",this.dialogConfig.applyDetail);
    },
    putDeliveryNotes(){
      this.dialogLoading = true;
      let that = this;
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.$put("/apply",{...this.dialogConfig.applyDetail,deliveryNotes:values.deliveryNotes? values.deliveryNotes:''})
          .then(function(){
            that.dialogLoading = false;
            that.$message.success("保存成功");
            that.$emit("getApplyListTableData");
          })
          .catch(function(){
            that.dialogLoading = false;
          })
        } else {
          return false;
        }   
      })
    },
    DistributionAssets(){
      this.$emit("DistributionAssets",this.dialogConfig.applyDetail);
    }
  }
}
</script>

<style lang="less" scoped>
.applydetaildialog_block{
  width: 100%;
  height: auto;
  max-height: 500px;
  padding: 30px 50px 20px 50px;
  overflow-x: hidden;
  overflow-y: auto;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
// /deep/ .ant-col{
//   height: 40px;
//   line-height: 40px;
// }
/deep/ .ant-form-item-control{
  line-height: 20px;
  padding-top: 10px;
}
/deep/ .ant-form-item{
  margin-bottom: 0;
  padding-bottom: 10px;
}
/deep/ .ant-form-item:hover{
  background-color: rgba(27, 42, 78, 0.05);
}
/deep/ .ant-divider-horizontal{
  margin: 8px 0;
}
</style>