<template>
  <div class="default_main">
    <a-spin :spinning="typeTreeData==null||typeTableData==null||pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="typemanagement_header">
      <a-input-search class="default_searchinput" placeholder="请输入分类编号或分类名称" enter-button v-model="normalSearchDataShow" @search="onNormalSearch"/>
      <a-dropdown>
        <a-button class="f_right" type="primary">添加分类</a-button>
        <a-menu slot="overlay">
          <a-menu-item @click="callTypeAddDialog()">新增</a-menu-item>
          <a-menu-item @click="callBatchImportDialog">导入</a-menu-item>
        </a-menu>
      </a-dropdown>
      </div>
      <div class="typemanagement_content">
      <div class="typemanagement_content_left">
        <a-tree v-if="typeTreeData&&typeTreeData.length>0" :treeData="typeTreeData" :defaultExpandedKeys="defaultTypeTreeExpandedKeys" :blockNode="true" @select="typeTreeSelect" :selectedKeys="typeTreeSelectedKeys">
          <template slot="custom" slot-scope="item">
            <div class="tree_node">
            <div class="tree_n_title" :title="item.title">{{item.title}}</div>
            <div class="tree_n_board">
              <div class="tree_n_b_block">
                <a-icon class="tree_n_b_icon" type="plus-circle" @click="addTypeBaseRoot(item)"/>
                <a-icon class="tree_n_b_icon" type="edit" @click="callTypeEditDialog(item)"/>
                <a-icon class="tree_n_b_icon" type="delete" @click="deleteType(item)"/>
              </div>
            </div>
            </div>
          </template>
        </a-tree>
      </div>
      <div class="typemanagement_content_right">
        <a-table 
          :columns="typeTableColumns" 
          :data-source="typeTableData" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="typeTablePagination" 
          @change="handleTypeTableChange"
        >
          <template slot="actions" slot-scope="text,record">
            <font class="table_action" @click="callTypeEditDialog(record)">修改</font>
            <font class="table_action" @click="deleteType(record)">删除</font>
          </template>
        </a-table>
      </div>
      </div>
    </a-spin>
    <BatchImportDialog :dialogConfig="BatchImportDialogConfig" @submit="batchImportTypes"/>
    <TypeAddDialog :dialogConfig="TypeAddDialogConfig" :typeTreeDataWithRoot="typeTreeDataWithRoot" @submit="addType"/>
    <TypeEditDialog :dialogConfig="TypeEditDialogConfig" :typeTreeDataWithRoot="typeTreeDataWithRoot" @submit="editType"/>
  </div>
</template>

<script>
import BatchImportDialog from "../../components/BatchImportDialog.vue";
import TypeAddDialog from "../../components/TypeAddDialog.vue";
import TypeEditDialog from "../../components/TypeEditDialog.vue";
export default {
  components: {
    BatchImportDialog,
    TypeAddDialog,
    TypeEditDialog
  },
  data(){
    return {
      searchType: "normal",
      pageLoading: false,
      normalSearchData: "",
      normalSearchDataShow: "",
      typeTreeData: null,
      typeTreeDataWithRoot: null,
      defaultTypeTreeExpandedKeys: [],
      typeTreeSelectedKeys: [],
      typeTableColumns: [
        {title: '分类编号',dataIndex: 'code',key: 'code',align: 'center',width: 150,ellipsis: true},
        {title: '分类名称',dataIndex: 'name',key: 'name',align: 'center',width: 180,ellipsis: true},
        {title: '上级分类',dataIndex: 'parentName',key: 'parentName',align: 'center',width: 180,ellipsis: true},
        {title: '使用期限（月）',dataIndex: 'dateEstimated',key: 'dateEstimated',align: 'center',width: 200},
        {title: '计量单位',dataIndex: 'measureUnit',key: 'measureUnit',align: 'center',width: 200,ellipsis: true},
        {title: '操作',key: 'actions',align: 'center',width: 100,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      typeTableData: null,
      typeTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      BatchImportDialogConfig: { visible:false,requesting:false,title:"",templateUrl:"",templateName:"" },
      TypeAddDialogConfig: { visible:false,requesting:false,parentId:null },
      TypeEditDialogConfig: { visible:false,requesting:false,type:{} }
    }
  },
  mounted(){
    this.getTypeTreeData(()=>{});
    this.getTypeTableData(()=>{});
  },
  methods: {
    //获取树部分的数据
    getTypeTreeData(callback){
      let that = this;
      this.$get("/categoryStructureWithRoot",{})
      .then(function(res){
        that.typeTreeDataWithRoot = res.data;
        that.typeTreeData = that.typeTreeDataWithRoot[0].children;
        that.typeTreeDataWithRoot[0].title = "无上级";
        if(that.typeTreeData[0]){ that.defaultTypeTreeExpandedKeys=[that.typeTreeData[0].id]; }
        callback();
      })
    },
    //获取表格数据
    getTypeTableData(callback){
      let that = this;
      if(this.searchType=="normal"){
        this.$get("/categories?searchData="+that.normalSearchData+"&pageSize="+this.typeTablePagination.pageSize+"&pageNumber="+this.typeTablePagination.current,{})
        .then(function(res){
          that.typeTableData = res.data.records;
          const pager = { ...that.typeTablePagination };
          pager.total = res.data.total;
          that.typeTablePagination = pager;
          callback();
        })
      }else{
        this.$get("/categories/"+that.typeTreeSelectedKeys[0]+"?pageSize="+this.typeTablePagination.pageSize+"&pageNumber="+this.typeTablePagination.current,{})
        .then(function(res){
          that.typeTableData = res.data.records;
          const pager = { ...that.typeTablePagination };
          pager.total = res.data.total;
          that.typeTablePagination = pager;
          callback();
        })
      }
    },
    //触发树选择
    typeTreeSelect(selectedKeys){
      if(selectedKeys.length==0){ return; }
      this.typeTreeSelectedKeys = selectedKeys;
      this.searchType = "tree";
      this.normalSearchData = "";
      this.normalSearchDataShow = "";
      this.pageLoading = true;
      this.typeTablePagination.current = 1;
      this.getTypeTableData(()=>{ this.pageLoading = false; });
    },
    handleTypeTableChange(pagination){
      const pager = { ...this.typeTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.typeTablePagination = pager;
      this.pageLoading = true;
      this.getTypeTableData(()=>{ this.pageLoading = false; });
    },
    onNormalSearch(value){
      this.searchType = "normal";
      this.normalSearchData = value;
      this.pageLoading = true;
      this.typeTreeSelectedKeys = [];
      this.typeTablePagination.current = 1;
      this.getTypeTableData(()=>{ this.pageLoading = false; });
    },
    callTypeEditDialog(record){
      this.TypeEditDialogConfig = { visible:true,requesting:false,type:{...record} };
    },
    editType(values){
      this.TypeEditDialogConfig.requesting = true;
      let that = this;
      this.$put("/category",values)
      .then(function(){
        that.TypeEditDialogConfig.requesting = false;
        that.TypeEditDialogConfig.visible = false;
        that.$message.success('保存成功');
        that.pageLoading = true;
        that.getTypeTreeData(()=>{
          that.getTypeTableData(()=>{ that.pageLoading=false; });
        });
      })
      .catch(function(){
        that.TypeEditDialogConfig.requesting = false;
      })
    },
    callTypeAddDialog(){
      this.TypeAddDialogConfig = { visible:true,requesting:false,parentId:null };
    },
    addType(values){
      this.TypeAddDialogConfig.requesting = true;
      let that = this;
      this.$post("/category",values)
      .then(function(){
        that.TypeAddDialogConfig.requesting = false;
        that.TypeAddDialogConfig.visible = false;
        that.$message.success('保存成功');
        that.pageLoading = true;
        that.getTypeTreeData(()=>{
          that.getTypeTableData(()=>{ that.pageLoading=false; });
        });
      })
      .catch(function(){
        that.TypeAddDialogConfig.requesting = false;
      })
    },
    callBatchImportDialog(){
      this.BatchImportDialogConfig = { visible:true,requesting:false,title:"批量导入分类",templateUrl:"/categories/export/excelTemplate",templateName:"分类模板.xls" };
    },
    batchImportTypes(values){
      this.BatchImportDialogConfig.requesting = true;
      let that = this;
      this.$post("/categories/import",values)
      .then(function(res){
        that.BatchImportDialogConfig.requesting = false;
        if(res.data.import_status==="error"){
          that.$message.warning({
            title: '错误提示',
            content: res.data.import_error_info,
            duration: 5
          });
        }else{
          that.BatchImportDialogConfig.visible = false;
          that.$message.success("导入成功");
          that.typeTablePagination.current = 1;
          that.pageLoading = true;
          that.getTypeTreeData(()=>{
            that.getTypeTableData(()=>{ that.pageLoading=false; });
          });
        } 
      })
      .catch(function(){
        that.BatchImportDialogConfig.requesting = false;
      })
    },
    addTypeBaseRoot(record){
      this.TypeAddDialogConfig = { visible:true,requesting:false,parentId:record.id };
    },
    deleteType(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除【"+record.name+"】分类吗?",
        onOk(){
          that.pageLoading = true;
          that.$delete("/category/"+record.id,{})
          .then(function(){
            that.pageLoading=false;
            that.$message.success("删除成功");
            that.pageLoading = true;
            that.searchType = "normal";
            that.normalSearchData = "";
            that.typeTreeSelectedKeys = [];
            that.typeTablePagination.current = 1;
            that.getTypeTreeData(()=>{
              that.getTypeTableData(()=>{ that.pageLoading=false; });
            });
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    }
  }
}
</script>

<style lang="less" scoped>
.typemanagement_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.typemanagement_content{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
.typemanagement_content_left{
  width: 300px;
  height: 100%;
  float: left;
  box-sizing: border-box;
  border-right: 1px solid #d1d7de;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px 0px 15px 15px;
}
.typemanagement_content_right{
  width: calc(100% - 300px);
  height: 100%;
  float: left;
}
</style>