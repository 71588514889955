<template>
  <div class="default_main">
    <a-spin :spinning="pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="extendmanagement_header">
        <a-input-search class="default_searchinput" placeholder="请输入扩展信息名称或应用范围" enter-button @search="onSearch"/>
        <a-button class="f_right" type="primary" @click="callExtendAddDialog">添加字段扩展</a-button>
      </div>
      <div class="extendmanagement_table">
        <a-table 
          :columns="extendsTableColumns" 
          :data-source="extendsTableData" 
          :row-selection="{ selectedRowKeys: extendsTableSelectedRowKeys,onChange: extendsTableOnSelectChange }" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="extendsTablePagination" 
          @change="handleExtendsTableChange"
        >
          <template slot="active" slot-scope="active">
            <a-tag :color="active===true? '#87D068':''">{{ active === true ? "在职":"离职"}}</a-tag>
          </template>
          <template slot="actions" slot-scope="text,record">
            <font class="table_action" @click="callExtendEditDialog(record)">修改</font>
            <font class="table_action" @click="deleteExtend(record)">删除</font>
          </template>
        </a-table>
      </div>
    </a-spin>
    <ExtendAddDialog :dialogConfig="ExtendAddDialogConfig" :assetsTypeData="assetsTypeData" @submit="addExtend"/>
    <ExtendEditDialog :dialogConfig="ExtendEditDialogConfig" :assetsTypeData="assetsTypeData" @submit="editExtend"/>
  </div>
</template>

<script>
import ExtendAddDialog from "../../components/ExtendAddDialog.vue";
import ExtendEditDialog from "../../components/ExtendEditDialog.vue";
export default {
  components: {
    ExtendAddDialog,
    ExtendEditDialog
  },
  data(){
    return {
      pageLoading: false,
      extendsTableColumns: [
        {title: '扩展信息名称 ',dataIndex: 'labelName',key: 'labelName',align: 'center',width: 150,ellipsis: true},
        {title: '应用范围（分类）',dataIndex: 'categoryName',key: 'categoryName',align: 'center',width: 200,ellipsis: true},
        {title: '是否必填',dataIndex: 'required',key: 'required',align: 'center',width: 120,customRender: (val) => {return val ? '是':'否'}},
        {title: '操作',key: 'actions',align: 'center',width: 100,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      extendsTableData: [],
      extendsTableSelectedRowKeys: [],
      extendsTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      searchData: "",
      assetsTypeData: null,
      ExtendAddDialogConfig: { visible:false,requesting:false },
      ExtendEditDialogConfig: { visible:false,requesting:false,extend:{} }
    }
  },
  mounted(){
    this.getExtendsTableData();
    this.getAssetsTypeTreeData();
  },
  methods: {
    //获取资产分类数据
    getAssetsTypeTreeData(){
      let that = this;
      this.$get("/categoryStructure",{})
      .then(function(res){
        that.assetsTypeData = res.data;
      })
    },
    getExtendsTableData(){
      let that = this;
      this.pageLoading = true;
      let param = "pageNumber="+this.extendsTablePagination.current+"&pageSize="+this.extendsTablePagination.pageSize;
      if(this.searchData!=null){ param=param+"&searchData="+this.searchData; }
      this.$get("/categoryExtendFields?"+param,{})
      .then(function(res){
        that.pageLoading = false;
        that.extendsTableSelectedRowKeys = [];
        that.extendsTableData = res.data.records;
        const pager = { ...that.extendsTablePagination };
        pager.total = res.data.total;
        that.extendsTablePagination = pager;
      })
    },
    onSearch(value){
      this.searchData = value;
      this.extendsTablePagination.current = 1;
      this.getExtendsTableData();
    },
    extendsTableOnSelectChange(selectedRowKeys){
      this.extendsTableSelectedRowKeys = selectedRowKeys;
    },
    handleExtendsTableChange(pagination){
      const pager = { ...this.extendsTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.extendsTablePagination = pager;
      this.getExtendsTableData();
    },
    //修改
    callExtendEditDialog(record){
      let fix = {...record};
      fix.required = record.required===true? "true":"false";
      this.ExtendEditDialogConfig = { visible:true,requesting:false,extend:fix };
    },
    editExtend(values){
      this.ExtendEditDialogConfig.requesting = true;
      let that = this;
      this.$put("/categoryExtendField",values)
      .then(function(){
        that.ExtendEditDialogConfig.requesting = false;
        that.ExtendEditDialogConfig.visible = false;
        that.$message.success('保存成功');
        that.getExtendsTableData();
      })
      .catch(function(){
        that.ExtendEditDialogConfig.requesting = false;
      })
    },
    //删除
    deleteExtend(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除【"+record.labelName+"】字段扩展吗（记录数据也将删除）?",
        onOk(){
          that.pageLoading = true;
          that.$post('/categoryExtendFields/delete',[record.id])
          .then(function(){
            that.pageLoading=false;
            that.$message.success("删除成功");
            if(that.extendsTableData.length==1&&that.extendsTablePagination.current>1){ that.extendsTablePagination.current--; }
            that.getExtendsTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    //新增
    callExtendAddDialog(){
      this.ExtendAddDialogConfig = { visible:true,requesting:false };
    },
    addExtend(values){
      this.ExtendAddDialogConfig.requesting = true;
      let that = this;
      this.$post("/categoryExtendField",values)
      .then(function(){
        that.ExtendAddDialogConfig.requesting = false;
        that.ExtendAddDialogConfig.visible = false;
        that.$message.success('保存成功');
        that.getExtendsTableData();
      })
      .catch(function(){
        that.ExtendAddDialogConfig.requesting = false;
      })
    }
  }
}
</script>

<style lang="less" scoped>
.extendmanagement_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.extendmanagement_header button+button{
  margin-right: 5px;
}
.extendmanagement_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
</style>