<template>
  <div class="default_main">
    <a-spin :spinning="pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="inventorymanagement_header">
        <a-input-search class="default_searchinput" placeholder="请输入盘点单名称" enter-button v-model="searchDataShow" @search="onSearch"/>
        <a-button class="f_right" type="primary" @click="callInventoryAddDialog">开始盘点</a-button>
      </div>
      <div class="inventorymanagement_table">
        <a-table 
          :columns="inventoryListTableColumns" 
          :data-source="inventoryListTableData" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="inventoryListTablePagination" 
          @change="handleInventoryListTableChange"
        >
          <template slot="status" slot-scope="status">
            <a-tag :color="status==='finished'? '#108EE9':status==='terminated'? '':'#87d068'">
              {{ status==='ongoing'?'盘点中':status==='finished'?'已完成':status==='terminated'?'已终止':'' }} 
            </a-tag>
          </template>
          <template slot="checkName" slot-scope="text,record">
            <router-link class="table_action" :to="{path:'/backstage/assetManagement/inventory/detail',query: {orderCheckId:record.id}}">{{text}}</router-link>
          </template>
          <template slot="actions" slot-scope="text,record">
            <font class="table_action" @click="callInventoryDetailDialog(record)">详情</font>
            <font class="table_action" v-if="record.status=='ongoing'" @click="stopInventory(record)">终止</font>
            <font class="table_action disabled" v-if="record.status!='ongoing'">终止</font>
            <!-- <font class="table_action" v-if="record.status=='ongoing'">修改</font>
            <font class="table_action disabled" v-if="record.status!='ongoing'">修改</font> -->
            <font class="table_action" v-if="record.status=='terminated'" @click="deleteInventory(record)">删除</font>
            <font class="table_action disabled" v-if="record.status!=='terminated'">删除</font>
          </template>
        </a-table>
      </div>
    </a-spin>
    <InventoryAddDialog :dialogConfig="InventoryAddDialogConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" @submit="postInventoryDetail"/>
    <InventoryDetailDialog :dialogConfig="InventoryDetailDialog"/>
  </div>
</template>

<script>
import InventoryAddDialog from "../../components/InventoryAddDialog.vue";
import InventoryDetailDialog from "../../components/InventoryDetailDialog.vue";
export default {
  components: {
    InventoryAddDialog,
    InventoryDetailDialog
  },
  data(){
    return {
      pageLoading: false,
      searchDataShow: "",
      searchData: "",
      inventoryListTableColumns: [
        {title: '盘点状态',dataIndex: 'status',key: 'status',align: 'center',width: 150,scopedSlots: { customRender: 'status' }},
        {title: '盘点名称',dataIndex: 'name',key: 'name',align: 'center',width: 300,scopedSlots: { customRender: 'checkName' }},
        {title: '计划开始时间',dataIndex: 'startTime',key: 'startTime',align: 'center',width: 200,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '计划结束时间',dataIndex: 'endTime',key: 'endTime',align: 'center',width: 200,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '创建人',dataIndex: 'createUserName',key: 'createUserName',align: 'center',width: 180},
        {title: '创建时间',dataIndex: 'dateEntered',key: 'dateEntered',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '完成时间',dataIndex: 'dateCompleted',key: 'dateCompleted',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '操作',key: 'actions',align: 'center',width: 130,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      inventoryListTableData: [],
      inventoryListTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      InventoryAddDialogConfig: { visible:false,requesting:false },
      assetsTypeData: [],
      assetsLocationData: [],
      InventoryDetailDialog: { visible:false,inventoryDetail:{} }
    }
  },
  mounted(){
    this.getInventoryListTableData();
    this.getAssetsTypeTreeData();
    this.getAssetsLocationTreeData();
  },
  methods: {
    onSearch(value){
      this.searchData = value;
      this.inventoryListTablePagination.current = 1;
      this.getInventoryListTableData();
    },
    handleInventoryListTableChange(pagination){
      const pager = { ...this.inventoryListTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.inventoryListTablePagination = pager;
      this.getInventoryListTableData();
    },
    getInventoryListTableData(){
      this.pageLoading = true;
      let that = this;
      this.$get("/orderChecks?pageNumber="+this.inventoryListTablePagination.current+"&pageSize="+this.inventoryListTablePagination.pageSize+"&searchData="+this.searchData,{})
      .then(function(res){
        that.pageLoading = false;
        that.inventoryListTableData = res.data.records;
        const pager = { ...that.inventoryListTablePagination };
        pager.total = res.data.total;
        that.inventoryListTablePagination = pager;
      })
    },
    //获取资产分类数据
    getAssetsTypeTreeData(){
      let that = this;
      this.$get("/categoryStructure",{})
      .then(function(res){
        that.assetsTypeData = res.data;
      })
    },
    //获取资产位置数据
    getAssetsLocationTreeData(){
      let that = this;
      this.$get("/locationStructure",{})
      .then(function(res){
        that.assetsLocationData = res.data;
      })
    },
    //新增盘点
    callInventoryAddDialog(){
      this.InventoryAddDialogConfig = { visible:true,requesting:false };
    },
    postInventoryDetail(values){
      this.InventoryAddDialogConfig.requesting = true;
      let that = this;
      this.$post("/orderCheck",values)
      .then(function(){
        that.InventoryAddDialogConfig.requesting = false;
        that.InventoryAddDialogConfig.visible = false;
        that.$message.success("创建盘点成功");
        that.getInventoryListTableData();
      })
      .catch(function(){
        that.InventoryAddDialogConfig.requesting = false;
      })
    },
    callInventoryDetailDialog(record){
      this.InventoryDetailDialog = { visible:true,inventoryDetail:record };
    },
    stopInventory(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "终止操作",
        content: "确定终止【"+record.name+"】盘点吗?",
        onOk(){
          that.pageLoading = true;
          that.$put("/orderCheck/"+record.id+"/terminate",{})
          .then(function(){
            that.pageLoading = false;
            that.$message.success("终止成功");
            that.getInventoryListTableData();
          })
          .catch(function(){
            that.pageLoading = false;
          })
        },
        onCancel(){}
      });
    },
    deleteInventory(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除【"+record.name+"】盘点吗?",
        onOk(){
          that.pageLoading = true;
          that.$delete("/orderCheck/"+record.id,{})
          .then(function(){
            that.pageLoading = false;
            that.$message.success("删除成功");
            if(that.inventoryListTableData.length==1&&that.inventoryListTablePagination.current>1){ that.inventoryListTablePagination.current--; }
            that.getInventoryListTableData();
          })
          .catch(function(){
            that.pageLoading = false;
          })
        },
        onCancel(){}
      });
    }
  }
}
</script>

<style lang="less" scoped>
.inventorymanagement_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.inventorymanagement_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
</style>