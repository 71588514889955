<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="盘盈记录详情" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="750" 
    :closable="true"
  >
    <template slot="footer">
      <a-button type="primary" @click="callAssetAddDialog">新增盘盈资产</a-button>
      <a-button type="primary" v-if="dialogConfig.surplusDetail.status=='untreated'" @click="doneSurplus">完成</a-button>
      <a-button type="primary" v-if="dialogConfig.surplusDetail.status=='untreated'" @click="stopSurplus">终止</a-button>
      <a-button @click="handleCancel">关闭</a-button>
    </template>
    <div class="surplusdetaildialog_block">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="盘点人">{{dialogConfig.surplusDetail.checkUserName}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="手机号码">{{dialogConfig.surplusDetail.phoneNumber}}</a-form-item></a-col>
          <a-col :span="8"><a-form-item v-bind="formItemLayout" label="提交日期">{{$moment(dialogConfig.surplusDetail.dateEntered).format('YYYY-MM-DD')}}</a-form-item></a-col>
          <a-col :span="24"><a-form-item style="margin-left:-8px" v-bind="formItemSingleLayout" label="盘盈内容">{{dialogConfig.surplusDetail.description}}</a-form-item></a-col>
        </a-row>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object,
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 9 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 15 } },
      },
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 3 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 21 } },
      }
    }
  },
  methods: {
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    doneSurplus(){
      this.$emit("doneSurplus",this.dialogConfig.surplusDetail);
    },
    stopSurplus(){
      this.$emit("stopSurplus",this.dialogConfig.surplusDetail);
    },
    callAssetAddDialog(){
      this.$emit("callAssetAddDialog");
    }
  }
}
</script>

<style lang="less" scoped>
.surplusdetaildialog_block{
  width: 100%;
  height: auto;
  max-height: 500px;
  padding: 30px 50px 20px 50px;
  overflow-x: hidden;
  overflow-y: auto;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
// /deep/ .ant-col{
//   height: 40px;
//   line-height: 40px;
// }
/deep/ .ant-form-item-control{
  line-height: 20px;
  padding-top: 10px;
}
/deep/ .ant-form-item{
  margin-bottom: 0;
  padding-bottom: 10px;
}
/deep/ .ant-form-item:hover{
  background-color: rgba(27, 42, 78, 0.05);
}
/deep/ .ant-divider-horizontal{
  margin: 8px 0;
}
</style>