<template>
  <div class="default_main">
    <a-spin :spinning="assetsListTableData==null||pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="inventorydetail_header">
        <a-button class="f_left" @click="goBackPage" icon="arrow-left" title="返回盘点列表"/>
        <a-input-search style="margin-left:5px" class="default_searchinput" placeholder="请输入资产名称或编码" enter-button v-model="normalSearchDataShow" @search="onNormalSearch"/>
        <a-button-group class="inventorydetail_button_group">
          <a-button @click="callAssetsAdvanceSearchDrawer">高级搜索</a-button>
          <a-button v-if="searchType=='advanced'&&advancedSearchTags.length>0" icon="stop" @click="stopAssetsAdvanceSearch"/>
        </a-button-group>
        <a-dropdown>
          <a-button class="f_right">导出</a-button>
          <a-menu slot="overlay">
            <a-menu-item @click="exportInventoryDetail('all')">导出全部</a-menu-item>
            <a-menu-item @click="exportInventoryDetail('selected')">导出选中</a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-button class="f_right" type="primary" @click="InventoryDone">盘点完成</a-button>
        <a-button class="f_right" type="primary" @click="goInventorySurplus">盘盈记录</a-button>
        <a-button class="f_right" type="primary" @click="callInventoryProcessDialog">进展情况</a-button>
      </div>
      <div class="inventorydetail_tags" v-if="searchType=='advanced'&&advancedSearchTags.length>0">
        <a-tag closable v-for="item in advancedSearchTags" :key="item.field" @close="closeAdvancedSearchTag(item)"> {{item.name}}:{{item.showValue}} </a-tag>
      </div>
      <div :class="['inventorydetail_table',searchType=='advanced'&&advancedSearchTags.length>0? 'advanced':'']">
        <a-table 
          :columns="assetsListTableColumns" 
          :data-source="assetsListTableData" 
          :row-selection="{ selectedRowKeys: assetsListTableSelectedRowKeys,onChange: assetsListTableOnSelectChange }" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="assetsListTablePagination" 
          @change="handleAssetsListTableChange"
        >
          <template slot="checkStatus" slot-scope="checkStatus">
            <a-tag :color="checkStatus==='undone'?'':checkStatus==='finished'?'#87D068':checkStatus==='surplus'?'#722ED1':checkStatus==='loss'?'#F5222D':''">
                {{ checkStatus==='undone'?'未盘':checkStatus==='finished'?'已盘':checkStatus==='surplus'?'盘盈':checkStatus==='loss'?'盘亏':'' }} 
            </a-tag>
          </template>
          <!-- <template slot="status" slot-scope="status">
            <a-tag :color="status==='leisure'? '#D46B08':status==='dispose_end'? '':status==='receive_end'? '#108EE9':'#87D068'">
              {{ status==='leisure'?'空闲':status==='receive_approval'?'领用审批中':status==='receive_confirming'?'待领用':
                status==='receive_sign_confirming'?'领用待签字':status==='receive_end'?'已领用':status==='loan'?'借出':status==='cancel_approval'?'退库审批中':
                status==='dispose'?'处置':status==='cancel_confirming'?'待退库':status==='cancel_sign_confirming'?'退库待签字':
                status==='transfer_confirming'?'待转移':status==='transfer_sign_confirming'?'转移待签字':
                status==='dispose_confirming'?'待处置':status==='dispose_sign_confirming'?'处置待签字':
                status==='dispose_end'?'已处置':status}}
            </a-tag>
          </template> -->
          <template slot="usageCase" slot-scope="usageCase">
            {{ usageCase==='normal'?'正常':usageCase==='fault'?'故障':'' }} 
          </template>
          <template slot="warehousedType" slot-scope="warehousedType">     
            {{ warehousedType==='purchase'?'采购':warehousedType==='allocation'?'调拨':warehousedType==='inventory_win'?'盘赢':warehousedType==='donation'?'捐赠':warehousedType==='others'?'其他':warehousedType}} 
          </template>
          <template slot="signId" slot-scope="text,record">
            <font class="table_action" v-if="record.signId" @click="callSignDetailDialog(record)">查看</font>
            <font v-else>暂无</font>
          </template>
        </a-table>
      </div>
    </a-spin>
    <AssetsAdvancedSearchDrawer :drawerConfig="AssetsAdvanceSearchDrawerConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :employeeData="employeeData" @getEmployeeData="getEmployeeData" @submit="onAdvancedSearch"/>
    <InventoryProgressDialog :dialogConfig="InventoryProgressDialogConfig"/>
    <SignDetailDialog :dialogConfig="SignDetailDialogConfig"/>
  </div>
</template>

<script>
import AssetsAdvancedSearchDrawer from "../../components/AssetsAdvancedSearchDrawer.vue";
import InventoryProgressDialog from "../../components/InventoryProgressDialog.vue";
import SignDetailDialog from "../../components/SignDetailDialog.vue";
export default {
  components: {
    AssetsAdvancedSearchDrawer,
    InventoryProgressDialog,
    SignDetailDialog
  },
  data(){
    return {
      pageLoading: false,
      searchType:"normal",
      normalSearchData: null,
      normalSearchDataShow: "",
      advancedSearchData:null,
      advancedSearchTags: [],
      assetsListTableColumns: [
        {title: '盘点状态',dataIndex: 'checkStatus',key: 'checkStatus',align: 'center',ellipsis: true,width: 80,fixed: 'left',scopedSlots: { customRender: 'checkStatus' }},
        // {title: '资产状态',dataIndex: 'status',key: 'status',align: 'center',width: 80,fixed: 'left',scopedSlots: { customRender: 'status' },ellipsis: true},
        {title: '资产编码',dataIndex: 'code',key: 'code',align: 'center',width: 150,fixed: 'left',ellipsis: true},
        {title: '资产名称',dataIndex: 'name',key: 'name',align: 'center',ellipsis: true,fixed: 'left',width: 200},
        {title: '盘点人',dataIndex: 'checkUserName',key: 'checkUserName',align: 'center',ellipsis: true,width: 120},
        {title: '盘点日期',dataIndex: 'dateCheck',key: 'dateCheck',align: 'center',width: 120,customRender: (val,record) => {return record.checkStatus!='undone'&&val? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '盘点备注',dataIndex: 'checkNotes',key: 'checkNotes',align: 'center',ellipsis: true,width: 200,customRender: (val,record) => {return record.checkStatus!='undone'? val : ''}},
        {title: '签字照片',dataIndex: 'signId',key: 'signId',align: 'center',ellipsis: true,width: 80,scopedSlots: { customRender: 'signId' }},
        {title: '资产分类',dataIndex: 'categoryName',key: 'categoryName',align: 'center',ellipsis: true,width: 120},
        {title: '资产位置',dataIndex: 'locationName',key: 'locationName',align: 'center',ellipsis: true,width: 120},
        {title: '入库类型',dataIndex: 'warehousedType',key: 'warehousedType',align: 'center',ellipsis: true,width: 120,scopedSlots: { customRender: 'warehousedType' }},
        {title: '入库日期',dataIndex: 'dateWarehoused',key: 'dateWarehoused',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '所属库房',dataIndex: 'storeLocationName',key: 'storeLocationName',align: 'center',ellipsis: true,width: 120},
        {title: '使用状况',dataIndex: 'usageCase',key: 'usageCase',align: 'center',width: 120,scopedSlots: { customRender: 'usageCase' }},
        {title: '品牌',dataIndex: 'brand',key: 'brand',align: 'center',ellipsis: true,width: 120},
        {title: '型号',dataIndex: 'modelNumber',key: 'modelNumber',align: 'center',ellipsis: true,width: 120},
        {title: '金额',dataIndex: 'amount',key: 'amount',align: 'center',ellipsis: true,width: 100},
        {title: '使用期限（月）',dataIndex: 'dateEstimated',key: 'dateEstimated',align: 'center',width: 120},
        {title: '计量单位',dataIndex: 'measureUnit',key: 'measureUnit',align: 'center',width: 120},
        {title: '领用人',dataIndex: 'receivedUserName',key: 'receivedUserName',align: 'center',ellipsis: true,width: 120},
        {title: '领用日期',dataIndex: 'dateReceived',key: 'dateReceived',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '备注',dataIndex: 'notes',key: 'notes',align: 'center',ellipsis: true,width: 250},
        {title: '供应商',dataIndex: 'maintenanceProvider',key: 'maintenanceProvider',align: 'center',ellipsis: true,width: 120},
        {title: '联系人',dataIndex: 'maintenanceContacts',key: 'maintenanceContacts',align: 'center',ellipsis: true,width: 120},
        {title: '联系方式',dataIndex: 'maintenanceContactInformation',key: 'maintenanceContactInformation',align: 'center',ellipsis: true,width: 120},
        {title: '维保到期日期',dataIndex: 'maintenanceDateExpired',key: 'maintenanceDateExpired',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}}
      ],
      assetsListTableData: null,
      assetsListTableSelectedRowKeys: [],
      assetsListTableSelectedData: [],
      assetsListTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      AssetsAdvanceSearchDrawerConfig: { visible:false,action:"inventoryDetail" },
      assetsTypeData: null,
      assetsLocationData: null,
      employeeData: null,
      InventoryProgressDialogConfig: { visible:false,inventoryId:"" },
      SignDetailDialogConfig: { visible:false,fileId:null }
    }
  },
  mounted(){
    this.getAssetsListTableData();
    this.getAssetsTypeTreeData();
    this.getAssetsLocationTreeData();
    this.getEmployeeData();
  },
  methods: {
    //获取资产分类数据
    getAssetsTypeTreeData(){
      let that = this;
      this.$get("/categoryStructure",{})
      .then(function(res){
        that.assetsTypeData = res.data;
      })
    },
    //获取资产位置数据
    getAssetsLocationTreeData(){
      let that = this;
      this.$get("/locationStructure",{})
      .then(function(res){
        that.assetsLocationData = res.data;
      })
    },
    //加载员工数据
    getEmployeeData(value){
      let that = this;
      let param = "pageNumber=1&pageSize=50&active=true";
      if(value!=null){ param=param+"&searchData="+value; }
      this.$get("/userEmployees?"+param,{})
      .then(function(res){ 
        that.employeeData = res.data.records;
      })
    },
    onNormalSearch(value){
      this.searchType = "normal";
      this.normalSearchData = value;
      this.advancedSearchData = {};
      this.advancedSearchTags = [];
      this.assetsListTablePagination.current = 1;
      this.getAssetsListTableData();
    },
    //高级搜索
    onAdvancedSearch(tags,data){
      this.AssetsAdvanceSearchDrawerConfig.visible = false;
      this.advancedSearchTags = tags;
      this.advancedSearchData = data;
      this.normalSearchData = "";
      this.normalSearchDataShow = "";
      if(tags.length==0){
        this.searchType = "normal";
      }else{
        this.searchType = "advanced";
      }
      this.assetsListTablePagination.current = 1;
      this.getAssetsListTableData();
    },
    //关闭高级搜索项
    closeAdvancedSearchTag(item){
      delete this.advancedSearchData[item.field];
      this.advancedSearchTags.splice(this.advancedSearchTags.findIndex(temp => temp.field === item.field), 1);
      if(this.advancedSearchTags.length==0){
        this.stopAssetsAdvanceSearch();
      }else{
        this.getAssetsListTableData();
      }
    },
    assetsListTableOnSelectChange(selectedRowKeys,selectedRows){
      this.assetsListTableSelectedRowKeys = selectedRowKeys;
      this.assetsListTableSelectedData = selectedRows;
    },
    callAssetsAdvanceSearchDrawer(){
      this.AssetsAdvanceSearchDrawerConfig = { visible:true,action:"inventoryDetail" };
    },
    callSignDetailDialog(record){
      this.SignDetailDialogConfig = { visible:true,fileId:record.signId };
    },
    stopAssetsAdvanceSearch(){
      this.advancedSearchData = {};
      this.advancedSearchTags = [];
      this.searchType = "normal";
      this.normalSearchData = "";
      this.normalSearchDataShow = "";
      this.assetsListTablePagination.current = 1;
      this.getAssetsListTableData();
    },
    handleAssetsListTableChange(pagination){
      const pager = { ...this.assetsListTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.assetsListTablePagination = pager;
      this.getAssetsListTableData();
    },
    goBackPage(){
      this.$router.push({path:'/backstage/assetManagement/inventory'});
    },
    getAssetsListTableData(){
      let that = this;
      this.pageLoading = true;
      let param = "pageNumber="+this.assetsListTablePagination.current+"&pageSize="+this.assetsListTablePagination.pageSize;
      if(this.searchType==="advanced"){
        this.$post("/advanceSearch/orderCheck/"+this.$route.query.orderCheckId+"?"+param,this.advancedSearchData,{})
        .then(function(res){
          that.assetsListTableSelectedRowKeys = [];
          that.assetsListTableSelectedData = [];
          that.assetsListTableData = res.data.records;
          const pager = { ...that.assetsListTablePagination };
          pager.total = res.data.total;
          that.assetsListTablePagination = pager;
          that.pageLoading = false;
        })
      }else{
        if(this.normalSearchData!=null){ param=param+"&searchData="+this.normalSearchData; }
        this.$get("/orderCheck/"+this.$route.query.orderCheckId+"?"+param,{})
        .then(function(res){
          that.assetsListTableSelectedRowKeys = [];
          that.assetsListTableSelectedData = [];
          that.assetsListTableData = res.data.records;
          const pager = { ...that.assetsListTablePagination };
          pager.total = res.data.total;
          that.assetsListTablePagination = pager;
          that.pageLoading = false;
        })
      }
    },
    exportInventoryDetail(type){
      let param = [];
      if(type==='selected'){
        if(this.assetsListTableSelectedRowKeys.length===0){ this.$message.warning("请至少选择一项资产");return; }
        param = this.assetsListTableSelectedRowKeys;
      }
      let that = this;
      this.$message.loading({content:"正在导出，请耐心等待",key:"exportInventoryDetail",duration:0});
      this.$postFile("/orderCheck/"+this.$route.query.orderCheckId+"/export/excel",param)
      .then(function(res){
        if(!res) return;
        that.$message.success({content:"导出完成",key:"exportInventoryDetail",duration:2});
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' }); // 构造一个blob对象来处理数据，并设置文件类型
        if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
          navigator.msSaveBlob(blob, "盘点资产列表.xls")
        } else {
          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = "盘点资产列表.xls" //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        }
      })
      .catch(function(){
        that.$message.error({content:"导出失败",key:"exportInventoryDetail",duration:2});
      })
    },
    InventoryDone(){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "盘点完成操作",
        content: "未盘资产归为盘亏，盘亏资产将进入已处置状态，确定完成盘点？",
        onOk(){
          that.pageLoading = true;
          that.$put("/orderCheck/"+that.$route.query.orderCheckId+"/finish")
          .then(function(){
            that.pageLoading=false;
            that.$message.success("盘点完成成功");
            that.stopAssetsAdvanceSearch();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    callInventoryProcessDialog(){
      this.InventoryProgressDialogConfig = { visible:true,inventoryId:this.$route.query.orderCheckId };
    },
    goInventorySurplus(){
      this.$router.push({path:'/backstage/assetManagement/inventory/detail/surplus',query: {orderCheckId:this.$route.query.orderCheckId}})
    }
  }
}
</script>

<style lang="less" scoped>
.inventorydetail_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.inventorydetail_header button+button{
  margin-right: 5px;
}
.inventorydetail_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
.inventorydetail_button_group{
  margin-left: 5px;
}
.inventorydetail_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
.inventorydetail_table.advanced{
  height: calc(100% - 116px);
}
.inventorydetail_tags{
  width: 100%;
  height: 44px;
  float: left;
  overflow-x: scroll;
  padding: 0 0 5px 20px;
}
.inventorydetail_tags span+span{
  margin-left: 5px;
}
.inventorydetail_tags span{
  display: inline !important;
}
.inventorydetail_tags span:last-child{
  margin-right: 20px;
}
</style>