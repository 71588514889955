<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="签字内容" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="540" 
    :closable="true"
  >
    <template slot="footer">
      <a-button @click="handleCancel">关闭</a-button>
    </template>
    <div class="signdetaildialog_block">
      <a-spin :spinning="dialogLoading" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <div class="signdetaildialog_sign">
          <img :src="imgSrc" v-if="imgSrc"/>
        </div>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object
  },
  data(){
    return{
      dialogLoading: false,
      imgSrc: null
    }
  },
  watch: {
    'dialogConfig.visible': {
      handler(val){ if(val){ this.downloadSignImg(); } },
    }
  },
  methods: {
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    downloadSignImg(){
      this.dialogLoading = true;
      let that = this;
      this.$getImage("/document/"+this.dialogConfig.fileId)
      .then(function(res){
        that.imgSrc = 'data:image/png;base64,' + btoa( new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '') );
        that.$forceUpdate();
        that.dialogLoading = false;
      })
    }
  }
}
</script>

<style lang="less" scoped>
.signdetaildialog_block{
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
}
.signdetaildialog_sign{
  width: 500px;
  min-height: 180px;
}
.signdetaildialog_sign img{
  width: 500px;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
/deep/ .ant-divider-horizontal{
  margin: 8px 0;
}
</style>