<template>
  <div class="default_main">
    <a-spin :spinning="pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="approvalswitch_table">
        <a-table 
          :columns="approvalSettingTableColumns" 
          :data-source="approvalSettingTableData" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="approvalSettingTablePagination" 
          @change="handleApprovalSettingTableChange"
        >
          <template slot="actions" slot-scope="text,record">
            <font class="table_action" v-if="record.active=='0'" @click="approvalSettleChange(record)">开启</font>
            <font class="table_action disabled" v-if="record.active=='1'">开启</font>
            <font class="table_action" v-if="record.active=='1'" @click="approvalSettleChange(record)">关闭</font>
            <font class="table_action disabled" v-if="record.active=='0'">关闭</font>
          </template>
          <template slot="action" slot-scope="action">
            {{action=='receive_confirming'?'领用审批':action=='back_confirming'?'退库审批':action=='dispose_confirming'?'处置审批':action=='transfer_confirming'?'转移审批':''}}
          </template>
          <template slot="explain" slot-scope="text,record">
            {{record.action=='receive_confirming'?'开启时，派发操作需要领用人对应审批人进行审批；关闭时，派发操作无需审批':
                record.action=='back_confirming'?'开启时，退库操作需要退库人对应审批人进行审批；关闭时，退库操作无需审批':
                record.action=='dispose_confirming'?'开启时，处置操作需要领用人对应审批人进行审批；关闭时，处置操作无需审批':
                record.action=='transfer_confirming'?'开启时，转移操作需要接收人对应审批人进行审批；关闭时，转移操作无需审批':''}}
          </template>
          <template slot="status" slot-scope="text,record">
            <a-tag :color="record.active=='1'? '#87D068':''">
              {{ record.active=='1'? "开启":"关闭" }}
            </a-tag>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  data(){
    return {
      pageLoading: false,
      approvalSettingTableData: [],
      approvalSettingTableColumns: [
        {title: '审批环节',dataIndex: 'action',key: 'action',align: 'center',width: 80,ellipsis: true,scopedSlots: { customRender: 'action' }},
        {title: '是否开启审批',dataIndex: 'active',key: 'active',align: 'center',width: 80,ellipsis: true,scopedSlots: { customRender: 'status' }},
        {title: '说明',dataIndex: 'parentName',key: 'parentName',align: 'center',width: 500,ellipsis: true,scopedSlots: { customRender: 'explain' }},
        {title: '操作',key: 'actions',align: 'center',width: 80,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      approvalSettingTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`}
    }
  },
  mounted(){
    this.getApprovalSettingData();
  },
  methods: {
    handleApprovalSettingTableChange(pagination){
      const pager = { ...this.approvalSettingTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.approvalSettingTablePagination = pager;
      this.getApprovalSettingData();
    },
    getApprovalSettingData(){
      this.pageLoading = true;
      let that = this;
      this.$get("/settingApprovals",{})
      .then(function(res){
        that.approvalSettingTableData = res.data;
        that.pageLoading = false;
      })
    },
    approvalSettleChange(item){
      this.pageLoading = true;
      let active = item.active=='1'? '0':'1';
      let that = this;
      this.$put("/settingApproval/"+item.id+"/"+active,{})
      .then(function(){
        that.getApprovalSettingData();
      })
    }
  }
}
</script>

<style lang="less" scoped>
.approvalswitch_table{
  width: 100%;
  height: 100%;
  float: left;
}
</style>