<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="盘点内容" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="550" 
    :closable="true"
  >
    <template slot="footer">
      <a-button @click="handleCancel">关闭</a-button>
    </template>
    <div class="inventorydetaildialog_block">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="24" class="inventoryadddialog_title">基本信息</a-col>
          <a-col :span="24"><a-form-item v-bind="formItemLayout" label="盘点名称">{{dialogConfig.inventoryDetail.name}}</a-form-item></a-col>
          <a-col :span="24"><a-form-item v-bind="formItemLayout" label="计划起始日期">{{dialogConfig.inventoryDetail.startTime&&dialogConfig.inventoryDetail.endTime? $moment(dialogConfig.inventoryDetail.startTime).format('YYYY-MM-DD')+" 至 "+$moment(dialogConfig.inventoryDetail.endTime).format('YYYY-MM-DD'):""}}</a-form-item></a-col>
        </a-row>
        <a-divider dashed/>
        <a-row :gutter="24">
          <a-col :span="24" class="inventoryadddialog_title">盘点范围</a-col>
          <a-col :span="24">
            <a-form-item v-bind="formItemLayout" label="资产分类">
              <a-tag v-for="item in dialogConfig.inventoryDetail.categoryRange" :key="item.id">{{item.name}}</a-tag>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item v-bind="formItemLayout" label="资产位置">
              <a-tag v-for="item in dialogConfig.inventoryDetail.locationRange" :key="item.id">{{item.name}}</a-tag>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object,
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 6 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 18 } },
      }
    }
  },
  methods: {
    handleCancel(){
      this.dialogConfig.visible = false;
    }
  }
}
</script>

<style lang="less" scoped>
.inventorydetaildialog_block{
  width: 100%;
  height: auto;
  max-height: 500px;
  padding: 30px 50px 10px 30px;
  overflow-x: hidden;
  overflow-y: auto;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
// /deep/ .ant-col{
//   height: 40px;
//   line-height: 40px;
// }
/deep/ .ant-form-item-control{
  line-height: 20px;
  padding-top: 10px;
}
/deep/ .ant-form-item{
  margin-bottom: 0;
  padding-bottom: 10px;
}
/deep/ .ant-form-item:hover{
  background-color: rgba(27, 42, 78, 0.05);
}
/deep/ .ant-divider-horizontal{
  margin: 8px 0;
}
</style>