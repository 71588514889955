<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="添加租户" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="500" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">保存</a-button>
    </template>
    <div class="tenanteditdialog_block">
      <a-spin :spinning="dialogConfig.requesting" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <a-form :form="form" style="padding: 30px 0px 5px 30px">
          <a-row :gutter="24">
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="租户名称">
                <a-input placeholder="请输入租户名称" v-decorator="['tenantName',{initialValue:dialogConfig.tenant.tenantName,rules:[{required:true,message:'请输入租户名称'},{ max: 20, message: '最多填写20个字符' }]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="租户手机">
                <a-input placeholder="请输入租户手机" v-decorator="['phoneNumber',{initialValue:dialogConfig.tenant.phoneNumber,rules:[{ required: true, message: '请输入租户手机' },{ pattern: /^[1][0-9][0-9]{9}$/, message: '请输入正确的手机号码' }]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="租户密码">
                <a-input placeholder="请输入租户密码" type="password" v-decorator="['password',{initialValue:'aaa123+-*/',rules:[{required:true,message:'请输入租户密码'},{validator:checkPassword}]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="确认密码">
                <a-input placeholder="请输入租户密码" type="password" v-decorator="['passwordConfirm',{initialValue:'aaa123+-*/',rules:[{required:true,message:'请输入租户密码'},{validator:checkPassword}]}]"/>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="套餐">
                <a-select  v-decorator="['scheme',{initialValue:dialogConfig.tenant.scheme,rules: [{ required: true, message: '请选择租户所选套餐' }] }]" placeholder="请选择租户所选套餐" @change="handleSchemeChange">
                  <a-select-option v-for="scheme in schemes" :key="scheme">
                    {{ schemeLabel[scheme] }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="最大资产数">
                <a-input :disabled="dialogConfig.tenant.scheme=='c'" v-decorator="['assetMaxCount',{ initialValue:dialogConfig.tenant.assetMaxCount+'',rules: [{ required: true, message: '请输入最大资产数' },{validator:confirmSchemeMaxCount}] }]" placeholder="请输入最大资产数"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="所属省份">
                <a-select  v-decorator="['province',{initialValue:dialogConfig.tenant.province, rules: [{ required: true, message: '请选择租户所属省份' }] }]" placeholder="请选择租户所属省份" @change="handleProvinceChange">
                  <a-select-option v-for="province in provinceData" :key="province">
                    {{ province }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="所属城市">
                <a-select  v-decorator="['city',{initialValue:dialogConfig.tenant.city, rules: [{ required: true, message: '请选择租户所属城市' }] }]" placeholder="请选择租户所属城市" @change="handleCityChange">
                  <a-select-option v-for="city in cities" :key="city">
                    {{ city }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item v-bind="formItemSingleLayout" label="所属区域">
                <a-select  v-decorator="['district',{initialValue:dialogConfig.tenant.district, rules: [{ required: true, message: '请选择租户所属区域' }] }]" placeholder="请选择租户所属区域">
                  <a-select-option v-for="district in districts" :key="district">
                    {{ district }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
import {provinceData,cityData,districtData} from "../utils/china";
export default {
  props: {
    dialogConfig: Object
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 5 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 16 } },
      },
      provinceData,
      cityData,
      districtData,
      cities: cityData[provinceData[0]],
      districts: districtData[cityData[provinceData[0]][0]],
      scheme: 'a',
      schemes: ['a','b','c'],
      schemeLabel: {'a':'套餐A','b':"套餐B",'c':'套餐C'},
      schemeMaxCount: {'a':'2000','b':'5000',c:'无限制'},
    }
  },
  methods: {
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        let fix = {...values};
        if(values.assetMaxCount=="无限制"){ fix.assetMaxCount=999999999; }
        fix.id = this.dialogConfig.tenant.id;
        this.$emit('submit',fix);
      })
    },
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    handleProvinceChange(value){
      this.form.setFieldsValue({
        city: cityData[value][0],district: districtData[cityData[value][0]][0]
      });
      this.cities = cityData[value];
      this.districts = districtData[cityData[value][0]];
    },
    handleCityChange(value){
      this.form.setFieldsValue({
        district: districtData[value][0]
      });
      this.districts = districtData[value];
    },
    handleSchemeChange(value){
      this.dialogConfig.tenant.scheme = value;
      this.form.setFieldsValue({ assetMaxCount: this.schemeMaxCount[value] });
    },
    confirmSchemeMaxCount(rule, value, callback){
      var type="^[0-9]*[1-9][0-9]*$"; 
      var re = new RegExp(type); 
      if(value.match(re)==null&&value!='无限制'&&value!="") {
        callback('请输入大于零的正整数！')
      }else{
        callback()
      }
    },
    checkPassword(rule, value, callback){
      var p1 = this.form.getFieldValue("password");
      var p2 = this.form.getFieldValue("passwordConfirm");
      if(p1==""||p2==""||p1==null||p2==null){
        callback()
      }else if(p1!=p2){
        callback("两次输入的密码不一致")
        this.form.validateFields(['password','passwordConfirm']);
      }else{
        callback();
        this.form.validateFields(['password','passwordConfirm']);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.tenanteditdialog_block{
  width: 100%;
  height: auto;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
</style>