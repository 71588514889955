<template>
  <div class="default_main">
    <a-spin :spinning="pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="cancelmanagement_header">
        <a-input-search class="default_searchinput" placeholder="请输入退库单号或申请人" enter-button v-model="searchDataShow" @search="onSearch"/>
        <a-dropdown>
          <a-button class="f_right">导出退库单据</a-button>
          <a-menu slot="overlay">
            <a-menu-item @click="exportCancels('all')">导出全部单据</a-menu-item>
            <a-menu-item @click="exportCancels('selected')">导出选中单据</a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div class="cancelmanagement_table">
        <a-table 
          :columns="cancelListTableColumns" 
          :data-source="cancelListTableData" 
          :row-selection="{ selectedRowKeys: cancelListTableSelectedRowKeys,onChange: cancelListTableOnSelectChange }" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="cancelListTablePagination" 
          @change="handleCancelListTableChange"
        >
          <template slot="actions" slot-scope="text,record">
            <font class="table_action"  v-if="record.status=='back_confirming'||record.status=='approval'" @click="stopCancel(record)">终止</font>
            <font class="table_action disabled" v-if="record.status!='back_confirming'&&record.status!='approval'">终止</font>
            <font class="table_action" v-if="record.status=='terminated'||record.status=='rejected'" @click="deleteCancel(record)">删除</font>
            <font class="table_action disabled" v-if="record.status!='terminated'&&record.status!='rejected'">删除</font>
            <font class="table_action" v-if="record.signId" @click="callSignDetailDialog(record)">签字</font>
            <font class="table_action disabled" v-if="!record.signId">签字</font>
          </template>
          <template slot="status" slot-scope="status">
            <a-tag :color="status==='back_end'? '#108EE9':status==='terminated'||status==='rejected'? '':'#87d068'">
              {{ status==='approval'?'审批中':status==='back_confirming'?'进行中':status==='back_end'?'已完成':status==='terminated'?'已终止':status==='rejected'?'已驳回':status}} 
            </a-tag>
          </template>
          <template slot="cancelCode" slot-scope="text,record">
            <font class="table_action" @click="callCancelDetailDialog(record)">{{text}}</font>
          </template>
        </a-table>
      </div>
    </a-spin>
    <CancelDetailDialog :dialogConfig="CancelDetailDialogConfig" :assetsColumns="[...cancelAssetsColumns]"/>
    <SignDetailDialog :dialogConfig="SignDetailDialogConfig"/>
  </div>
</template>

<script>
import tableColumns from "../../utils/tableColumns";
import CancelDetailDialog from "../../components/CancelDetailDialog.vue";
import SignDetailDialog from "../../components/SignDetailDialog.vue";
export default {
  components: {
    CancelDetailDialog,
    SignDetailDialog
  },
  data(){
    return {
      pageLoading: false,
      searchDataShow: "",
      searchData: "",
      cancelListTableColumns: [
        {title: '单据状态',dataIndex: 'status',key: 'status',align: 'center',width: 120,scopedSlots: { customRender: 'status' }},
        {title: '退库单号',dataIndex: 'orderCancelCode',key: 'orderCancelCode',align: 'center',ellipsis: true,width: 200,scopedSlots: { customRender: 'cancelCode' }},
        {title: '申请人',dataIndex: 'applyUserName',key: 'applyUserName',align: 'center',width: 120,ellipsis: true},
        {title: '退库后位置',dataIndex: 'locationName',key: 'locationName',align: 'center',ellipsis: true,width: 200},   
        {title: '处理人',dataIndex: 'handleUserName',key: 'handleUserName',align: 'center',width: 120,ellipsis: true},
        {title: '审批人',dataIndex: 'approvalUserName',key: 'approvalUserName',align: 'center',width: 120,ellipsis: true},
        {title: '审批结果',dataIndex: 'approvalAction',key: 'approvalAction',align: 'center',width: 120,customRender: (val) => {return val=='approval_agree' ? '同意':val=='approval_reject'? '驳回':''}},
        {title: '审批意见',dataIndex: 'approvalNotes',key: 'approvalNotes',align: 'center',ellipsis: true,width: 200},
        {title: '创建日期',dataIndex: 'dateEntered',key: 'dateEntered',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '完成日期',dataIndex: 'dateProcessed',key: 'dateProcessed',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '操作',key: 'actions',align: 'center',width: 130,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      cancelListTableData: [],
      cancelListTableSelectedRowKeys: [],
      cancelListTablePagination:{ current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      CancelDetailDialogConfig: { visible:false,cancelDetail:{} },
      cancelAssetsColumns: [...tableColumns.assetsListTableColumns],
      SignDetailDialogConfig: { visible:false,fileId:null }
    }
  },
  mounted(){
    this.getCancelListTableData();
    this.getExtentionData();
  },
  methods: {
    onSearch(value){
      this.searchData = value;
      this.cancelListTablePagination.current = 1;
      this.getCancelListTableData();
    },
    cancelListTableOnSelectChange(selectedRowKeys){
      this.cancelListTableSelectedRowKeys = selectedRowKeys;
    },
    callCancelDetailDialog(record){
      this.CancelDetailDialogConfig = { visible:true,cancelDetail:record };
    },
    callSignDetailDialog(record){
      this.SignDetailDialogConfig = { visible:true,fileId:record.signId };
    },
    getCancelListTableData(){
      this.pageLoading = true;
      let that = this;
      this.$get("/orderCancels?pageNumber="+this.cancelListTablePagination.current+"&pageSize="+this.cancelListTablePagination.pageSize+"&searchData="+this.searchData,{})
      .then(function(res){
        that.pageLoading = false;
        that.cancelListTableSelectedRowKeys = [];
        that.cancelListTableData = res.data.records;
        const pager = { ...that.cancelListTablePagination };
        pager.total = res.data.total;
        that.cancelListTablePagination = pager;
      })
    },
    //加载扩展列信息
    getExtentionData(){
      let that = this;
      this.$get("/categoryExtendFields?pageNumber=1&pageSize=999999&searchData=",{})
      .then(function(res){
        res.data.records.forEach(item => {
          that.cancelAssetsColumns.push({ title:item.labelName,dataIndex:item.fieldLabel,key:item.fieldLabel,align:'center',ellipsis: true,width: 120 });
        })
      })
    },
    handleCancelListTableChange(pagination){
      const pager = { ...this.cancelListTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.cancelListTablePagination = pager;
      this.getCancelListTableData();
    },
    stopCancel(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "终止操作",
        content: "确定终止【"+record.orderCancelCode+"】退库单吗?",
        onOk(){
          that.pageLoading = true;
          that.$put("/orderCancel/"+record.id+"/terminate",{})
          .then(function(){
            that.pageLoading=false;
            that.$message.success("终止成功");
            that.getCancelListTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    deleteCancel(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除【"+record.orderCancelCode+"】退库单吗?",
        onOk(){
          that.pageLoading = true;
          that.$delete("/orderCancel/"+record.id,{})
          .then(function(){
            that.pageLoading=false;
            that.$message.success("删除成功");
            if(that.cancelListTableData.length==1&&that.cancelListTablePagination.current>1){ that.cancelListTablePagination.current--; }
            that.getCancelListTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    exportCancels(type){
      let selected = [];
      if(type==='selected'){ 
        if(this.cancelListTableSelectedRowKeys.length===0){ this.$message.warning("请至少选择一个单据");return; }
        selected = this.cancelListTableSelectedRowKeys;
      }
      let that = this;
      this.$message.loading({content:"正在导出，请耐心等待",key:"exportCancels",duration:0});
      this.$postFile("/orderCancel/export/excel",selected)
      .then(function(res){
        if(!res) return;
        that.$message.success({content:"导出完成",key:"exportCancels",duration:2});
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' }) // 构造一个blob对象来处理数据，并设置文件类型
        if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
            navigator.msSaveBlob(blob, "退库单列表.xls")
        } else {
          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = "退库单列表.xls" //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        }
      })
      .catch(function(){
        that.$message.error({content:"导出失败",key:"exportCancels",duration:2});
      })
    }
  }
}
</script>

<style lang="less" scoped>
.cancelmanagement_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.cancelmanagement_header button+button{
  margin-right: 5px;
}
.cancelmanagement_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
</style>