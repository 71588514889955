<template>
  <div class="default_main">
    <a-spin :spinning="pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="transfermanagement_header">
        <a-input-search class="default_searchinput" placeholder="请输入转移单号或领用人或接收人" enter-button v-model="searchDataShow" @search="onSearch"/>
        <a-dropdown>
          <a-button class="f_right">导出转移单据</a-button>
          <a-menu slot="overlay">
            <a-menu-item @click="exportTransfers('all')">导出全部单据</a-menu-item>
            <a-menu-item @click="exportTransfers('selected')">导出选中单据</a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-button class="f_right" type="primary" @click="TransferAssets">开始转移</a-button>
      </div>
      <div class="transfermanagement_table">
        <a-table 
          :columns="transferListTableColumns" 
          :data-source="transferListTableData" 
          :row-selection="{ selectedRowKeys: transferListTableSelectedRowKeys,onChange: transferListTableOnSelectChange }" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="transferListTablePagination" 
          @change="handleTransferListTableChange"
        >
          <template slot="actions" slot-scope="text,record">
            <font class="table_action"  v-if="record.status=='transfer_confirming'||record.status=='approval'" @click="stopTransfer(record)">终止</font>
            <font class="table_action disabled" v-if="record.status!='transfer_confirming'&&record.status!='approval'">终止</font>
            <font class="table_action" v-if="record.status=='terminated'||record.status=='rejected'" @click="deleteTransfer(record)">删除</font>
            <font class="table_action disabled" v-if="record.status!='terminated'&&record.status!='rejected'">删除</font>
            <font class="table_action" v-if="record.signId" @click="callSignDetailDialog(record)">签字</font>
            <font class="table_action disabled" v-if="!record.signId">签字</font>
          </template>
          <template slot="status" slot-scope="status">
            <a-tag :color="status==='transfer_end'? '#108EE9':status==='terminated'||status==='rejected'? '':'#87d068'">
              {{ status==='approval'?'审批中':status==='transfer_confirming'?'进行中':status==='transfer_end'?'已完成':status==='terminated'?'已终止':status==='rejected'?'已驳回':status}} 
            </a-tag>
          </template>
          <template slot="transferCode" slot-scope="text,record">
            <font class="table_action" @click="callTransferDetailDialog(record)">{{text}}</font>
          </template>
        </a-table>
      </div>
    </a-spin>
    <TransferAssetsDialog :dialogConfig="TransferAssetsDialogConfig" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :employeeData="employeeData" :assetsColumns="[...transferAssetsColumns]" @getEmployeeData="getEmployeeData" @submit="handleTransferSubmit"/>
    <TransferDetailDialog :dialogConfig="TransferDetailDialogConfig" :assetsColumns="[...transferAssetsColumns]"/>
    <SignDetailDialog :dialogConfig="SignDetailDialogConfig"/>
  </div>
</template>

<script>
import tableColumns from "../../utils/tableColumns";
import TransferAssetsDialog from "../../components/TransferAssetsDialog.vue";
import TransferDetailDialog from "../../components/TransferDetailDialog.vue";
import SignDetailDialog from "../../components/SignDetailDialog.vue";
export default {
  components: {
    TransferAssetsDialog,
    TransferDetailDialog,
    SignDetailDialog
  },
  data(){
    return {
      pageLoading: false,
      searchDataShow: "",
      searchData: "",
      transferListTableColumns: [
        {title: '单据状态',dataIndex: 'status',key: 'status',align: 'center',width: 120,scopedSlots: { customRender: 'status' }},
        {title: '转移单号',dataIndex: 'orderTransferCode',key: 'orderTransferCode',align: 'center',ellipsis: true,width: 200,scopedSlots: { customRender: 'transferCode' }},
        {title: '领用人',dataIndex: 'originUserName',key: 'originUserName',align: 'center',width: 120,ellipsis: true},
        {title: '接收人',dataIndex: 'receivedUserName',key: 'receivedUserName',align: 'center',width: 120,ellipsis: true},
        {title: '转移后位置',dataIndex: 'receivedLocationName',key: 'receivedLocationName',align: 'center',ellipsis: true,width: 200},
        {title: '审批人',dataIndex: 'approvalUserName',key: 'approvalUserName',align: 'center',width: 120,ellipsis: true},
        {title: '审批结果',dataIndex: 'approvalAction',key: 'approvalAction',align: 'center',width: 120,customRender: (val) => {return val=='approval_agree' ? '同意':val=='approval_reject'? '驳回':''}},
        {title: '审批意见',dataIndex: 'approvalNotes',key: 'approvalNotes',align: 'center',ellipsis: true,width: 200},
        {title: '备注',dataIndex: 'notes',key: 'notes',align: 'center',ellipsis: true,width: 250},
        {title: '创建日期',dataIndex: 'dateEntered',key: 'dateEntered',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '完成日期',dataIndex: 'dateProcessed',key: 'dateProcessed',align: 'center',width: 120,customRender: (val) => {return val ? this.$moment(val).format('YYYY-MM-DD') : ''}},
        {title: '操作',key: 'actions',align: 'center',width: 130,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      transferListTableData: [],
      transferListTableSelectedRowKeys: [],
      transferListTablePagination:{ current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      TransferAssetsDialogConfig: { visible:false,requesting:false,assets:[],action:"transferManagement",transferUser:{} },
      assetsTypeData: null,
      assetsLocationData: null,
      employeeData: null,
      transferAssetsColumns: [...tableColumns.assetsListTableColumns],
      TransferDetailDialogConfig: { visible:false,transferDetail:{} },
      SignDetailDialogConfig: { visible:false,fileId:null }
    }
  },
  mounted(){
    this.getTransferListTableData();
    this.getExtentionData();
    this.getAssetsTypeTreeData();
    this.getAssetsLocationTreeData();
    this.getEmployeeData();
  },
  methods: {
    //发起转移
    TransferAssets(){
      this.TransferAssetsDialogConfig = { visible:true,requesting:false,assets:[],action:"transferManagement",transferUser:{} };
    },
    handleTransferSubmit(values){
      this.TransferAssetsDialogConfig.requesting = true;
      let that = this;
      this.$post("/orderTransfer",values)
      .then(function(){
        that.TransferAssetsDialogConfig.visible = false;
        that.TransferAssetsDialogConfig.requesting = false;
        that.$message.success("保存成功");
        that.getTransferListTableData();
      })
      .catch(function(){
        that.TransferAssetsDialogConfig.requesting = false;
      })
    },
    callTransferDetailDialog(record){
      this.TransferDetailDialogConfig = { visible:true,transferDetail:record };
    },
    callSignDetailDialog(record){
      this.SignDetailDialogConfig = { visible:true,fileId:record.signId };
    },
    //获取资产分类数据
    getAssetsTypeTreeData(){
      let that = this;
      this.$get("/categoryStructure",{})
      .then(function(res){
        that.assetsTypeData = res.data;
      })
    },
    //获取资产位置数据
    getAssetsLocationTreeData(){
      let that = this;
      this.$get("/locationStructure",{})
      .then(function(res){
        that.assetsLocationData = res.data;
      })
    },
    //加载员工数据
    getEmployeeData(value){
      let that = this;
      let param = "pageNumber=1&pageSize=50&active=true";
      if(value!=null){ param=param+"&searchData="+value; }
      this.$get("/userEmployees?"+param,{})
      .then(function(res){ 
        that.employeeData = res.data.records;
      })
    },
    //加载扩展列信息
    getExtentionData(){
      let that = this;
      this.$get("/categoryExtendFields?pageNumber=1&pageSize=999999&searchData=",{})
      .then(function(res){
        res.data.records.forEach(item => {
          that.transferAssetsColumns.push({ title:item.labelName,dataIndex:item.fieldLabel,key:item.fieldLabel,align:'center',ellipsis: true,width: 120 });
        })
      })
    },
    onSearch(value){
      this.searchData = value;
      this.transferListTablePagination.current = 1;
      this.getTransferListTableData();
    },
    transferListTableOnSelectChange(selectedRowKeys){
      this.transferListTableSelectedRowKeys = selectedRowKeys;
    },
    getTransferListTableData(){
      this.pageLoading = true;
      let that = this;
      this.$get("/orderTransfers?pageNumber="+this.transferListTablePagination.current+"&pageSize="+this.transferListTablePagination.pageSize+"&searchData="+this.searchData,{})
      .then(function(res){
        that.pageLoading = false;
        that.transferListTableSelectedRowKeys = [];
        that.transferListTableData = res.data.records;
        const pager = { ...that.transferListTablePagination };
        pager.total = res.data.total;
        that.transferListTablePagination = pager;
      })
    },
    handleTransferListTableChange(pagination){
      const pager = { ...this.transferListTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.transferListTablePagination = pager;
      this.getTransferListTableData();
    },
    exportTransfers(type){
      let selected = [];
      if(type==='selected'){ 
        if(this.transferListTableSelectedRowKeys.length===0){ this.$message.warning("请至少选择一个单据");return; }
        selected = this.transferListTableSelectedRowKeys;
      }
      let that = this;
      this.$message.loading({content:"正在导出，请耐心等待",key:"exportTransfers",duration:0});
      this.$postFile("/orderTransfer/export/excel",selected)
      .then(function(res){
        if(!res) return;
        that.$message.success({content:"导出完成",key:"exportTransfers",duration:2});
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' }) // 构造一个blob对象来处理数据，并设置文件类型
        if (window.navigator.msSaveOrOpenBlob) { //兼容IE10
            navigator.msSaveBlob(blob, "转移单列表.xls")
        } else {
          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = "转移单列表.xls" //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        }
      })
      .catch(function(){
        that.$message.error({content:"导出失败",key:"exportTransfers",duration:2});
      })
    },
    stopTransfer(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "终止操作",
        content: "确定终止【"+record.orderTransferCode+"】转移单吗?",
        onOk(){
          that.pageLoading = true;
          that.$put("/orderTransfer/"+record.id+"/terminate",{})
          .then(function(){
            that.pageLoading=false;
            that.$message.success("终止成功");
            that.getTransferListTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    deleteTransfer(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除【"+record.orderTransferCode+"】转移单吗?",
        onOk(){
          that.pageLoading = true;
          that.$delete("/orderTransfer/"+record.id,{})
          .then(function(){
            that.pageLoading=false;
            that.$message.success("删除成功");
            if(that.transferListTableData.length==1&&that.transferListTablePagination.current>1){ that.transferListTablePagination.current--; }
            that.getTransferListTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    }
  }
}
</script>

<style lang="less" scoped>
.transfermanagement_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.transfermanagement_header button+button{
  margin-right: 5px;
}
.transfermanagement_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
</style>