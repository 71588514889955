<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="派发资产" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="1050" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">派发</a-button>
    </template>
    <div class="distributionassetsdialog_block">
      <a-spin :spinning="assetsLocationData==null||employeeData==null||assetsTypeData==null||dialogConfig.requesting" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <a-form :form="form">
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-item v-bind="formItemLayout" label="领用人">
                <a-select 
                  v-if="dialogConfig.action!='applyList'" 
                  v-decorator="['receivedUserId',{ rules: [{ required: true, message: '请选择领用人' }]}]"
                  show-search
                  :filterOption="false"
                  @search="handleEmployeeSearch"
                  placeholder="请输入姓名搜索"
                >
                  <a-select-option v-for="item in employeeData" :value="item.id" :key="item.id">{{ item.name }}</a-select-option>
                </a-select>
                <font v-if="dialogConfig.action=='applyList'">{{dialogConfig.apply.applyUserName}}</font>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item v-bind="formItemLayout" label="领用后位置">
                <a-tree-select 
                  :tree-data="assetsLocationData"  
                  placeholder="请选择领用后资产位置"
                  show-search 
                  treeNodeFilterProp='title'
                  tree-default-expand-all 
                  v-decorator="['locationId',{ rules: [{ required: true, message: '请选择领用后资产位置' }] }]" 
                  :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :span="24"><a-form-item style="margin-left:-8px" v-bind="formItemSingleLayout" label="备注"><a-textarea v-decorator="['notes',{rules:[{ max: 200, message: '最多填写200个字符' }]}]" placeholder="请输入备注"/></a-form-item></a-col>
          </a-row>
        </a-form>
        <a-divider dashed/>
        <div class="distributionassetsdialog_table">
          <a-button-group class="distributionassetsdialog_btn">
            <a-button type="primary" @click="callAssetsSelect">添加派发资产</a-button>
            <a-button @click="removeAssets">移除</a-button>
          </a-button-group>
          <a-table 
            :columns="assetsColumns" 
            :data-source="dialogConfig.assets" 
            :row-selection="{ selectedRowKeys: assetsSelectedRowKeys,onChange: assetsOnSelectChange }" 
            :scroll="{ x: '100%',y: true}" 
            rowKey="id" 
            :pagination="false" 
          >
            <template slot="disposeType" slot-scope="disposeType">
                {{disposeType==='allocation'? '调拨':disposeType==='inventory_loss'? '盘亏':disposeType==='donation'? '捐赠':disposeType==='scrap'? '报废':disposeType==='broken'? '报损':disposeType==='loss'? '报失':disposeType==='others'?'其他':''}}
            </template>
            <template slot="status" slot-scope="status">
                <a-tag :color="status==='leisure'? '#D46B08':status==='dispose_end'? '':status==='receive_end'? '#108EE9':'#87D068'">
                {{ status==='leisure'?'空闲':status==='receive_approval'?'领用审批中':status==='receive_confirming'?'待领用':
                    status==='receive_sign_confirming'?'领用待签字':status==='receive_end'?'已领用':status==='loan'?'借出':status==='cancel_approval'?'退库审批中':
                    status==='dispose'?'处置':status==='cancel_confirming'?'待退库':status==='cancel_sign_confirming'?'退库待签字':
                    status==='transfer_confirming'?'待转移':status==='transfer_sign_confirming'?'转移待签字':
                    status==='dispose_confirming'?'待处置':status==='dispose_sign_confirming'?'处置待签字':
                    status==='dispose_end'?'已处置':status}}
                </a-tag>
            </template>
            <template slot="usageCase" slot-scope="usageCase">
                {{ usageCase==='normal'?'正常':usageCase==='fault'?'故障':'' }} 
            </template>
            <template slot="warehousedType" slot-scope="warehousedType">     
                {{ warehousedType==='purchase'?'采购':warehousedType==='allocation'?'调拨':warehousedType==='inventory_win'?'盘赢':warehousedType==='donation'?'捐赠':warehousedType==='others'?'其他':warehousedType}} 
            </template>
          </a-table>
        </div>
      </a-spin>
    </div>
    <AssetsSelectDialog :dialogConfig="AssetsSelectDialogConfig" :assetsColumns="assetsColumns" :assetsTypeData="assetsTypeData" :assetsLocationData="assetsLocationData" :employeeData="employeeData" @getEmployeeData="handleEmployeeSearch" @submit="selectAssetsDone"/>
  </a-modal>
</template>

<script>
import AssetsSelectDialog from "./AssetsSelectDialog.vue";
export default {
  props: {
    dialogConfig: Object,
    assetsTypeData: Array,
    assetsLocationData: Array,
    employeeData: Array,
    assetsColumns: Array
  },
  components: {
    AssetsSelectDialog
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 9 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 15 } },
      },
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 3 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 21 } },
      },
      assetsSelectedRowKeys: [],
      AssetsSelectDialogConfig: { visible:false,requesting:false,action:"distribution" }
    }
  },
  methods: {
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        if(this.dialogConfig.assets.length==0){ this.$message.warning("至少选择一项资产进行派发");return; }
        let assetIds = [];
        this.dialogConfig.assets.forEach(item => { assetIds.push(item.id) });
        if(this.dialogConfig.action=="applyList"){ values.receivedUserId = this.dialogConfig.apply.applyUserId; }
        this.$emit('submit',{...values,assetIds});
      })
    },
    handleEmployeeSearch(value){
      this.$emit("getEmployeeData",value);
    },
    assetsOnSelectChange(selectedRowKeys){
      this.assetsSelectedRowKeys = selectedRowKeys;
    },
    removeAssets(){
      this.assetsSelectedRowKeys.forEach(key => {
        let index = this.dialogConfig.assets.findIndex((item) => key === item.id);
        this.dialogConfig.assets.splice(index,1);
      })
      this.assetsSelectedRowKeys = [];
    },
    callAssetsSelect(){
      this.AssetsSelectDialogConfig = { visible:true,requesting:false,action:"distribution" };
    },
    selectAssetsDone(assets){
      let mix = [...this.dialogConfig.assets].concat(assets);
      let fix = [];
      mix.forEach(m => {
        let flag = true; 
        fix.forEach(f => { if(m.id==f.id){ flag = false; } });
        if(flag){ fix.push(m); }
      });
      this.dialogConfig.assets = fix;
      this.AssetsSelectDialogConfig.visible=false;
    }
  }
}
</script>

<style lang="less" scoped>
.distributionassetsdialog_block{
  width: 100%;
  height: auto;
  padding: 30px;
}
.distributionassetsdialog_table{
  width: 100%;
  height: 350px;
  position: relative;
  margin-top: 40px;
}
.distributionassetsdialog_btn{
  position: absolute;
  left: 0;
  top: -32px;
}
/deep/ .ant-spin-nested-loading{
  height: 100%;
}
/deep/ .ant-spin-container{
  height: 100%;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
/deep/ .ant-divider-horizontal{
  margin: 8px 0;
}

/deep/ .ant-table-thead > tr > th{
  padding: 8px 8px;
}
/deep/ .ant-table-tbody > tr > td {
  padding: 6px 8px;
}
/deep/ .ant-table{
  height: 100%;
  font-size: 13px;
}
/deep/ .ant-table-content{
  height: 100%;
}
/deep/ .ant-table-scroll{
  height: 100%;
  overflow-y: hidden;
}
/deep/ .ant-table-body-outer{
  height: calc(100% - 37px);
}
/deep/ .ant-table-fixed-left{
  height: calc(100% - 15px);
}
/deep/ .ant-table-fixed-right{
  height: calc(100% - 15px);
}
/deep/ .ant-table td { 
  white-space: nowrap;
}
/deep/ .ant-table-fixed-left .ant-table-body-outer .ant-table-body-inner{
  overflow-x: hidden;
}
/deep/ .ant-table-fixed-right .ant-table-body-outer .ant-table-body-inner{
  overflow-x: hidden;
}
/deep/ .ant-table-body{
  height: calc(100% - 36px);
  max-height: calc(100% - 36px) ;
  min-height: calc(100% - 36px);
}
/deep/ .ant-table-wrapper{
  height: 100%;
}
/deep/ .ant-table-placeholder {
  position: absolute;
  top: 39px;
  width: 100%;
  height: calc(100% - 26px);
  border: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
</style>