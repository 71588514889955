<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    :title="'【'+dialogConfig.assetDetail.code+'】修改资产信息'" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="950" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">保存</a-button>
    </template>
    <div class="asseteditdialog_block">
      <a-spin :spinning="assetsTypeData==null||assetsLocationData==null||assetsStoreData==null||dialogConfig.requesting" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <div class="asseteditdialog_main_block">
          <a-form :form="form">
            <a-row :gutter="24">
              <a-col :span="24" class="asseteditdialog_title">资产基本信息</a-col>
              <a-col :span="8"><a-form-item v-bind="formItemLayout" label="资产名称"><a-input v-decorator="['name',{initialValue: dialogConfig.assetDetail.name,rules: [{ required: true, message: '请输入资产名称' },{ max: 10, message: '最多填写10个字符' }]}]" placeholder="请输入资产名称"/></a-form-item></a-col>
              <a-col :span="8">
                <a-form-item v-bind="formItemLayout" label="资产分类">
                  <a-tree-select 
                    :tree-data="assetsTypeData"  
                    placeholder="请选择资产分类"
                    show-search 
                    treeNodeFilterProp='title'
                    tree-default-expand-all 
                    v-decorator="['categoryId',{ initialValue: dialogConfig.assetDetail.categoryId,rules: [{ required: true, message: '请选择资产分类' }] }]" 
                    :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                    @select="handleAssetTypeChange"
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item v-bind="formItemLayout" label="资产位置">
                  <a-tree-select 
                    :tree-data="assetsLocationData"  
                    placeholder="请选择资产位置"
                    show-search 
                    treeNodeFilterProp='title'
                    tree-default-expand-all 
                    v-decorator="['locationId',{ initialValue: dialogConfig.assetDetail.locationId,rules: [{ required: true, message: '请选择资产位置' }] }]" 
                    :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item v-bind="formItemLayout" label="入库类型">
                  <a-select v-decorator="['warehousedType',{initialValue: dialogConfig.assetDetail.warehousedType,rules: [{ required: true, message: '请选择入库类型' }]}]" placeholder="请选择入库类型">
                    <a-select-option value="purchase"> 采购</a-select-option>
                    <a-select-option value="allocation"> 调拨</a-select-option>
                    <a-select-option value="inventory_win"> 盘盈</a-select-option>
                    <a-select-option value="donation"> 捐赠</a-select-option>
                    <a-select-option value="others"> 其他</a-select-option>
                  </a-select> 
                </a-form-item>
              </a-col>
              <a-col :span="8"><a-form-item v-bind="formItemLayout" label="入库日期"><a-date-picker v-decorator="['dateWarehoused',{initialValue: dialogConfig.assetDetail.dateWarehoused,rules: [{ required: true, message: '请输入入库日期' }]}]" placeholder="请输入入库日期"/></a-form-item></a-col>
              <a-col :span="8">
                <a-form-item v-bind="formItemLayout" label="所属库房">
                  <a-select v-decorator="['storeLocationId',{initialValue: dialogConfig.assetDetail.storeLocationId,rules: [{ required: true, message: '请选择所属库房' }]}]" placeholder="请选择所属库房">
                    <a-select-option v-for="item in assetsStoreData" :value="item.id" :key="item.id">{{ item.name }}</a-select-option>
                  </a-select> 
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item v-bind="formItemLayout" label="使用状况">
                  <a-select v-decorator="['usageCase',{initialValue: dialogConfig.assetDetail.usageCase,rules: [{ required: true, message: '请选择使用状况' }]}]" placeholder="请选择使用状况">
                    <a-select-option value="normal"> 正常</a-select-option>
                    <a-select-option value="fault"> 故障</a-select-option>
                  </a-select> 
                </a-form-item>
              </a-col>
              <a-col :span="8"><a-form-item v-bind="formItemLayout" label="品牌"><a-input v-decorator="['brand',{initialValue: dialogConfig.assetDetail.brand,rules: [{ max: 20, message: '最多填写20个字符' }]}]" placeholder="请输入品牌"/></a-form-item></a-col>
              <a-col :span="8"><a-form-item v-bind="formItemLayout" label="型号"><a-input v-decorator="['modelNumber',{initialValue: dialogConfig.assetDetail.modelNumber,rules: [{ max: 20, message: '最多填写20个字符' }]}]" placeholder="请输入型号"/></a-form-item></a-col>
              <a-col :span="8"><a-form-item v-bind="formItemLayout" label="金额"><a-input v-decorator="['amount',{initialValue: dialogConfig.assetDetail.amount,rules:[{validator:checkFloatFormat}]}]" placeholder="请输入金额" suffix="元"/></a-form-item></a-col>
              <a-col :span="8"><a-form-item v-bind="formItemLayout" label="使用期限"><a-input v-decorator="['dateEstimated',{initialValue: dialogConfig.assetDetail.dateEstimated,rules:[{validator:checkNumberFormat}]}]" placeholder="请输入使用期限" suffix="月" /></a-form-item></a-col>
              <a-col :span="8"><a-form-item v-bind="formItemLayout" label="计量单位"><a-input v-decorator="['measureUnit',{initialValue: dialogConfig.assetDetail.measureUnit,rules: [{ max: 10, message: '最多填写10个字符' }]}]" placeholder="请输入计量单位"/></a-form-item></a-col>
              <a-col :span="24"><a-form-item style="margin-left:-8px" v-bind="formItemSingleLayout" label="备注"><a-textarea v-decorator="['notes',{initialValue: dialogConfig.assetDetail.notes,rules: [{ max: 200, message: '最多填写200个字符' }]}]" placeholder="请输入备注"/></a-form-item></a-col>
            </a-row>
            <a-divider dashed/>
            <a-row :gutter="24" v-if="extentionDataFit.length>0">
              <a-col :span="24" class="asseteditdialog_title">资产扩展信息</a-col>
              <a-col :span="8" v-for="(item,index) in extentionDataFit" :key="index"><a-form-item v-bind="formItemLayout" :label="item.labelName"><a-input v-decorator="[extentionDataFit[index].fieldLabel,{initialValue: dialogConfig.assetDetail[extentionDataFit[index].fieldLabel],rules: [{ required: extentionDataFit[index].required, message:`请输入${item.labelName}`},{ max: 20, message: '最多填写20个字符' }]}]" :placeholder="'请输入'+item.labelName" /></a-form-item></a-col>
            </a-row>
            <a-divider v-if="extentionDataFit.length>0" dashed/>
            <a-row :gutter="24">
              <a-col :span="24" class="asseteditdialog_title">资产维保信息</a-col>
              <a-col :span="8"><a-form-item v-bind="formItemLayout" label="供应商"><a-input v-decorator="['maintenanceProvider',{initialValue: dialogConfig.assetDetail.maintenanceProvider,rules: [{ max: 20, message: '最多填写20个字符' }]}]" placeholder="请输入供应商"/></a-form-item></a-col>
              <a-col :span="8"><a-form-item v-bind="formItemLayout" label="联系人"><a-input v-decorator="['maintenanceContacts',{initialValue: dialogConfig.assetDetail.maintenanceContacts,rules: [{ max: 20, message: '最多填写20个字符' }]}]" placeholder="请输入联系人"/></a-form-item></a-col>
              <a-col :span="8"><a-form-item v-bind="formItemLayout" label="联系方式"><a-input v-decorator="['maintenanceContactInformation',{initialValue: dialogConfig.assetDetail.maintenanceContactInformation,rules: [{ max: 20, message: '最多填写20个字符' }]}]" placeholder="请输入联系方式" /></a-form-item></a-col>
              <a-col :span="8"><a-form-item v-bind="formItemLayout" label="到期日期"><a-date-picker v-decorator="['maintenanceDateExpired',{initialValue: dialogConfig.assetDetail.maintenanceDateExpired}]" placeholder="请输入到期日期"/></a-form-item></a-col>
            </a-row>
          </a-form>
        </div>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object,
    assetsTypeData: Array,
    assetsLocationData: Array,
    assetsStoreData: Array,
    extentionData: Array
  },
  watch: {
    'dialogConfig.visible': {
      handler(val){
        if(val){
          this.extentionDataFit = [];
          this.extentionData.forEach(item => {
            if(item.type==="type_global"||item.categoryId===this.dialogConfig.assetDetail.categoryId){ this.extentionDataFit.push(item); }
          });
          this.dialogConfig.assetDetail.dateWarehoused = this.dialogConfig.assetDetail.dateWarehoused ? this.$moment(this.$moment(this.dialogConfig.assetDetail.dateWarehoused).format('YYYY-MM-DD')):null;
          this.dialogConfig.assetDetail.maintenanceDateExpired = this.dialogConfig.assetDetail.maintenanceDateExpired ? this.$moment(this.$moment(this.dialogConfig.assetDetail.maintenanceDateExpired).format('YYYY-MM-DD')):null;
        }
      },
    }
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 9 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 15 } },
      },
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 3 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 21 } },
      },
      extentionDataFit: []
    }
  },
  methods: {
    //资产分类变化时处理拓展信息计量单位等
    handleAssetTypeChange(value,node){
      this.extentionDataFit = [];
      this.extentionData.forEach(item => { 
        if(item.type==="type_global"||item.categoryId===value){ this.extentionDataFit.push(item); }
        // 回写计量单位
        this.form.setFieldsValue({measureUnit:node.dataRef.measureUnit});
        // 回写使用期限（月）
        this.form.setFieldsValue({dateEstimated:node.dataRef.dateEstimated});
      });
    },
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        let assetExtend={};
        this.extentionDataFit.forEach(item => {
          let attr = {[item.fieldName]:values[item.fieldLabel],}
          assetExtend={...assetExtend,...attr}
        })
        let assetExtendString = {"assetExtend":assetExtend};
        this.$emit('submit',{id:this.dialogConfig.assetDetail.id,...values,...assetExtendString});
      })
    },
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    checkNumberFormat(rule, value, callback){
      if(value!=""&&value!=null&&!(/(^[0-9]\d*$)/.test(value))){
        callback("请输入正整数");
      }else{
        callback();
      }
    },
    checkFloatFormat(rule, value, callback){
      if(value!=""&&value!=null&&!(/^\d+(\.\d{1,2})?$/.test(value))){
        callback("请输入两位小数正数");
      }else{
        callback();
      }
    }
  }
}
</script>

<style lang="less" scoped>
.asseteditdialog_block{
  width: 100%;
  height: auto;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.asseteditdialog_main_block{
  width: 100%;
  height: auto;
  min-height: 200px;
  padding: 10px 50px 10px 30px;
}
.asseteditdialog_title{
  height: 60px;
  line-height: 60px;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
/deep/ .ant-divider-horizontal{
  margin: 8px 0;
}
</style>