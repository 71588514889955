<template>
  <div class="default_main">
    <a-spin :spinning="pageLoading" size="large" tip="加载中...">
      <a-icon slot="indicator" type="loading"/>
      <div class="employeemanagement_header">
        <a-input-search class="default_searchinput" placeholder="请输入员工编号、姓名或手机号" enter-button @search="onSearch"/>
        <a-dropdown>
          <a-button class="f_right" type="primary">添加员工</a-button>
          <a-menu slot="overlay">
            <a-menu-item @click="callEmployeeAddDialog">新增</a-menu-item>
            <a-menu-item @click="callBatchImportDialog">批量导入</a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div class="employeemanagement_table">
        <a-table 
          :columns="employeesTableColumns" 
          :data-source="employeesTableData" 
          :scroll="{ x: '100%',y: true}" 
          rowKey="id" 
          :pagination="employeesTablePagination" 
          @change="handleEmployeesTableChange"
        >
          <template slot="active" slot-scope="active">
            <a-tag :color="active===true? '#87D068':''">{{ active === true ? "在职":"离职"}}</a-tag>
          </template>
          <template slot="actions" slot-scope="text,record">
            <font class="table_action" v-if="record.code!='tenant'&&record.active===true" @click="callEmployeeEditDialog(record)">修改</font>
            <font class="table_action disabled" v-if="record.code!='tenant'&&record.active===false">修改</font>
            <font class="table_action" v-if="record.code!='tenant'&&record.active===true" @click="quitEmployee(record)">离职</font>
            <font class="table_action disabled" v-if="record.code!='tenant'&&record.active===false">离职</font>
            <font class="table_action" v-if="record.code!='tenant'" @click="deleteEmployee(record)">删除</font>
          </template>
        </a-table>
      </div>
    </a-spin>
    <BatchImportDialog :dialogConfig="BatchImportDialogConfig" @submit="batchImportEmployees"/>
    <EmployeeAddDialog :dialogConfig="EmployeeAddDialogConfig" @submit="addEmployee"/>
    <EmployeeEditDialog :dialogConfig="EmployeeEditDialogConfig" @submit="editEmployee"/>
  </div>
</template>

<script>
import BatchImportDialog from "../../components/BatchImportDialog.vue";
import EmployeeAddDialog from "../../components/EmployeeAddDialog.vue";
import EmployeeEditDialog from "../../components/EmployeeEditDialog.vue";
export default {
  components: {
    BatchImportDialog,
    EmployeeAddDialog,
    EmployeeEditDialog
  },
  data(){
    return {
      pageLoading: false,
      employeesTableColumns: [
        {title: '在职状态',dataIndex: 'active',key: 'active',align: 'center',width: 120,scopedSlots: { customRender: 'active' }},
        {title: '员工编号',dataIndex: 'code',key: 'code',align: 'center',width: 120,ellipsis: true},
        {title: '姓名',dataIndex: 'name',key: 'name',align: 'center',width: 150,ellipsis: true},
        {title: '手机号',dataIndex: 'phoneNumber',key: 'phoneNumber',align: 'center',width: 120},
        {title: '职位',dataIndex: 'position',key: 'position',align: 'center',width: 200,ellipsis: true},
        {title: '所属组织',dataIndex: 'unitName',key: 'unitName',align: 'center',width: 150,ellipsis: true},
        {title: '邮箱',dataIndex: 'email',key: 'email',align: 'center',width: 200,ellipsis: true},
        {title: '备注',dataIndex: 'notes',key: 'notes',align: 'center',ellipsis: true,width: 200},
        {title: '操作',key: 'actions',align: 'center',width: 130,fixed: 'right',scopedSlots: { customRender: 'actions' }}
      ],
      employeesTableData: [],
      employeesTablePagination: { current: 1,pageSize: 20,showSizeChanger: true,pageSizeOptions: ['20','50','100','200'],total:0,showTotal: total => `共计${total}条`},
      searchData: "",
      BatchImportDialogConfig: { visible:false,requesting:false,title:"",templateUrl:"",templateName:"" },
      EmployeeAddDialogConfig: { visible:false,requesting:false },
      EmployeeEditDialogConfig: { visible:false,requesting:false,employee:{} }
    }
  },
  mounted(){
    this.getEmployeesTableData();
  },
  methods: {
    getEmployeesTableData(){
      let that = this;
      this.pageLoading = true;
      let param = "pageNumber="+this.employeesTablePagination.current+"&pageSize="+this.employeesTablePagination.pageSize;
      if(this.searchData!=null){ param=param+"&searchData="+this.searchData; }
      this.$get("/userEmployees?"+param,{})
      .then(function(res){
        that.pageLoading = false;
        that.employeesTableData = res.data.records;
        const pager = { ...that.employeesTablePagination };
        pager.total = res.data.total;
        that.employeesTablePagination = pager;
      })
    },
    onSearch(value){
      this.searchData = value;
      this.employeesTablePagination.current = 1;
      this.getEmployeesTableData();
    },
    handleEmployeesTableChange(pagination){
      const pager = { ...this.employeesTablePagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.employeesTablePagination = pager;
      this.getEmployeesTableData();
    },
    //修改
    callEmployeeEditDialog(record){
      this.EmployeeEditDialogConfig = { visible:true,requesting:false,employee:record };
    },
    editEmployee(values){
      this.EmployeeEditDialogConfig.requesting = true;
      let that = this;
      this.$put("/userEmployee",values)
      .then(function(){
        that.EmployeeEditDialogConfig.requesting = false;
        that.EmployeeEditDialogConfig.visible = false;
        that.$message.success("修改成功");
        that.getEmployeesTableData();
      })
      .catch(function(){
        that.EmployeeEditDialogConfig.requesting = false;
      })
    },
    //离职
    quitEmployee(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "离职操作",
        content: "确定【"+record.name+"】办理离职吗（确保名下无资产、无待办项、非库房管理员方可离职）?",
        onOk(){
          that.pageLoading = true;
          that.$post("/userEmployee/"+record.id+"/unActive",{})
          .then(function(){
            that.pageLoading=false;
            that.$message.success("离职成功");
            that.getEmployeesTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    //删除
    deleteEmployee(record){
      let that = this;
      this.$modal.confirm({
        width: 530,
        title: "删除操作",
        content: "确定删除【"+record.name+"】吗?",
        onOk(){
          that.pageLoading = true;
          that.$post("/userEmployees",[record.id])
          .then(function(){
            that.pageLoading=false;
            that.$message.success("删除成功");
            if(that.employeesTableData.length==1&&that.employeesTablePagination.current>1){ that.employeesTablePagination.current--; }
            that.getEmployeesTableData();
          })
          .catch(function(){
            that.pageLoading=false;
          })
        },
        onCancel(){}
      });
    },
    //新增
    callEmployeeAddDialog(){
      this.EmployeeAddDialogConfig = { visible:true,requesting:false };
    },
    addEmployee(values){
      this.EmployeeAddDialogConfig.requesting = true;
      let that = this;
      this.$post("/userEmployee",values)
      .then(function(){
        that.EmployeeAddDialogConfig.requesting = false;
        that.EmployeeAddDialogConfig.visible = false;
        that.$message.success("保存成功");
        that.getEmployeesTableData();
      })
      .catch(function(){
        that.EmployeeAddDialogConfig.requesting = false;
      })
    },
    //批量导入
    callBatchImportDialog(){
      this.BatchImportDialogConfig = { visible:true,requesting:false,title:"批量导入员工",templateUrl:"/userEmployees/export/excelTemplate",templateName:"员工模板.xls" };
    },
    //批量导入回调
    batchImportEmployees(values){
      this.BatchImportDialogConfig.requesting = true;
      let that = this;
      this.$post("/userEmployees/import",values)
      .then(function(res){
        that.BatchImportDialogConfig.requesting = false;
        if(res.data.import_status==="error"){
          that.$message.warning({
            title: '错误提示',
            content: res.data.import_error_info,
            duration: 5
          });
        }else{
          that.BatchImportDialogConfig.visible = false;
          that.$message.success("导入成功");
          that.employeesTablePagination.current = 1;
          that.getEmployeesTableData();
        } 
      })
      .catch(function(){
        that.BatchImportDialogConfig.requesting = false;
      })
    }
  }
}
</script>

<style lang="less" scoped>
.employeemanagement_header{
  padding: 20px;
  width: 100%;
  float: left;
}
.employeemanagement_header button+button{
  margin-right: 5px;
}
.employeemanagement_table{
  width: 100%;
  height: calc(100% - 72px);
  float: left;
}
</style>