<template>
  <a-modal
    v-model="dialogConfig.visible" 
    centered 
    title="选择员工" 
    :maskClosable="false" 
    :destroyOnClose="true" 
    :width="650" 
    :closable="!dialogConfig.requesting"
  >
    <template slot="footer">
      <a-button :disabled="employeesNoUnitData==null||dialogConfig.requesting" @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="dialogConfig.requesting" @click="handleSubmit">保存</a-button>
    </template>
    <div class="organizationusersselectdialog_block">
      <a-spin :spinning="dialogConfig.requesting" size="large" tip="加载中...">
        <a-icon slot="indicator" type="loading"/>
        <a-form :form="form" style="padding: 50px 50px 20px 50px">
          <a-row :gutter="24">
            <a-form-item v-bind="formItemSingleLayout">
              <a-select 
                mode="multiple"
                v-decorator="['approvalUserId',{ rules: [{ required: true, message: '请选择要添加到该组织的员工' }] }]"
                show-search
                :filterOption="false"
                @search="getEmployeesNoUnitData" 
                placeholder="请输入编号、姓名或手机号搜索员工"
                allow-clear
              >
                <a-select-option v-for="item in employeesNoUnitData" :value="item.id" :key="item.id">
                  {{ item.name }}（{{item.code}}）
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-row>
        </a-form>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    dialogConfig: Object,
    employeesNoUnitData: Array
  },
  data(){
    return{
      form: this.$form.createForm(this),
      formItemSingleLayout: {
        labelCol: { xs: { span: 24 },sm: { span: 0 } },
        wrapperCol: { xs: { span: 24 },sm: { span: 24 } },
      }
    }
  },
  methods: {
    handleCancel(){
      this.dialogConfig.visible = false;
    },
    handleSubmit(){
      this.form.validateFields((errors, values) => {
        if(errors){ return; }
        this.$emit('submit',values);
      })
    },
    getEmployeesNoUnitData(value){
      this.$emit("getEmployeesNoUnitData",value);
    }
  }
}
</script>

<style lang="less" scoped>
.organizationusersselectdialog_block{
  width: 100%;
  height: auto;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
/deep/ .ant-divider-horizontal{
  margin: 8px 0;
}
</style>